import React from "react";
import SelectTemplate from "../SelectTemplate";
import { LoaderCircle } from "lucide-react";
import NavigationButtons from "./NavigationButtons";

export default function Step1Basic({
  formData,
  setFormData,
  industries,
  templates,
  isLoading,
  currentStep,
  totalSteps,
  handleNext,
  handleBack,
  isStepValid,
  setChat,
}) {
  return (
    <div className="space-y-6 mx-auto w-[671px] p-6 bg-white dark:bg-gray-800 rounded-2xl shadow-sm border border-gray-200 dark:border-gray-700">
      {/* Project Name */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Website Name
        </label>
        <input
          type="text"
          value={formData.projectName}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, projectName: e.target.value }))
          }
          className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                   dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
          placeholder="Enter website name"
        />
      </div>

      {/* Industry Selection */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Website Type
        </label>
        <select
          value={formData.industry}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, industry: e.target.value }))
          }
          className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                   dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
        >
          <option value="">Select website type</option>
          {industries.map((ind) => (
            <option key={ind._id} value={ind.industry_name}>
              {ind.industry_name}
            </option>
          ))}
        </select>
      </div>

      {/* Template Selection */}
      {formData.industry && (
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Select Template
          </label>
          {isLoading ? (
            <div className="flex flex-col items-center w-full p-12 gap-5">
              <LoaderCircle className="h-12 w-12 animate-spin text-primary" />
              <p>Loading Templates...</p>
            </div>
          ) : templates?.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-3 py-2 px-1 gap-4 max-h-[420px] overflow-y-auto mt-1">
              {templates.map((template) => (
                <div
                  key={template._id}
                  className="transform transition-all duration-300 hover:-translate-y-1"
                >
                  <SelectTemplate
                    themeId={template._id}
                    image={`${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${template._id}/thumb/${template.thumbnail}`}
                    title={template.template_name}
                    demoURL={template?.url}
                    selectedValue={formData.templateId}
                    setSelectedValue={(id) =>
                      setFormData((prev) => ({ ...prev, templateId: id }))
                    }
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-500 py-12">
              No templates found
            </div>
          )}
        </div>
      )}

      <NavigationButtons
        currentStep={currentStep}
        totalSteps={totalSteps}
        handleNext={handleNext}
        handleBack={handleBack}
        isStepValid={isStepValid}
        setChat={setChat}
      />
    </div>
  );
}
