import { Camera, LoaderCircle, Save } from "lucide-react";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { InputField } from "../../../../components";
import dayjs from "dayjs";
import imageCompression from "browser-image-compression";
import { debounce } from "lodash";
import { toast } from "react-hot-toast";

export default function AddUpdateImage({
  sidebar,
  toggleSidebar,
  handleChange,
  isUpdating,
  createUpdate,
  handleFileChange,
  fileInfo,
  image,
}) {
  const addFromRef = useRef();
  const [compressing, setCompressing] = useState(false);
  const [selectedSize, setSelectedSize] = useState(50);
  const [originalFile, setOriginalFile] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addFromRef.current && !addFromRef.current.contains(event.target)) {
        if (sidebar) toggleSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebar, toggleSidebar]);

  const compressImage = useCallback(
    async (file, targetSize) => {
      try {
        setCompressing(true);
        const compressionOptions = {
          maxSizeMB: targetSize / 1024,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
          initialQuality: 0.5,
          fileType: "image/jpeg",
        };

        const compressedFile = await imageCompression(file, compressionOptions);

        const reader = new FileReader();
        reader.onloadend = () => {
          const img = new Image();
          img.src = reader.result;

          img.onload = () => {
            handleFileChange({
              target: {
                files: [compressedFile],
              },
              preventDefault: () => {},
            });
          };
        };
        reader.readAsDataURL(compressedFile);
      } catch (err) {
        console.error("Error compressing image:", err);
        toast.error("Failed to compress image");
      } finally {
        setCompressing(false);
      }
    },
    [handleFileChange]
  );

  const debouncedCompress = useCallback(
    debounce((file, size) => {
      compressImage(file, size);
    }, 300),
    []
  );

  const handleSizeChange = useCallback(
    (e) => {
      const newSize = parseInt(e.target.value);
      setSelectedSize(newSize);
      if (originalFile) {
        debouncedCompress(originalFile, newSize);
      }
    },
    [originalFile, debouncedCompress]
  );

  const handleImageFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setOriginalFile(file);
      const fileSizeKB = file.size / 1024;
      if (fileSizeKB > 100) {
        compressImage(file, selectedSize);
        return;
      }
      handleFileChange(e);
    }
  };

  useEffect(() => {
    if (!sidebar) {
      setOriginalFile(null);
    }
  }, [sidebar]);

  useEffect(() => {
    if (fileInfo?.file && !originalFile) {
      fetch(fileInfo.preview)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "existing-image.jpg", {
            type: "image/jpeg",
          });
          setOriginalFile(file);
        })
        .catch((err) => {
          console.error("Error creating file from existing image:", err);
        });
    }
  }, [fileInfo, originalFile]);

  return (
    <div
      ref={addFromRef}
      className={`flex flex-col gap-6 w-[600px] overflow-y-scroll h-screen z-50 fixed right-0 top-0 p-6 bg-white dark:bg-gray-800 capitalize ${
        sidebar && "shadow-l shadow-black/20 dark:shadow-gray-600/40"
      }`}
      style={{ transform: sidebar ? "translateX(0)" : "translateX(100%)" }}
    >
      <h3 className="font-bold">Attachment Detail</h3>
      <div className="overflow-hidden relative h-96 transition-all w-full rounded-md bg-gray-300 border border-white/20 flex items-center justify-end">
        <input
          type="file"
          onChange={handleImageFileChange}
          id="imageUpload"
          className="hidden"
          accept="image/*"
        />
        <div className="flex justify-end z-10 p-5 gap-3 absolute right-0 bottom-0 h-fit w-full bg-gradient-to-b from-black/5 to-black">
          <label
            htmlFor="imageUpload"
            className="btnWhite py-1 px-3 cursor-pointer flex items-center gap-2"
          >
            <Camera className="w-4" />
            Upload Image
          </label>
        </div>
        <img
          title={"Upload an image"}
          src={fileInfo?.preview}
          loading="lazy"
          alt=""
          className={`w-full h-full object-cover absolute top-0 ${
            fileInfo ? "scale-100" : "scale-125"
          }`}
        />
      </div>
      {originalFile && (
        <div className="mb-4">
          <label className="text-sm font-medium">
            Compress Image Size: {selectedSize}KB
          </label>
          <input
            type="range"
            min="5"
            max="100"
            value={selectedSize}
            onChange={handleSizeChange}
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 mt-2"
          />
          <div className="flex justify-between text-xs text-gray-500 mt-1">
            <span>5KB</span>
            <span>100KB</span>
          </div>
          {compressing && (
            <p className="text-sm text-gray-500 mt-1">Compressing image...</p>
          )}
        </div>
      )}
      {fileInfo && (
        <div className="grid grid-cols-3 gap-3 mb-4">
          <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-md">
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Current Size
            </p>
            <p className="font-medium">{image.fileSize}</p>
          </div>
          <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-md">
            <p className="text-xs text-gray-500 dark:text-gray-400">Format</p>
            <p className="font-medium">
              {image.fileType?.split("/")[1]?.toUpperCase()}
            </p>
          </div>
          <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-md">
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Dimensions
            </p>
            <p className="font-medium">{image.dimensions}</p>
          </div>
        </div>
      )}
      <div className="grid grid-cols-2 gap-4">
        <InputField
          label="Image Title"
          name="imageTitle"
          placeholder="image title"
          value={image?.imageTitle || ""}
          onChange={handleChange}
        />
        <InputField
          label="Alt Text"
          name="imageAltText"
          placeholder="image alt text"
          value={image?.imageAltText || ""}
          onChange={handleChange}
        />
      </div>
      <div>
        {image.createdAt && (
          <p>
            <span className="font-semibold">Created At: </span>
            {dayjs(image.createdAt).format("ddd, MMM D, YYYY h:mm A")}
          </p>
        )}
        {image.createdAt && (
          <p>
            <span className="font-semibold">Updated At: </span>
            {dayjs(image.updatedAt).format("ddd, MMM D, YYYY h:mm A")}
          </p>
        )}
        {image.fileSize && (
          <p>
            <span className="font-semibold">File Size: </span>
            {image.fileSize}
          </p>
        )}
        {image.fileType && (
          <p>
            <span className="font-semibold">File Type: </span>
            {image.fileType}
          </p>
        )}
        {image.dimensions && (
          <p>
            <span className="font-semibold">Dimensions: </span>
            {image.dimensions}
          </p>
        )}

        <p className="break-all lowercase">
          <span className="font-semibold capitalize">Image URL: </span>
          {fileInfo?.preview}
        </p>
      </div>
      <div className="flex items-center justify-end gap-2 mt-6">
        <button onClick={toggleSidebar} className="px-4">
          Cancel
        </button>
        <button
          type="button"
          disabled={isUpdating}
          onClick={createUpdate}
          className="btnPrimary px-3 bg-green-600 disabled:cursor-default disabled:opacity-90 disabled:bg-primary"
        >
          {isUpdating ? (
            <LoaderCircle className="w-4 h-4 animate-spin" />
          ) : (
            <Save className="w-4 h-4" />
          )}
          {isUpdating ? <p>Updating Image</p> : <p>Save & Update</p>}
        </button>
      </div>
    </div>
  );
}
