import {
  Copy,
  Edit,
  Trash,
  Camera,
  Search,
  CheckCheck,
  LoaderCircle,
} from "lucide-react";

import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import AddUpdateImage from "./AddUpdateImage";
import useApi from "../../../../utils/useApi";
import toast, { Toaster } from "react-hot-toast";
import { DataTable } from "../../../../components";
import { useProjectContext } from "../ProjectContext";
import React, { useEffect, useState, useCallback } from "react";

// Utility function to format file sizes
export const formatFileSize = (size) => {
  if (size < 1024) return `${size} bytes`;
  let i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / Math.pow(1024, i)).toFixed(2)} ${
    ["B", "KB", "MB", "GB", "TB"][i]
  }`;
};

// Component to handle file input
const FileInput = ({ handleFileChange }) => (
  <div className="relative">
    <input
      type="file"
      onChange={handleFileChange}
      id="imageUpload"
      className="hidden"
      accept="image/*"
      multiple
    />
    <label htmlFor="imageUpload" className="btnWhite px-4 py-2.5">
      Add Image
      <Camera className="w-4 h-4" />
    </label>
  </div>
);

// Delete confirmation popup component
const DeleteConfirmationPopup = ({
  isOpen,
  onClose,
  onConfirm,
  isDeleting,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl w-[400px] relative z-10">
        <h2 className="text-xl font-bold mb-4">Delete Image</h2>
        <p className="text-gray-600 dark:text-gray-300 mb-6">
          Are you sure you want to delete this image? This action cannot be
          undone.
        </p>
        <div className="flex items-center justify-end gap-3">
          <button
            className="px-4 py-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors"
            onClick={onClose}
            disabled={isDeleting}
          >
            Cancel
          </button>
          <button
            className="btnPrimary bg-red-500 hover:bg-red-600 disabled:bg-red-400 px-4 py-2 flex items-center gap-2"
            onClick={onConfirm}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <>
                <LoaderCircle className="w-4 h-4 animate-spin" />
                Deleting...
              </>
            ) : (
              <>
                <Trash className="w-4 h-4" />
                Delete
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

// Main ProjectGallery component
export default function ProjectGallery() {
  const { request } = useApi();
  const { imageKey } = useParams();
  const { project_id } = useProjectContext();

  const [image, setImage] = useState({});
  const [images, setImages] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const [isCopied, setIsCopied] = useState(null);
  const [keyToDelete, setKeyToDelete] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [deletingArticle, setDeletingArticle] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const toggleSidebar = useCallback(() => {
    setSidebar((prevSidebar) => !prevSidebar);
  }, []);

  const getGallery = useCallback(async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/gallery`,
      });
      setImages(res.data[0]?.value || []);
    } catch (err) {
      console.error("Failed to fetch gallery:", err);
      toast.error("Failed to fetch gallery.");
      setImages([]);
    }
  }, [project_id, request]);

  useEffect(() => {
    getGallery();
  }, [project_id]);

  useEffect(() => {
    if (imageKey && images.length > 0) {
      const imageToEdit = images.find((item) => item.key === imageKey);
      if (imageToEdit) {
        setImage(imageToEdit);
        setFileInfo(null);
        setSidebar(true);
      }
    }
  }, [imageKey, images]);

  const handleChange = (e) => {
    setImage((prevImage) => ({
      ...prevImage,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFileChange = useCallback((e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      const fileSizeKB = file.size / 1024; // Convert bytes to KB
      if (fileSizeKB > 100) {
        toast.error("Image size must be less than 100KB");
        e.target.value = ""; // Reset the input
        return;
      }

      const img = new Image();
      img.onload = () => {
        setImage((prevImage) => ({
          ...prevImage,
          fileSize: formatFileSize(file.size),
          fileType: file.type,
          dimensions: `${img.width}x${img.height}`,
        }));
      };
      img.src = URL.createObjectURL(file);
      setFileInfo({ file, preview: img.src });
      setSidebar(true);
    }
  }, []);

  const createUpdate = useCallback(
    async (event) => {
      event.preventDefault();
      setIsUpdating(true);

      if (!image.imageTitle || !image.imageAltText) {
        toast.error("Image title and alt text cannot be empty.");
        setIsUpdating(false); // Reset the updating state
        return;
      }

      const uniqueKey = image.key || `image-${uuidv4()}`;
      const updatedImage = { ...image, key: uniqueKey };

      try {
        const formData = new FormData();
        formData.append("key", uniqueKey);
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(updatedImage));
        if (fileInfo?.file) {
          formData.append("file", fileInfo.file);
        }

        const res = await request({
          method: "post",
          url: `projects/${project_id}/data`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (res.status) {
          const imageWithResponseData = {
            ...updatedImage,
            _id: res.data._id,
            key: res.data.key,
            image: res.data.file_name,
            createdAt: res.data.createdAt,
            updatedAt: res.data.updatedAt,
          };

          const updatedImagesList = [...images];
          const imgIndex = updatedImagesList.findIndex(
            (img) => img.key === uniqueKey
          );

          if (imgIndex !== -1) {
            updatedImagesList[imgIndex] = imageWithResponseData;
          } else {
            updatedImagesList.push(imageWithResponseData);
          }

          const updatedFormData = new FormData();
          updatedFormData.append("key", "gallery");
          updatedFormData.append("value_type", "JSON");
          updatedFormData.append("value", JSON.stringify(updatedImagesList));

          await request({
            method: "post",
            url: `projects/${project_id}/data`,
            data: updatedFormData,
            headers: { "Content-Type": "multipart/form-data" },
          });

          toast.success("Image updated.");
          getGallery();
        }
        setFileInfo(null);
        setImage({});
        setSidebar(false);
      } catch (err) {
        console.error("Failed to add/update image:", err);
        toast.error("Failed to add/update image.");
      } finally {
        setIsUpdating(false);
      }
    },
    [image, fileInfo, images, getGallery, project_id, request]
  );

  const handleEditImage = useCallback(
    async (image) => {
      setSidebar(true);
      try {
        const res = await request({
          method: "get",
          url: `projects/${project_id}/data/${image.key}`,
        });

        const img = res.data?.[0]?.value || {};
        const file = res.data?.[0]?.file_name;

        setImage({
          key: img.key,
          _id: img._id,
          createdAt: image.createdAt,
          updatedAt: image.updatedAt,
          fileSize: image.fileSize,
          fileType: image.fileType,
          dimensions: image.dimensions,
          imageTitle: img.imageTitle || "",
          imageAltText: img.imageAltText || "",
        });

        setFileInfo({
          file,
          preview: file
            ? `${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${image.image}`
            : null,
        });
      } catch (err) {
        console.error(err);
        toast.error("Failed to fetch banner data.");
      }
    },
    [project_id, request]
  );

  const handleDeleteClick = useCallback((key) => {
    setKeyToDelete(key);
    setDeleteConfirmation(true);
  }, []);

  const deleteFromList = useCallback(async () => {
    if (!keyToDelete) return;
    setDeletingArticle(true);

    const loadingToast = toast.loading("Deleting image...");

    try {
      await request({
        method: "delete",
        url: `projects/${project_id}/data/${keyToDelete}`,
      });

      const updatedImagesList = images.filter(
        (image) => image.key !== keyToDelete
      );

      const formData = new FormData();
      formData.append("key", "gallery");
      formData.append("value_type", "JSON");
      formData.append("value", JSON.stringify(updatedImagesList));

      const res = await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (res.status) {
        toast.dismiss(loadingToast);
        toast.success("Image successfully deleted");
        setImages(updatedImagesList);
      }
    } catch (err) {
      console.error("Error deleting image:", err);
      toast.dismiss(loadingToast);
      toast.error("Failed to delete image");
    } finally {
      setDeletingArticle(false);
      setDeleteConfirmation(false);
      setKeyToDelete("");
    }
  }, [keyToDelete, images, project_id, request]);

  const copyToClipboard = useCallback(
    (item) => {
      navigator.clipboard
        .writeText(
          `${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${item.image}`
        )
        .then(() => {
          setIsCopied(item.key);
          setTimeout(() => setIsCopied(null), 2000);
          toast.success("Image URL Copied to clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
          toast.error("Failed to copy to clipboard");
        });
    },
    [project_id]
  );

  const filteredImages = useCallback(() => {
    return images.filter((image) => {
      const query = searchQuery.toLowerCase();
      return (
        image.imageTitle?.toLowerCase().includes(query) ||
        image.imageAltText?.toLowerCase().includes(query)
      );
    });
  }, [images, searchQuery]);

  return (
    <div className="h-[calc(100vh-6rem)] overflow-y-scroll">
      <Toaster />
      <div className="flex items-center justify-between flex-wrap gap-2 px-6 pb-2 pt-4">
        <div>
          <h3 className="font-bold">Project Gallery</h3>
          <p className="text-gray-500">
            You can use these images only between articles.
          </p>
        </div>
        <div className="flex items-center gap-3">
          <div className="relative">
            <input
              type="text"
              placeholder="Search by title or alt text..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-9 pr-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary dark:bg-gray-800 dark:border-gray-700"
            />
            <Search className="w-4 h-4 absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
          </div>
          <FileInput handleFileChange={handleFileChange} />
        </div>
      </div>

      <DataTable
        tableHeight="h-[calc(100vh-13rem)]"
        heads={[
          "Image",
          "Actions",
          "File Type",
          "Size",
          "Dimensions",
          "Title",
          "Alt Text",
        ]}
        items={filteredImages()?.map((item) => ({
          image: (
            <div className="relative overflow-hidden bg-black rounded-md w-20 h-9">
              <img
                alt={"preview"}
                className="w-full min-h-full"
                src={`${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${item.image}`}
              />
            </div>
          ),

          actions: (
            <div className="flex items-center gap-3">
              {isCopied === item.key ? (
                <CheckCheck className="w-5 h-5 text-green-500" />
              ) : (
                <Copy
                  className="w-5 h-5 cursor-pointer text-gray-500 dark:text-white/80 hover:text-primary transition"
                  onClick={() => copyToClipboard(item)}
                />
              )}
              <Edit
                className="w-5 h-5 hover:text-primary cursor-pointer"
                onClick={() => handleEditImage(item)}
              />
              <Trash
                className="deleteIcon cursor-pointer w-5 h-5 text-red-500"
                onClick={() => handleDeleteClick(item.key)}
              />
            </div>
          ),
          fileType: item.fileType,
          size: item.fileSize,
          dimensions: item.dimensions,
          title: item.imageTitle,
          altText: item.imageAltText,
        }))}
      />

      <AddUpdateImage
        sidebar={sidebar}
        toggleSidebar={toggleSidebar}
        handleChange={handleChange}
        isUpdating={isUpdating}
        createUpdate={createUpdate}
        handleFileChange={handleFileChange}
        fileInfo={fileInfo}
        image={image}
      />

      <DeleteConfirmationPopup
        isOpen={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        onConfirm={deleteFromList}
        isDeleting={deletingArticle}
      />
    </div>
  );
}
