import React, { useState } from "react";
import DarkSwitch from "../components/common/DarkSwitch";
import { InputField } from "../components";
import {
  CircleX,
  Lock,
  User,
  ArrowRight,
  Mail,
  Github,
  Loader2,
} from "lucide-react";
import useAuth from "../utils/useAuth";
import axios from "axios";
import PageSeo from "../components/common/PageSeo";
import { useSpring, animated } from "react-spring";

const Login = () => {
  const { loginUser } = useAuth();
  const [view, setView] = useState("login");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    email: "",
    confirmPassword: "",
    general: "",
  });

  const slideUp = useSpring({
    from: { transform: "translateY(30px)", opacity: 0 },
    to: { transform: "translateY(0)", opacity: 1 },
    delay: 200,
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/login`,
        {
          username: formData.username,
          password: formData.password,
        }
      );
      const { user, accessToken, refreshToken, menu_permissions } =
        res.data.data;
      loginUser(user, { accessToken, refreshToken }, menu_permissions);
      window.location.reload();
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        general: "Username or password incorrect.",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (formData.password !== formData.confirmPassword) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: "Passwords do not match",
      }));
      setIsLoading(false);
      return;
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/signup`,
        {
          username: formData.username,
          email: formData.email,
          password: formData.password,
        }
      );
      const { user, accessToken, refreshToken, menu_permissions } =
        res.data.data;
      loginUser(user, { accessToken, refreshToken }, menu_permissions);
      window.location.reload();
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        general: "Error creating account. Please try again.",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/forgot-password`,
        {
          email: formData.email,
        }
      );
      setErrors((prev) => ({
        ...prev,
        general: "Password reset instructions sent to your email.",
      }));
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        general: "Error sending reset instructions. Please try again.",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const renderForm = () => {
    switch (view) {
      case "signup":
        return (
          <form onSubmit={handleSignup} className="space-y-6">
            <div className="space-y-5">
              <InputField
                name="username"
                label="Username"
                type="text"
                placeholder="Choose a username"
                value={formData.username}
                onChange={(e) =>
                  setFormData({ ...formData, username: e.target.value })
                }
                required={true}
                error={errors.username}
                inputStyle="!border-gray-200 dark:!border-gray-700 rounded-lg !py-3 !bg-gray-50 dark:!bg-gray-700 dark:!text-white"
                labelStyle="!text-black dark:!text-white"
              />
              <InputField
                name="email"
                label="Email"
                type="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required={true}
                error={errors.email}
                inputStyle="!border-gray-200 dark:!border-gray-700 rounded-lg !py-3 !bg-gray-50 dark:!bg-gray-700 dark:!text-white"
                labelStyle="!text-black dark:!text-white"
              />
              <InputField
                name="password"
                label="Password"
                type="password"
                placeholder="Create a password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                required={true}
                error={errors.password}
                inputStyle="!border-gray-200 dark:!border-gray-700 rounded-lg !py-3 !bg-gray-50 dark:!bg-gray-700 dark:!text-white"
                labelStyle="!text-black dark:!text-white"
              />
              <InputField
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                placeholder="Confirm your password"
                value={formData.confirmPassword}
                onChange={(e) =>
                  setFormData({ ...formData, confirmPassword: e.target.value })
                }
                required={true}
                error={errors.confirmPassword}
                inputStyle="!border-gray-200 dark:!border-gray-700 rounded-lg !py-3 !bg-gray-50 dark:!bg-gray-700 dark:!text-white"
                labelStyle="!text-black dark:!text-white"
              />
            </div>
            <button
              type="submit"
              className="group w-full py-3.5 px-6 rounded-xl bg-gradient-to-r from-orange-500 to-primary text-white font-medium
                hover:from-orange-600 hover:to-primary transition-all duration-300"
            >
              Create Account
              <ArrowRight className="inline-block ml-2 w-4 h-4" />
            </button>
          </form>
        );

      case "forgot":
        return (
          <form onSubmit={handleForgotPassword} className="space-y-6">
            <InputField
              name="email"
              label="Email"
              type="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              required={true}
              error={errors.email}
              inputStyle="!border-gray-200 dark:!border-gray-700 rounded-lg !py-3 !bg-gray-50 dark:!bg-gray-700 dark:!text-white"
              labelStyle="!text-black dark:!text-white"
            />

            {errors.general && (
              <div className="flex items-center gap-2 text-red-500 bg-red-50 rounded-xl p-3 text-sm border border-red-100">
                <CircleX className="w-4 h-4 flex-shrink-0" />
                <span>{errors.general}</span>
              </div>
            )}

            <button
              type="submit"
              disabled={isLoading}
              className="group w-full py-3.5 px-6 rounded-xl bg-gradient-to-r from-orange-500 to-primary text-white font-medium
                hover:from-orange-600 hover:to-primary transition-all duration-300"
            >
              <span className="flex items-center justify-center gap-2">
                {isLoading ? (
                  <Loader2 className="w-5 h-5 animate-spin" />
                ) : (
                  <>
                    Reset Password
                    <ArrowRight className="w-4 h-4 transition-transform group-hover:translate-x-1" />
                  </>
                )}
              </span>
            </button>

            <p className="text-center text-gray-500 text-sm mt-8">
              Remember your password?{" "}
              <button
                onClick={() => setView("login")}
                className="text-primary hover:text-red-600 ml-1 font-medium"
              >
                Back to login
              </button>
            </p>
          </form>
        );

      default:
        return (
          <form onSubmit={handleLogin} className="space-y-6">
            <div className="space-y-5">
              <div className="relative">
                <InputField
                  name="username"
                  label="Username"
                  type="username"
                  placeholder="Enter your username"
                  value={formData.username}
                  onChange={(e) =>
                    setFormData({ ...formData, username: e.target.value })
                  }
                  required={true}
                  error={errors.username}
                  inputStyle="!border-gray-200 dark:!border-gray-700 rounded-lg !py-3 !bg-gray-50 dark:!bg-gray-700 dark:!text-white"
                  labelStyle="!text-black dark:!text-white"
                />
              </div>

              <div className="relative">
                <InputField
                  labelStyle="!text-black dark:!text-white"
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="Enter your password"
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  required={true}
                  error={errors.password}
                  inputStyle="!border-gray-200 dark:!border-gray-700 rounded-lg !py-3 !bg-gray-50 dark:!bg-gray-700 dark:!text-white"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <label className="flex items-center gap-2 cursor-pointer group">
                <input
                  type="checkbox"
                  className="w-4 h-4 rounded border-gray-300 text-primary focus:ring-primary
                    group-hover:border-primary transition-colors"
                />
                <span className="text-sm text-gray-500 group-hover:text-primary transition-colors">
                  Remember me
                </span>
              </label>
              <button
                type="button"
                onClick={() => setView("forgot")}
                className="text-sm text-primary hover:text-orange-600 transition-colors font-medium"
              >
                Forgot Password?
              </button>
            </div>

            {errors.general && (
              <div className="flex items-center gap-2 text-red-500 bg-red-50 rounded-xl p-3 text-sm border border-red-100">
                <CircleX className="w-4 h-4 flex-shrink-0" />
                <span>{errors.general}</span>
              </div>
            )}

            <button
              type="submit"
              disabled={isLoading}
              className="group w-full py-3.5 px-6 rounded-xl bg-gradient-to-r from-orange-500 to-primary text-white font-medium
                hover:from-orange-600 hover:to-primary transform transition-all duration-300 
                focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:outline-none
                shadow-lg shadow-red-500/30 hover:shadow-red-500/40"
            >
              <span className="flex items-center justify-center gap-2">
                {isLoading ? (
                  <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                      fill="none"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                ) : (
                  <>
                    Sign In
                    <ArrowRight className="w-4 h-4 transition-transform group-hover:translate-x-1" />
                  </>
                )}
              </span>
            </button>

            <div className="relative my-8">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-200"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-4 text-gray-500 bg-white">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <button
                type="button"
                className="flex items-center justify-center gap-2 py-3 px-4 rounded-xl border-2 border-yellow-500 
                  hover:bg-yellow-50 transition-colors text-yellow-600 font-medium"
              >
                <Mail className="w-5 h-5" />
                <span>Google</span>
              </button>
              <button
                type="button"
                className="flex items-center justify-center gap-2 py-3 px-4 rounded-xl border-2 border-blue-500
                  hover:bg-blue-50 transition-colors text-blue-600 font-medium"
              >
                <Github className="w-5 h-5" />
                <span>Github</span>
              </button>
            </div>
          </form>
        );
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-6 relative dark:bg-gray-900">
      {/* Add background image */}
      <div
        className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat opacity-100 dark:opacity-50"
        style={{
          backgroundImage:
            'url("https://st5.depositphotos.com/17620692/63337/v/450/depositphotos_633371220-stock-illustration-white-grey-background-space-design.jpg")',
          backgroundBlendMode: "overlay",
        }}
      >
        {/* Add overlay */}
        <div
          className="absolute inset-0 bg-gradient-to-br from-orange-500/30 via-yellow-500/30 to-primary/30
          dark:from-orange-900/30 dark:via-yellow-900/30 dark:to-blue-900/30"
        ></div>
      </div>

      <PageSeo
        title={`SiteBuilderz | ${
          view === "signup"
            ? "Sign Up"
            : view === "forgot"
            ? "Reset Password"
            : "Login"
        }`}
      />

      <animated.div
        style={slideUp}
        className="w-full max-w-5xl grid grid-cols-2 bg-white dark:bg-gray-800 rounded-3xl shadow-2xl overflow-hidden 
          border border-red-100 dark:border-gray-700 relative z-10"
      >
        {/* Left Panel - Form */}
        <div
          className="relative bg-gradient-to-br from-orange-500 to-primary dark:from-orange-600 dark:to-primary p-10 
          flex items-center justify-center overflow-hidden"
        >
          <div className="absolute top-10 left-10 z-20">
            <DarkSwitch bgSun="bg-red-400" />
          </div>

          {/* Enhanced background elements with modern glassmorphism and animations */}
          <div className="absolute inset-0">
            <div className="absolute left-0 top-0 w-[500px] h-[500px] bg-gradient-to-br from-white/20 to-transparent rounded-full -translate-x-1/2 -translate-y-1/2 blur-3xl animate-pulse"></div>
            <div className="absolute right-0 bottom-0 w-[500px] h-[500px] bg-gradient-to-tl from-primary/30 to-transparent rounded-full translate-x-1/2 translate-y-1/2 blur-3xl"></div>
            <div className="absolute left-1/2 top-1/2 w-[300px] h-[300px] bg-gradient-to-r from-yellow-500/20 to-red-500/20 rounded-full -translate-x-1/2 -translate-y-1/2 blur-2xl animate-pulse"></div>

            {/* Added subtle geometric patterns */}
            <div className="absolute inset-0 opacity-10">
              <div className="absolute top-12 left-12 w-20 h-20 border-2 border-white rounded-lg rotate-45"></div>
              <div className="absolute bottom-12 right-12 w-32 h-32 border-2 border-white rounded-full"></div>
              <div className="absolute top-1/2 left-1/2 w-24 h-24 border-2 border-white rounded-xl -translate-x-1/2 -translate-y-1/2 rotate-12"></div>
            </div>
          </div>

          <div className="relative max-w-md z-10">
            <h3 className="text-4xl mt-14 font-bold text-white mb-2 leading-tight bg-clip-text text-transparent bg-gradient-to-r from-white to-red-50">
              {view === "signup"
                ? "Join Our Community"
                : view === "forgot"
                ? "Password Recovery"
                : "Site Building Automation"}
            </h3>
            <p className="text-red-50/90 text-base leading-relaxed font-light">
              Experience the future of web development. Build stunning websites
              in minutes with AI-powered tools that understand your vision.
            </p>

            <img
              src="/bannerIllustration.png"
              alt="Login illustration"
              className="w-full mx-auto mb-6 drop-shadow-2xl hover:scale-105 transition-transform duration-500 animate-float"
            />

            {/* Modernized feature highlights with hover effects */}
            <div className="grid grid-cols-2 gap-4 text-left">
              {[
                { icon: "⚡", text: "Quick Setup", desc: "Ready in minutes" },
                {
                  icon: "🛠️",
                  text: "Powerful Tools",
                  desc: "Advanced features",
                },
                {
                  icon: "🔒",
                  text: "Secure Platform",
                  desc: "Enterprise-grade",
                },
                {
                  icon: "🚀",
                  text: "Fast Performance",
                  desc: "Optimized speed",
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className="group px-4 py-2.5 rounded-xl bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-all duration-300 cursor-pointer"
                >
                  <span className="text-sm font-semibold text-white block group-hover:text-primary transition-colors">
                    <span className="mr-2">{feature.icon}</span>
                    {feature.text}
                  </span>
                  <span className="text-xs text-white/70">{feature.desc}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Right Panel */}
        <div className="p-12 flex flex-col justify-between relative overflow-hidden dark:bg-gray-800">
          <div className="relative">
            <div className="mb-12">
              <h1 className="text-4xl font-bold mb-2 !text-black dark:!text-white">
                {view === "signup"
                  ? "Create Account"
                  : view === "forgot"
                  ? "Reset Password"
                  : "Welcome Back!"}{" "}
                <span className="text-red-500">👋</span>
              </h1>
              <p className="text-gray-500 dark:text-gray-400">
                {view === "signup"
                  ? "Fill in your details to get started"
                  : view === "forgot"
                  ? "Enter your email to reset your password"
                  : "Enter your credentials to access your account"}
              </p>
            </div>

            {renderForm()}

            {view !== "forgot" && (
              <p className="text-center text-gray-500 dark:text-gray-400 text-sm mt-8">
                {view === "login" ? (
                  <>
                    Don't have an account?{" "}
                    <button
                      onClick={() => setView("signup")}
                      className="text-primary hover:text-red-600 dark:hover:text-red-400 ml-1 font-medium"
                    >
                      Sign up
                    </button>
                  </>
                ) : (
                  <>
                    Already have an account?{" "}
                    <button
                      onClick={() => setView("login")}
                      className="text-primary hover:text-red-600 dark:hover:text-red-400 ml-1 font-medium"
                    >
                      Sign in
                    </button>
                  </>
                )}
              </p>
            )}
          </div>

          {/* Decorative circles */}
          <div className="absolute top-0 right-0 w-52 h-52 bg-yellow-500/10 dark:bg-yellow-500/5 rounded-full translate-x-32 -translate-y-32"></div>
          <div className="absolute bottom-0 left-0 w-64 h-64 bg-yellow-500/10 dark:bg-yellow-500/5 rounded-full -translate-x-32 translate-y-32"></div>
        </div>
      </animated.div>
    </div>
  );
};

export default Login;
