import React, { useState } from "react";
import { motion } from "framer-motion";
import AskAnything from "./AskAnything";
import TaskTracking from "./TaskTracking";
import ListArticles from "./ListArticles";
import ListProjects from "./ListProjects";
import GenerateImage from "./GenerateImage";
import CreateProject from "./CreateProject";
import ListIndustries from "./ListIndustries";
import ListCategories from "./ListCategories";

export default function AIProject() {
  const [activeComponent, setActiveComponent] = useState("CreateProject");

  const handleNavigation = (componentName) => {
    setActiveComponent(componentName);
  };

  const sidebarItems = [
    { name: "Create New Project", component: "CreateProject" },
    { name: "List Projects", component: "ListProjects" },
    { name: "List Industries", component: "ListIndustries" },
    { name: "List Categories", component: "ListCategories" },
    { name: "List Articles", component: "ListArticles" },
    { name: "Task Tracking", component: "TaskTracking" },
    { name: "Generate Image", component: "GenerateImage" },
    { name: "Ask Anything", component: "AskAnything" },
  ];

  const Sidebar = () => (
    <div
      className={`h-[calc(100vh-3.5rem)] bg-gray-50 z-10 dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 transition-all duration-300`}
    >
      <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
          AI Project Manager
        </h2>
      </div>
      <nav className="p-4 overflow-y-auto ">
        <ul>
          {sidebarItems.map((item, index) => (
            <li key={index}>
              <button
                onClick={() => handleNavigation(item.component)}
                className={`w-full text-left px-4 py-2 rounded-lg text-sm transition-colors ${
                  activeComponent === item.component
                    ? "bg-blue-500 text-white"
                    : "text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700"
                }`}
              >
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "CreateProject":
        return <CreateProject />;
      case "ListProjects":
        return <ListProjects />;
      case "ListIndustries":
        return <ListIndustries />;
      case "ListCategories":
        return <ListCategories />;
      case "ListArticles":
        return <ListArticles />;
      case "TaskTracking":
        return <TaskTracking />;
      case "GenerateImage":
        return <GenerateImage />;
      case "AskAnything":
        return <AskAnything />;
      default:
        return <div className="p-8">Select a component</div>;
    }
  };

  return (
    <div className="bg-gray-100 dark:bg-gray-900 h-[calc(100vh-3.5rem)] grid grid-cols-aiProject">
      <Sidebar />
      <div
        className={`transition-all duration-300 h-[calc(100vh-3.5rem)] overflow-hidden`}
      >
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]"></div>
        <motion.div
          key={activeComponent}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.2 }}
        >
          {renderActiveComponent()}
        </motion.div>
      </div>
    </div>
  );
}
