import { ChevronDown } from "lucide-react";
import React, { useState, useEffect, useRef } from "react";

const ComboBox = ({
  label,
  options = [],
  optionPadding = "py-2 pl-3 pr-9",
  selectedOption,
  setSelectedOption,
  placeholder,
  className = "",
  optionsListHeight = "max-h-60",
  emptyMessage = "No options found",
  disabled = false,
  multiple = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  // Filter and sort options
  const filteredOptions = options
    ?.filter((option) =>
      option.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    )
    ?.sort((a, b) => a.name.localeCompare(b.name));

  const handleSelect = (option) => {
    if (multiple) {
      setSelectedOption(prev => {
        const exists = prev.some(item => item._id === option._id);
        if (exists) {
          return prev.filter(item => item._id !== option._id);
        }
        return [...prev, option];
      });
      setSearchTerm("");
    } else {
      setSelectedOption(option);
      setIsOpen(false);
      setSearchTerm("");
      inputRef.current?.blur();
    }
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return;

    switch (e.key) {
      case "Enter":
        if (filteredOptions.length > 0) {
          handleSelect(filteredOptions[0]);
        }
        break;
      case "Escape":
        setIsOpen(false);
        inputRef.current?.blur();
        break;
      case "ArrowDown":
        e.preventDefault();
        // TODO: Add keyboard navigation
        break;
      case "ArrowUp":
        e.preventDefault();
        // TODO: Add keyboard navigation
        break;
      default:
        break;
    }
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setIsOpen(false);
      setSearchTerm("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const displayValue = searchTerm || 
    (multiple 
      ? selectedOption.length > 0 
        ? `${selectedOption.length} user${selectedOption.length > 1 ? 's' : ''} selected`
        : ''
      : selectedOption?.name || '');

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      {label && <label className="inputLabel mb-1 block">{label}</label>}

      <div className="relative">
        <input
          type="text"
          ref={inputRef}
          className={`inputField pr-10 ${
            disabled ? "cursor-not-allowed opacity-60" : ""
          }`}
          placeholder={placeholder}
          value={displayValue}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={() => !disabled && setIsOpen(true)}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />

        <ChevronDown
          className={`w-5 text-gray-500 absolute top-0 right-0 my-2 mx-4 transition-transform duration-200 
            ${isOpen ? "rotate-180" : ""} 
            ${disabled ? "cursor-not-allowed opacity-60" : "cursor-pointer"}`}
          onClick={() => !disabled && setIsOpen((prev) => !prev)}
        />
      </div>

      {isOpen && (
        <div
          className={`absolute z-10 mt-1 w-full bg-white dark:bg-gray-700 shadow-lg rounded-md py-1 
            text-base ring-1 ring-black ring-opacity-5 overflow-auto sm:text-sm ${optionsListHeight}`}
        >
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option._id}
                className={`cursor-pointer select-none relative border-b dark:border-white/20 
                  last:border-none transition-all hover:bg-primary hover:text-white
                  ${
                    multiple 
                      ? selectedOption.some(item => item._id === option._id)
                        ? "bg-primary text-white"
                        : ""
                      : option._id === selectedOption?._id
                        ? "bg-primary text-white"
                        : ""
                  }
                  ${optionPadding}`}
                onMouseDown={() => handleSelect(option)}
              >
                <span className="font-normal block truncate">
                  {option.name}
                </span>
              </div>
            ))
          ) : (
            <div className="py-2 px-3 text-gray-500 dark:text-gray-400">
              {emptyMessage}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ComboBox;
