import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-hot-toast";
import { FileText, Clock, Archive, Play, Pause } from "lucide-react";

export default function ListArticles() {
  const [articles, setArticles] = useState([]);
  const [projects, setProjects] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [categories] = useState(['AI', 'ML', 'Web', 'Mobile']); // Add your categories here
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  // Fetch projects first
  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://apipm.ecommcube.com/list_projects",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setProjects(response.data?.projects || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setError("Failed to fetch projects");
        toast.error("Failed to fetch projects");
        setLoading(false);
        setProjects([]);
      }
    };

    fetchProjects();
  }, []);

  // Fetch industries when project is selected
  useEffect(() => {
    if (!selectedProject) {
      setIndustries([]);
      return;
    }

    const fetchIndustries = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://apipm.ecommcube.com/list_industries?projectName=${selectedProject}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setIndustries(response.data?.industries || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching industries:", error);
        setError("Failed to fetch industries");
        toast.error("Failed to fetch industries");
        setLoading(false);
        setIndustries([]);
      }
    };

    fetchIndustries();
  }, [selectedProject]);

  // Updated fetch articles effect to include category
  useEffect(() => {
    if (!selectedProject || !selectedIndustry || !selectedCategory) return;

    const fetchArticles = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://apipm.ecommcube.com/list_articles?projectName=${selectedProject}&industry=${selectedIndustry}&category=${selectedCategory}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );
        setArticles(response.data?.articles || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching articles:", error);
        setError("Failed to fetch articles");
        toast.error("Failed to fetch articles");
        setLoading(false);
        setArticles([]);
      }
    };

    fetchArticles();
  }, [selectedProject, selectedIndustry, selectedCategory]);

  const handleProjectChange = (e) => {
    setSelectedProject(e.target.value);
    setSelectedIndustry(""); // Reset industry
    setSelectedCategory(""); // Reset category
    setArticles([]); // Reset articles
  };

  const handleIndustryChange = (e) => {
    setSelectedIndustry(e.target.value);
    setSelectedCategory(""); // Reset category
    setArticles([]); // Reset articles
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setArticles([]); // Reset articles
  };

  const getStatusIcon = (progress, status) => {
    if (progress === "completed" && status === "archived") return <Archive className="h-6 w-6" />;
    if (progress === "initialised") return <Clock className="h-6 w-6" />;
    if (progress === "running") return <Play className="h-6 w-6" />;
    return <Pause className="h-6 w-6" />;
  };

  const getStatusColor = (progress, status) => {
    if (progress === "completed" && status === "archived") return "text-green-500 bg-green-50 dark:bg-green-900/20";
    if (progress === "initialised") return "text-blue-500 bg-blue-50 dark:bg-blue-900/20";
    if (progress === "running") return "text-yellow-500 bg-yellow-50 dark:bg-yellow-900/20";
    return "text-gray-500 bg-gray-50 dark:bg-gray-900/20";
  };

  const renderArticleGroup = (group) => {
    if (!group.articles || group.articles.length === 0) return null;

    const statusColor = getStatusColor(group.progress, group.status);
    const statusIcon = getStatusIcon(group.progress, group.status);

    return (
      <div className="mb-8">
        <div className="flex items-center gap-2 mb-4">
          <div className={`p-2 rounded-lg ${statusColor}`}>
            {statusIcon}
          </div>
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white capitalize">
            {group.progress} - {group.status}
          </h2>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            ({group.articles.length} articles)
          </span>
        </div>

        <div className="grid grid-cols-1 gap-4">
          {group.articles.map((article, index) => (
            <motion.div
              key={article.taskId}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.05 }}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-all duration-300 p-6"
            >
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                    {article.title}
                  </h3>
                  <div className="flex flex-wrap gap-3">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Priority: {article.priority}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Progress: {article.progress}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Status: {article.status}
                    </span>
                  </div>
                  <div className="mt-2">
                    <span className="text-xs text-gray-400 dark:text-gray-500 break-all">
                      Task ID: {article.taskId}
                    </span>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="relative">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-6xl mx-auto p-8"
      >
        <div className="mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Articles List
          </h1>

          {/* Filters Section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            {/* Project Selection */}
            <div>
              <label htmlFor="project" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Select Project
              </label>
              <select
                id="project"
                value={selectedProject}
                onChange={handleProjectChange}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="">Select a project</option>
                {Array.isArray(projects) && projects.map((project, index) => (
                  <option key={index} value={project}>
                    {project}
                  </option>
                ))}
              </select>
            </div>

            {/* Industry Selection */}
            <div>
              <label htmlFor="industry" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Select Industry
              </label>
              <select
                id="industry"
                value={selectedIndustry}
                onChange={handleIndustryChange}
                disabled={!selectedProject}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent disabled:opacity-50"
              >
                <option value="">Select an industry</option>
                {Array.isArray(industries) && industries.map((industry, index) => (
                  <option key={index} value={industry}>
                    {industry}
                  </option>
                ))}
              </select>
            </div>

            {/* Category Selection - New */}
            <div>
              <label htmlFor="category" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Select Category
              </label>
              <select
                id="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                disabled={!selectedIndustry}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent disabled:opacity-50"
              >
                <option value="">Select a category</option>
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Status Information */}
          {selectedProject && selectedIndustry && selectedCategory && (
            <div className="text-gray-600 dark:text-gray-400 mb-4">
              <p>Selected Project: {selectedProject}</p>
              <p>Selected Industry: {selectedIndustry}</p>
              <p>Selected Category: {selectedCategory}</p>
              <p>Total Articles: {articles?.length || 0}</p>
            </div>
          )}
        </div>

        {/* Content Section */}
        {selectedProject && selectedIndustry && selectedCategory ? (
          <>
            {loading ? (
              <div className="flex items-center justify-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
              </div>
            ) : error ? (
              <div className="p-8 text-center">
                <div className="text-red-500 mb-4">{error}</div>
                <button 
                  onClick={() => window.location.reload()}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Retry
                </button>
              </div>
            ) : (
              <div>
                {Array.isArray(articles.articles_by_progress_and_status) && 
                  articles.articles_by_progress_and_status.map((group, index) => (
                    <React.Fragment key={`${group.progress}-${group.status}`}>
                      {renderArticleGroup(group)}
                    </React.Fragment>
                  ))
                }
                
                {(!articles.articles_by_progress_and_status || 
                  articles.articles_by_progress_and_status.every(group => !group.articles?.length)) && (
                  <div className="text-center text-gray-500 dark:text-gray-400 mt-8 p-8 bg-gray-50 dark:bg-gray-800 rounded-lg">
                    <FileText className="h-12 w-12 mx-auto mb-4 text-gray-400" />
                    <p className="text-lg">No articles found for the selected criteria</p>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="text-center text-gray-500 dark:text-gray-400 mt-8 p-8 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <FileText className="h-12 w-12 mx-auto mb-4 text-gray-400" />
            <p className="text-lg">Please select project, industry, and category to view articles</p>
          </div>
        )}
      </motion.div>
    </div>
  );
}
