import React, { useRef, useState, useEffect } from "react";
import {
  LoaderCircle,
  PanelRightOpen,
  Trash,
  Upload,
  Users,
} from "lucide-react";
import ProjectMenu from "./ProjectMenu";
import LayoutStyles from "./LayoutStyles";
import useApi from "../../../../utils/useApi";
import ContactDetails from "../ContactDetails";
import { useNavigate } from "react-router-dom";
import ServicesListing from "./ServicesListing";
import ProjectIdentity from "./ProjectIdentity";
import toast, { Toaster } from "react-hot-toast";
import ProjectSections from "../ProjectSections";
import { useProjectContext } from "../ProjectContext";
import { InputField, Modal } from "../../../../components";
import ComboBox from "../../../../components/common/ComboBox";
import usePermissions from "../../../../utils/userPermission";

export default function ProjectSidebar({ open, setOpen }) {
  const navigate = useNavigate();
  const { project_id, projectToManage, getProjects } = useProjectContext();
  const sidebarRef = useRef(null);
  const [hasScroll, setHasScroll] = useState(false);

  // Add useEffect to check for overflow
  useEffect(() => {
    const checkScroll = () => {
      if (sidebarRef.current) {
        const hasVerticalScroll =
          sidebarRef.current.scrollHeight > sidebarRef.current.clientHeight;
        setHasScroll(hasVerticalScroll);
      }
    };

    checkScroll();
    // Add resize listener to recheck on window resize
    window.addEventListener("resize", checkScroll);
    return () => window.removeEventListener("resize", checkScroll);
  }, [projectToManage]); // Re-run when project data changes

  // Modal
  const [modalType, setModalType] = useState("");
  const [show, showModal] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    showModal(!show);
  };

  const { request } = useApi();
  const [confirmationText, setConfirmationText] = useState("");
  const deleteProject = async (e) => {
    e.preventDefault();
    try {
      await request({
        method: "delete",
        url: `projects/${project_id}`,
      });
      navigate(`/projects`);
      toast.success("Project Deleted");
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const [isDeploying, setIsDeploying] = useState(false);
  const deployProject = async () => {
    setIsDeploying(true);
    try {
      await request({
        method: "get",
        url: `projects/${projectToManage?._id}/deploy`,
      });
      toast.success("Project Deployed");
      getProjects();
      handleModal();
    } catch (err) {
      toast.error(err.response.data.message);
    }
    setIsDeploying(false);
  };

  const { hasPermission } = usePermissions();
  const [isCustomizing, setIsCustomizing] = useState("");

  const lastUpdatedAt = new Date(projectToManage.last_updated_at);
  const deployedOn = new Date(projectToManage.deployed_on);

  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const getUsers = async () => {
    try {
      const data = await request({ method: "get", url: "users" });
      // Filter out users who are already assigned to the project
      const availableUsers = data.data.filter(
        (user) =>
          !projectToManage?.assigned_users?.some(
            (assignedUser) => assignedUser._id === user._id
          )
      );
      setUsers(availableUsers);
    } catch (err) {
      console.error(err);
    }
  };

  const assignUsersToProject = async (e) => {
    e.preventDefault();

    try {
      await request({
        method: "post",
        url: `projects/${project_id}/assign_users`,
        data: {
          user_ids: selectedUsers.map((user) => user._id),
        },
      });
      toast.success("Users assigned successfully");
      handleModal();
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  console.log("Project to manage", projectToManage);

  return (
    <div className="relative">
      <div
        ref={sidebarRef}
        className={`${
          open ? "w-64" : "w-16"
        } bg-white dark:bg-gray-800 h-[calc(100vh-6rem)] border-r overflow-y-auto transition-all duration-300 ease-in-out ${
          hasScroll ? "thin-scrollbar" : ""
        }`}
      >
        <div className="flex flex-col justify-between h-full">
          <div>
            <div className="font-bold flex items-center justify-between dark:text-white capitalize px-4 border-b text-lg dark:border-white/20 py-2.5">
              {open &&
                (projectToManage?.project_name ? (
                  <span className="truncate">
                    {projectToManage?.project_name}
                  </span>
                ) : (
                  <span className="text-gray-500">Project Name</span>
                ))}

              <button
                onClick={() => setOpen(!open)}
                className="hover:bg-gray-100 dark:hover:bg-gray-700 p-1 rounded-lg transition-colors"
              >
                <PanelRightOpen
                  className={`w-5 h-5 transition-transform duration-300 ${
                    !open && "rotate-180"
                  }`}
                />
              </button>
            </div>
            <ProjectMenu
              setIsCustomizing={setIsCustomizing}
              isCustomizing={isCustomizing}
              open={open}
            />
          </div>

          <div className="p-2 mt-6 space-y-1">
            {hasPermission("manage users") && (
              <button
                onClick={() => {
                  setModalType("users");
                  getUsers();
                  handleModal();
                }}
                className={`w-full flex items-center gap-3 py-2.5 px-4 transition-all duration-200 hover:bg-gray-100 dark:hover:bg-gray-700/20
                ${!open ? "justify-center" : ""}`}
                title="Manage Users"
              >
                <Users className="h-4 w-4" />
                {open && <span>Manage Users</span>}
              </button>
            )}

            {hasPermission("deploy project") && (
              <button
                onClick={deployProject}
                className={`w-full flex items-center gap-3 py-2.5 px-4 transition-all duration-200 
                  ${!open ? "justify-center" : ""} 
                  ${
                    isDeploying
                      ? "bg-primary text-white cursor-wait"
                      : "hover:bg-primary hover:text-white"
                  } 
                  ${
                    lastUpdatedAt.getTime() > deployedOn.getTime() &&
                    "bg-primary text-white"
                  }`}
                disabled={isDeploying}
                title={
                  !open
                    ? isDeploying
                      ? "Publishing"
                      : "Publish Changes"
                    : undefined
                }
              >
                {isDeploying ? (
                  <LoaderCircle className="w-5 h-5 animate-spin" />
                ) : (
                  <Upload
                    className={`w-5 h-5 ${
                      lastUpdatedAt.getTime() > deployedOn.getTime() ||
                      isDeploying
                        ? "text-white"
                        : "text-gray-500"
                    }`}
                  />
                )}
                {open && (
                  <span>
                    {isDeploying
                      ? "Publishing"
                      : projectToManage?.is_deployed
                      ? "Publish Changes"
                      : "Publish"}
                  </span>
                )}
              </button>
            )}

            {hasPermission("delete project") && (
              <button
                onClick={() => {
                  setModalType("delete");
                  handleModal();
                }}
                className={`w-full flex items-center gap-3 py-2.5 px-4 transition-all duration-200 text-red-500 hover:bg-red-50 dark:hover:bg-red-900/20
                  ${!open ? "justify-center" : ""}`}
                title="Delete Project"
              >
                <Trash className="w-5 h-5" />
                {open && <span>Delete Project</span>}
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Extra Sidebar Components */}
      <ProjectIdentity
        setIsCustomizing={setIsCustomizing}
        isCustomizing={isCustomizing}
        open={open}
      />

      <ProjectSections
        setIsCustomizing={setIsCustomizing}
        isCustomizing={isCustomizing}
        open={open}
      />

      <LayoutStyles
        setIsCustomizing={setIsCustomizing}
        isCustomizing={isCustomizing}
        open={open}
      />

      <ServicesListing
        setIsCustomizing={setIsCustomizing}
        isCustomizing={isCustomizing}
        open={open}
      />

      <ContactDetails
        setIsCustomizing={setIsCustomizing}
        isCustomizing={isCustomizing}
        open={open}
      />

      {/* Toggle Sidebar Button */}
      {/* <div
        className={`h-[40vh] mt-[20vh] absolute flex z-50 items-center justify-center top-0 ${
          open ? "ml-64" : "ml-[6px]"
        } ${open && isCustomizing && "left-96"} ${
          !open && isCustomizing && "left-[400px]"
        }`}
      >
        <button
          onClick={() => setOpen(!open)}
          className={`bg-gray-500 rounded-sm group hover:bg-gray-700 hover:p-1 duration-200 transition-all cursor-pointer navDropdownButton h-10 ${
            !open && isCustomizing ? "left-[386px]" : ""
          }`}
          style={{
            position: !open && isCustomizing ? "fixed" : "relative",
          }}
        >
          <p className="navDropdown -mt-[9px] bg-white text-black !shadow-2xl !shadow-black py-1 px-3 transition-all rounded-md transform ml-6 flex-col items-center z-20 w-fit fixed">
            {open ? "Show Full Screen" : "Exit Full Screen"}
          </p>
          {open ? (
            <ChevronLeft
              size={14}
              className="text-white group-hover:scale-150 duration-200 transition-all"
            />
          ) : (
            <ChevronRight
              size={14}
              className="text-white group-hover:scale-150 duration-200 transition-all"
            />
          )}
        </button>
      </div> */}

      {/* Delete Modal */}
      {modalType === "delete" && (
        <Modal
          open={show}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          className="max-w-md"
          handleModalSubmit={deleteProject}
          modalType="delete"
          disabled={
            confirmationText?.toLowerCase() !==
            projectToManage?.project_name?.toLowerCase()
          }
          deleteButtonText="Delete This Project"
        >
          <p className="text-lg font-semibold">
            Are you sure you want delete this project?
          </p>
          <p className="text-red-500 mb-4 text-sm">
            This will permanently delete the {projectToManage?.project_name}{" "}
            project data, articles, logo, and all the tags.
          </p>
          <InputField
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
            label={`To confirm, type "${projectToManage?.project_name}" in the box below`}
            className="mb-3"
          />
        </Modal>
      )}

      {/* Add this new Users Modal */}
      {modalType === "users" && (
        <Modal
          open={show}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          className="max-w-lg"
          title="Assign Users to Project"
          handleModalSubmit={assignUsersToProject}
          modalType="new"
          saveButtonText="Assign Users"
        >
          <div className="space-y-4">
            <ComboBox
              label="Select Users"
              options={users
                ?.filter((user) => user?.role_id?.name === "support")
                ?.map((user) => ({
                  _id: user._id,
                  name: user.first_name + " " + user.last_name,
                }))}
              selectedOption={selectedUsers}
              setSelectedOption={setSelectedUsers}
              placeholder="Search users..."
              multiple={true}
            />

            {selectedUsers.length > 0 && (
              <div className="mt-4">
                <p className="text-sm font-medium mb-2">Selected Users:</p>
                <div className="space-y-2">
                  {selectedUsers.map((user) => (
                    <div
                      key={user._id}
                      className="flex items-center justify-between bg-gray-50 dark:bg-gray-700 p-2 rounded"
                    >
                      <span>{user.name}</span>
                      <button
                        onClick={() =>
                          setSelectedUsers((prev) =>
                            prev.filter((u) => u._id !== user._id)
                          )
                        }
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
      <Toaster />
    </div>
  );
}
