import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-hot-toast";
import { Briefcase, List, Copy, Check, X, FolderKanban } from "lucide-react"; // Import Briefcase icon for industries

export default function ListIndustries() {
  const [industries, setIndustries] = useState([]);
  const [projects, setProjects] = useState([]); // Initialize as empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProject, setSelectedProject] = useState("");

  // Fetch projects first
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://apipm.ecommcube.com/list_projects",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        // Ensure we're setting an array, even if the response is empty
        setProjects(response.data?.projects || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setError("Failed to fetch projects");
        toast.error("Failed to fetch projects");
        setLoading(false);
        setProjects([]); // Set empty array on error
      }
    };

    fetchProjects();
  }, []);

  // Fetch industries when a project is selected
  useEffect(() => {
    if (!selectedProject) return;

    const fetchIndustries = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://apipm.ecommcube.com/list_industries?projectName=${selectedProject}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        setIndustries(response.data?.industries || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching industries:", error);
        setError("Failed to fetch industries");
        toast.error("Failed to fetch industries");
        setLoading(false);
        setIndustries([]); // Set empty array on error
      }
    };

    fetchIndustries();
  }, [selectedProject]);

  const handleProjectChange = (e) => {
    setSelectedProject(e.target.value);
  };

  const [loadingTitles, setLoadingTitles] = useState(false);

  const fetchTitlesByIndustry = async (projectName) => {
    // Show modal first
    setShowTitlesModal(true);
    setLoadingTitles(true);

    try {
      const response = await axios.get(
        `https://apipm.ecommcube.com/get_titles_by_industry?projectName=${projectName}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setTitlesByIndustry(response.data);
    } catch (error) {
      console.error("Error fetching titles:", error);
      toast.error("Failed to fetch titles");
    } finally {
      setLoadingTitles(false);
    }
  };

  const handleCopyTitle = async (title) => {
    try {
      await navigator.clipboard.writeText(title);
      setCopiedId(title);
      setTimeout(() => setCopiedId(null), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy text: ", err);
      toast.error("Failed to copy text");
    }
  };

  const [titlesByIndustry, setTitlesByIndustry] = useState(null);
  const [showTitlesModal, setShowTitlesModal] = useState(false);
  const [copiedId, setCopiedId] = useState(null);

  if (loading && !projects.length) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="relative">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-6xl mx-auto p-8"
      >
        <div className="mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Industries List
          </h1>

          {/* Project Selection Dropdown */}
          <div className="mb-6">
            <label
              htmlFor="project"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
            >
              Select Project
            </label>
            <select
              id="project"
              value={selectedProject}
              onChange={handleProjectChange}
              className="w-full md:w-1/2 px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="">Select a project</option>
              {Array.isArray(projects) &&
                projects.map((project, index) => (
                  <option key={index} value={project}>
                    {project}
                  </option>
                ))}
            </select>
          </div>

          {selectedProject && (
            <div className="flex items-center space-x-4 text-gray-600 dark:text-gray-400 mb-4">
              <div className="flex items-center">
                <FolderKanban className="h-5 w-5 mr-2 text-blue-500" />
                <span>{selectedProject}</span>
              </div>
              <div className="flex items-center">
                <Briefcase className="h-5 w-5 mr-2 text-purple-500" />
                <span>Total Industries: {industries?.length || 0}</span>
              </div>
            </div>
          )}
        </div>

        {loading && selectedProject ? (
          <div className="flex items-center justify-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="p-8 text-center">
            <div className="text-red-500 mb-4">{error}</div>
            <button
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Retry
            </button>
          </div>
        ) : selectedProject ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {Array.isArray(industries) &&
                industries.map((industry, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="bg-gradient-to-br from-white to-gray-50 dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden"
                  >
                    <div className="p-6">
                      <div className="flex items-center space-x-4 mb-4">
                        <div className="p-3 bg-purple-50 dark:bg-purple-900/20 rounded-xl group-hover:bg-purple-100 dark:group-hover:bg-purple-900/30 transition-colors">
                          <Briefcase className="h-6 w-6 text-purple-500" />
                        </div>
                        <div className="flex-1">
                          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            {industry}
                          </h3>
                          <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                            Industry #{index + 1}
                          </p>
                        </div>
                      </div>
                      
                      <div className="mt-4 space-y-2">
                        <button
                          onClick={() => fetchTitlesByIndustry(selectedProject)}
                          className="w-full flex items-center justify-center space-x-2 px-4 py-2.5 bg-purple-50 dark:bg-purple-900/20 text-purple-600 dark:text-purple-400 rounded-lg hover:bg-purple-100 dark:hover:bg-purple-900/30 transition-all duration-300 font-medium"
                        >
                          <List className="h-4 w-4" />
                          <span>View Titles</span>
                        </button>
                      </div>
                    </div>
                  </motion.div>
                ))}
            </div>

            {(!industries || industries.length === 0) && (
              <div className="text-center text-gray-500 dark:text-gray-400 mt-8 p-8 bg-gray-50 dark:bg-gray-800 rounded-xl border border-gray-100 dark:border-gray-700">
                <Briefcase className="h-12 w-12 mx-auto mb-4 text-gray-400" />
                <p className="text-lg font-medium">No industries found for this project</p>
                <p className="text-sm mt-2">Try selecting a different project</p>
              </div>
            )}
          </>
        ) : (
          <div className="text-center text-gray-500 dark:text-gray-400 mt-8 p-8 bg-gray-50 dark:bg-gray-800 rounded-xl border border-gray-100 dark:border-gray-700">
            <Briefcase className="h-12 w-12 mx-auto mb-4 text-gray-400" />
            <p className="text-lg font-medium">Please select a project to view industries</p>
            <p className="text-sm mt-2">Choose a project from the dropdown above</p>
          </div>
        )}
      </motion.div>

      {/* Titles by Industry Modal */}
      {showTitlesModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-4xl w-full mx-4 max-h-[80vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                  Titles by Industry
                </h2>
                {titlesByIndustry && (
                  <p className="text-gray-600 dark:text-gray-400">
                    Industry: {titlesByIndustry.industry}
                  </p>
                )}
              </div>
              <button
                onClick={() => {
                  setShowTitlesModal(false);
                  setTitlesByIndustry(null);
                }}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            {loadingTitles ? (
              <div className="flex justify-center py-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : titlesByIndustry ? (
              <div className="space-y-8">
                {titlesByIndustry.categories.map((category, index) => (
                  <div
                    key={index}
                    className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4"
                  >
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                      {category.category}
                    </h3>
                    <div className="space-y-2">
                      {category.titles.map((title) => (
                        <div
                          key={title.taskId}
                          className="bg-white dark:bg-gray-800 p-3 rounded-md shadow-sm flex justify-between items-start gap-4"
                        >
                          <div className="flex-1">
                            <p className="text-gray-800 dark:text-gray-200">
                              {title.title}
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                              Task ID: {title.taskId}
                            </p>
                          </div>
                          <button
                            onClick={() => handleCopyTitle(title.title)}
                            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                            title="Copy title"
                          >
                            {copiedId === title.title ? (
                              <Check className="h-4 w-4 text-green-500" />
                            ) : (
                              <Copy className="h-4 w-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200" />
                            )}
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-500 dark:text-gray-400 text-center py-8">
                No titles available
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
