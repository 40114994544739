import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  LayoutTemplate,
  LoaderCircle,
  Users,
  Settings,
  Bell,
  Globe,
  Search,
  BarChart3,
  AlertCircle,
  PanelsTopLeft,
  Upload,
} from "lucide-react";
import useApi from "../../utils/useApi";
import { toast } from "react-hot-toast";
import usePermissions from "../../utils/userPermission";

const ProjectImageWithPreview = ({ thumbnail, templateId, manageProject }) => {
  const [position, setPosition] = useState({ top: "auto", bottom: "auto" });
  const imageRef = useRef(null);

  const updatePosition = useCallback(() => {
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      const newPosition =
        rect.top < 0
          ? { top: "0", bottom: "auto" }
          : rect.bottom > windowHeight
          ? { top: "auto", bottom: "0" }
          : { top: "0", bottom: "auto" };

      setPosition(newPosition);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", updatePosition);
    window.addEventListener("resize", updatePosition);

    updatePosition();

    return () => {
      window.removeEventListener("scroll", updatePosition);
      window.removeEventListener("resize", updatePosition);
    };
  }, [updatePosition]);

  const imageUrl = `${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${templateId}/thumb/${thumbnail}`;

  return (
    <div className="relative tempImg">
      <div className="aspect-video w-full bg-gray-100 dark:bg-gray-700 relative group overflow-hidden cursor-pointer">
        {thumbnail ? (
          <img
            src={imageUrl}
            alt="Template Preview"
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center text-gray-400">
            <LayoutTemplate className="w-8 h-8" />
          </div>
        )}
        <div className="absolute inset-0 bg-black/60 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center gap-3">
          <button
            onClick={manageProject}
            className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
          >
            <Settings className="w-5 h-5" />
          </button>
        </div>
      </div>
      {thumbnail && (
        <div
          ref={imageRef}
          className="absolute hidden group-hover:block bg-white left-0 ml-[230px] scale-125 z-50 imgToShow p-1 rounded-md shadow-xl"
          style={position}
        >
          <div className="overflow-hidden cursor-pointer transition-all h-96 overflow-y-scroll relative w-[200px]">
            <img
              src={imageUrl}
              alt="Template Preview"
              className="absolute top-0 left-0 w-full h-auto"
              width={200}
              height={300}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const timeAgo = (datePast) => {
  const now = new Date();
  const past = new Date(datePast);
  const diff = now - past;

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (seconds < 60) return `${seconds} seconds ago`;
  if (minutes < 60) return `${minutes} minutes ago`;
  if (hours < 24) return `${hours} hours ago`;
  if (days < 30) return `${days} days ago`;
  if (months < 12) return `${months} months ago`;
  return `${years} years ago`;
};

const ProjectCard = ({ project }) => {
  const [isDeploying, setIsDeploying] = useState(false);
  const { request } = useApi();
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();

  const deployProject = async () => {
    setIsDeploying(true);
    try {
      await request({
        method: "get",
        url: `projects/${project?._id}/deploy`,
      });
      toast.success("Project Deployed");
    } catch (err) {
      toast.error(err.response?.data?.message || "Deployment failed ");
    } finally {
      setIsDeploying(false);
    }
  };

  const manageProject = () => {
    navigate(`/projects/${project?.slug}`);
  };

  const lastUpdatedAt = new Date(project.last_updated_at || project.updatedAt);
  const deployedOn = new Date(project.deployed_on);
  const timeAgoCreated = timeAgo(project.createdAt);
  const timeAgoUpdated = timeAgo(project.last_updated_at || project.updatedAt);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden border border-gray-100 dark:border-gray-700 hover:shadow-xl transition-all duration-300">
      <ProjectImageWithPreview
        thumbnail={project.industry_template_id?.thumbnail}
        templateId={project.industry_template_id?._id}
        manageProject={manageProject}
      />

      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="font-semibold text-gray-900 dark:text-white text-lg">
            {project.project_name}
          </h3>
          <span
            className={`px-3 py-1 rounded-full text-xs font-medium ${
              project.is_deployed
                ? "bg-green-100 text-green-700 dark:bg-green-900/30 dark:text-green-400"
                : "bg-yellow-100 text-yellow-700 dark:bg-yellow-900/30 dark:text-yellow-400"
            }`}
          >
            {project.is_deployed ? "Live" : "Draft"}
          </span>
        </div>

        <div className="space-y-3">
          <div className="flex items-center gap-2 text-sm">
            <Globe className="w-4 h-4 text-gray-400" />
            <span className="text-gray-600 dark:text-gray-300">
              {project.domain_id?.domain}
            </span>
          </div>

          <div className="flex flex-wrap gap-2">
            <span className="px-2 py-1 bg-primary/10 rounded-md text-xs text-primary">
              {project.industry_id?.industry_name}
            </span>
            <span className="px-2 py-1 bg-gray-100 dark:bg-gray-700 rounded-md text-xs text-gray-600 dark:text-gray-400">
              {project.industry_template_id?.template_type}
            </span>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 pt-5 pb-4 border-t border-gray-100 dark:border-gray-700 mt-3">
          <div className="text-center">
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Domain Rating
            </p>
            <p className="font-semibold text-gray-900 dark:text-white">
              {project.domain_id?.ahref_info?.domain_rating?.domain_rating ||
                "N/A"}
            </p>
          </div>
          <div className="text-center">
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Backlinks
            </p>
            <p className="font-semibold text-gray-900 dark:text-white">
              {project.domain_id?.ahref_info?.backlinks_stats?.live?.toLocaleString() ||
                "N/A"}
            </p>
          </div>
        </div>

        <div className="border-t border-gray-100 dark:border-gray-700 pt-4 space-y-2">
          <div className="flex items-center justify-between">
            <div className="text-sm text-gray-500 dark:text-gray-400">
              <span className="font-medium text-gray-900 dark:text-white">
                {project.createdBy?.first_name} {project.createdBy?.last_name}
              </span>
            </div>
            <div className="flex gap-2">
              {hasPermission("deploy project") &&
                project?.is_deployed &&
                lastUpdatedAt.getTime() > deployedOn.getTime() && (
                  <button
                    onClick={deployProject}
                    className={`inline-flex items-center gap-1.5 px-3 py-1.5 rounded-full text-xs font-medium text-primary hover:bg-primary/10 transition-colors ${
                      isDeploying ? "bg-primary/10" : ""
                    }`}
                  >
                    {isDeploying ? (
                      <LoaderCircle className="w-3.5 h-3.5 animate-spin" />
                    ) : (
                      <Upload className="w-3.5 h-3.5" />
                    )}
                    {isDeploying ? "Publishing" : "Publish"}
                  </button>
                )}
            </div>
          </div>

          <div className="flex items-center justify-between text-xs text-gray-500 dark:text-gray-400">
            <span>Updated {timeAgoUpdated}</span>
            <span>Created {timeAgoCreated}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const MetricCard = ({ label, value, trend, icon }) => (
  <div className="bg-white dark:bg-gray-800 rounded-xl p-6 border border-gray-100 dark:border-gray-700">
    <div className="flex items-center justify-between mb-4">
      <div className="p-2 bg-primary/10 rounded-lg">{icon}</div>
      <span
        className={`text-sm ${trend > 0 ? "text-green-500" : "text-red-500"}`}
      >
        {trend > 0 ? "+" : ""}
        {trend}%
      </span>
    </div>
    <h4 className="text-2xl font-bold text-gray-900 dark:text-white mb-1">
      {value}
    </h4>
    <p className="text-sm text-gray-600 dark:text-gray-400">{label}</p>
  </div>
);

export default function Home() {
  const user = JSON.parse(localStorage.getItem("user"));
  const { request, isLoading } = useApi();
  const [projects, setProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");

  const { hasPermission } = usePermissions();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await request({ method: "get", url: "projects" });
        setProjects(response.data || []);
        console.log(response.data);
      } catch (err) {
        console.error(err);
        setProjects([]);
      }
    };

    fetchProjects();
  }, []);

  const filteredProjects = projects
    .filter(
      (project) =>
        project?.project_name
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) &&
        (filterStatus === "all" || project?.is_deployed === filterStatus)
    )
    .reverse();

  return (
    <div className="min-h-screen dark:bg-gray-900">
      {/* Modern Dark & Orange Banner */}
      <div className="relative bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-white mb-8 mt-8 mx-8 rounded-2xl overflow-hidden">
        {/* Background Effects */}
        <div className="absolute inset-0">
          {/* Gradient Overlay */}
          <div
            className="absolute inset-0 opacity-80"
            style={{
              background:
                "linear-gradient(120deg, rgba(251,146,60,0.05) 0%, rgba(251,146,60,0.02) 50%, rgba(251,146,60,0.05) 100%)",
              backgroundSize: "200% 200%",
              animation: "gradientMove 8s linear infinite",
            }}
          ></div>

          {/* Tech Grid Pattern */}
          <div
            className="absolute inset-0 opacity-[0.07]"
            style={{
              backgroundImage: `linear-gradient(rgba(251,146,60,0.3) 1px, transparent 1px),
                               linear-gradient(90deg, rgba(251,146,60,0.3) 1px, transparent 1px)`,
              backgroundSize: "50px 50px",
            }}
          ></div>

          {/* Animated Lines */}
          <div className="absolute inset-0">
            {[...Array(3)].map((_, i) => (
              <div
                key={i}
                className="absolute h-[1px] bg-gradient-to-r from-transparent via-orange-500/30 to-transparent"
                style={{
                  width: "70%",
                  top: `${30 * (i + 1)}%`,
                  left: "15%",
                  animation: `scanline ${6 + i}s linear infinite ${i * 1}s`,
                }}
              ></div>
            ))}
          </div>

          {/* Corner Accents */}
          <div className="absolute top-0 left-0 w-32 h-32">
            <div className="absolute top-0 left-0 w-full h-[1px] bg-gradient-to-r from-orange-500/50 to-transparent"></div>
            <div className="absolute top-0 left-0 h-full w-[1px] bg-gradient-to-b from-orange-500/50 to-transparent"></div>
          </div>
          <div className="absolute top-0 right-0 w-32 h-32">
            <div className="absolute top-0 right-0 w-full h-[1px] bg-gradient-to-l from-orange-500/50 to-transparent"></div>
            <div className="absolute top-0 right-0 h-full w-[1px] bg-gradient-to-b from-orange-500/50 to-transparent"></div>
          </div>
          <div className="absolute bottom-0 left-0 w-32 h-32">
            <div className="absolute bottom-0 left-0 w-full h-[1px] bg-gradient-to-r from-orange-500/50 to-transparent"></div>
            <div className="absolute bottom-0 left-0 h-full w-[1px] bg-gradient-to-t from-orange-500/50 to-transparent"></div>
          </div>
          <div className="absolute bottom-0 right-0 w-32 h-32">
            <div className="absolute bottom-0 right-0 w-full h-[1px] bg-gradient-to-l from-orange-500/50 to-transparent"></div>
            <div className="absolute bottom-0 right-0 h-full w-[1px] bg-gradient-to-t from-orange-500/50 to-transparent"></div>
          </div>

          {/* Floating Elements */}
          <div className="absolute inset-0 overflow-hidden">
            {[...Array(10)].map((_, i) => (
              <div
                key={i}
                className="absolute rounded-full bg-orange-500/10 backdrop-blur-sm"
                style={{
                  width: `${Math.random() * 3 + 1}rem`,
                  height: `${Math.random() * 3 + 1}rem`,
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  animation: `float ${
                    10 + Math.random() * 10
                  }s infinite ease-in-out ${Math.random() * 5}s`,
                }}
              ></div>
            ))}
          </div>

          {/* Subtle Glow Effects */}
          <div className="absolute -left-32 top-1/3 w-64 h-64 bg-orange-500/5 rounded-full filter blur-[100px]"></div>
          <div className="absolute -right-32 bottom-1/3 w-64 h-64 bg-orange-500/5 rounded-full filter blur-[100px]"></div>
        </div>

        {/* Content Container */}
        <div className="max-w-[1600px] mx-auto px-4 sm:px-9 lg:px-14 py-8 relative z-10">
          <div className="relative flex items-center justify-between">
            <div className="space-y-8">
              <div className="relative">
                <div className="absolute -left-4 top-0 w-1 h-full bg-gradient-to-b from-transparent via-white/50 to-transparent opacity-50"></div>
                <h1 className="text-6xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-white/50 via-blue-100 to-white animate-gradient">
                  Welcome back, {user?.first_name}
                </h1>
                <p className="text-xl text-white/80 ml-1">
                  Your project dashboard is ready
                </p>
              </div>

              <div className="flex gap-8">
                <div className="flex items-center gap-4 group">
                  <div className="h-20 w-20 rounded-2xl bg-white/10 flex items-center justify-center backdrop-blur-md border border-white/10 shadow-lg relative overflow-hidden group-hover:border-white/30 transition-all duration-300">
                    <div className="absolute inset-0 bg-gradient-to-tr from-primary/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity"></div>
                    <div className="absolute inset-0 bg-gradient-to-br from-white/5 to-transparent"></div>
                    <Globe className="w-10 h-10 relative z-10 group-hover:scale-110 transition-transform text-white/90" />
                    <div className="absolute -inset-1 bg-gradient-to-tr from-primary/20 to-transparent blur opacity-0 group-hover:opacity-100 transition-opacity"></div>
                  </div>
                  <div className="relative">
                    <p className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-100">
                      {projects.filter((p) => p.is_deployed).length}
                    </p>
                    <p className="text-sm text-white/60 font-medium tracking-wide">
                      Live Projects
                    </p>
                  </div>
                </div>

                <div className="flex items-center gap-4 group">
                  <div className="h-20 w-20 rounded-2xl bg-white/10 flex items-center justify-center backdrop-blur-md border border-white/10 shadow-lg relative overflow-hidden group-hover:border-white/30 transition-all duration-300">
                    <div className="absolute inset-0 bg-gradient-to-tr from-primary/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity"></div>
                    <div className="absolute inset-0 bg-gradient-to-br from-white/5 to-transparent"></div>
                    <BarChart3 className="w-10 h-10 relative z-10 group-hover:scale-110 transition-transform text-white/90" />
                    <div className="absolute -inset-1 bg-gradient-to-tr from-primary/20 to-transparent blur opacity-0 group-hover:opacity-100 transition-opacity"></div>
                  </div>
                  <div className="relative">
                    <p className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-100">
                      {projects.length}
                    </p>
                    <p className="text-sm text-white/60 font-medium tracking-wide">
                      Total Projects
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden lg:block">
              <div className="relative w-80 h-80">
                {/* Circular animations */}
                <div className="absolute inset-0 bg-gradient-to-tr from-white/20 to-transparent rounded-full animate-[spin_10s_linear_infinite]"></div>
                <div className="absolute inset-4 bg-gradient-to-tr from-white/20 to-transparent rounded-full animate-[spin_15s_linear_infinite]"></div>
                <div className="absolute inset-8 bg-gradient-to-tr from-white/20 to-transparent rounded-full animate-[spin_20s_linear_infinite]"></div>

                {/* Floating particles */}
                {[...Array(12)].map((_, i) => (
                  <div
                    key={i}
                    className="absolute w-2 h-2 bg-white rounded-full"
                    style={{
                      left: `${Math.random() * 100}%`,
                      top: `${Math.random() * 100}%`,
                      animation: `float ${
                        2 + Math.random() * 4
                      }s infinite ease-in-out ${Math.random() * 2}s`,
                    }}
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Rest of the dashboard */}
      <div className="max-w-[1600px] mx-auto px-4 sm:px-6 lg:px-8">
        {/* Metrics Overview */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <MetricCard
            label="Total Projects"
            value={projects.length}
            trend={12}
            icon={<Globe className="w-5 h-5 text-primary" />}
          />
          <MetricCard
            label="Live Sites"
            value={projects.filter((p) => p.is_deployed).length}
            trend={8}
            icon={<BarChart3 className="w-5 h-5 text-primary" />}
          />
          <MetricCard
            label="Active Users"
            value="2,847"
            trend={-3}
            icon={<Users className="w-5 h-5 text-primary" />}
          />
          <MetricCard
            label="System Alerts"
            value="7"
            trend={2}
            icon={<AlertCircle className="w-5 h-5 text-primary" />}
          />
        </div>

        {/* Projects List */}
        <div className="bg-gray-50 dark:bg-gray-800 rounded-xl p-6 mb-8">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-bold text-gray-900 dark:text-white">
              All Projects
            </h2>
            <div className="flex items-center gap-4">
              <select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                className="px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900"
              >
                <option value="all">All Status</option>
                <option value="live">Live</option>
                <option value="draft">Draft</option>
              </select>
              <div className="relative">
                <Search className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search projects..."
                  className="pl-10 pr-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {isLoading ? (
              <div className="col-span-full flex justify-center py-12">
                <LoaderCircle className="w-8 h-8 animate-spin text-primary" />
              </div>
            ) : filteredProjects.length > 0 ? (
              filteredProjects.map((project) => (
                <ProjectCard key={project._id} project={project} />
              ))
            ) : (
              <div className="col-span-full text-center py-12 text-gray-500">
                No projects found
              </div>
            )}
          </div>
        </div>

        {/* System Status */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 border border-gray-100 dark:border-gray-700">
            <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
              Recent Activity
            </h2>
            {/* Add recent activity list here */}
          </div>
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 border border-gray-100 dark:border-gray-700">
            <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
              System Health
            </h2>
            {/* Add system health metrics here */}
          </div>
        </div>
      </div>
    </div>
  );
}
