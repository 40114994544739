import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, ListBox } from "../../components";
import {
  Github,
  LoaderCircle,
  PlusCircle,
  RefreshCw,
  SquarePen,
} from "lucide-react";
import useApi from "../../utils/useApi";
import toast, { Toaster } from "react-hot-toast";
import AddUpdateTemplate from "./AddUpdateTemplate";
import dayjs from "dayjs";
import usePermissions from "../../utils/userPermission";
import Pagination from "../../components/common/Pagination";
import Status from "../../components/common/Status";

export default function Templates() {
  const { request } = useApi();
  const { templateType } = useParams();
  const [industry, setIndustry] = useState(null);
  const [selectIndustry, setSelectedIndustry] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setpage] = useState(1);
  const itemsPerPage = 12;

  const getTemplates = async () => {
    setLoading(true);
    try {
      const data = await request({
        method: "get",
        url: `industries/${
          selectIndustry ? selectIndustry?._id : "All"
        }/templates`,
      });
      setTemplates(data.data);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const getIndustries = async () => {
    try {
      const data = await request({ method: "get", url: "industries" });
      setIndustries(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getIndustries();
  }, [templateType]);

  useEffect(() => {
    getTemplates();
  }, [selectIndustry]);

  const navigate = useNavigate();
  const viewTemplate = (template) => {
    navigate(
      `/templates/${template?.template_name
        ?.toLowerCase()
        .replaceAll(" ", "-")}`
    );
  };

  // Search Query
  const [searchQuery, setSearchQuery] = useState(""); // Step 1
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredTemplates = templates.filter((template) =>
    template.template_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredTemplates.length / itemsPerPage);
  const currentTemplates = filteredTemplates.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
  };

  // Sidebar
  const [sidebar, setSidebar] = useState(false);
  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const activeStatus = [
    { _id: 1, name: "Active", value: true },
    { _id: 2, name: "InActive", value: false },
  ];
  const [active, setActive] = useState({});
  const tempTypes = [
    { _id: 1, name: "NEXT-14" },
    { _id: 2, name: "STATIC" },
  ];
  const [template_name, setTemplateName] = useState("");
  const [tempType, setTempType] = useState(tempTypes[0]);
  const [repo_url, setRepoURL] = useState("");
  const [port, setPort] = useState("");
  const [fileInfo, setFileInfo] = useState(null);
  const handleFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setFileInfo({
      file: file,
      preview: URL.createObjectURL(file),
    });
  };

  const [modalType, setModalType] = useState("");

  const addTemplate = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("template_name", template_name);
      formData.append("repo_url", repo_url);
      formData.append("port", port);
      formData.append("template_type", tempType.name);
      if (fileInfo?.file) {
        formData.append("thumb", fileInfo?.file);
      }
      await request({
        method: "post",
        url: `industries/${industry?._id}/templates`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(`New Template Created.`);
      getTemplates();
      toggleSidebar();
      setTemplateName("");
      setTempType({});
      setIndustry({});
      setRepoURL("");
      setPort("");
      setFileInfo(null);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const [templateToEdit, setTemplateToEdit] = useState({});
  const handleEditTemplate = (template) => {
    setModalType("edit");

    const industry = {
      _id: template.industry_id._id,
      name: template.industry_id.industry_name,
    };

    setIndustry(industry);
    setTemplateToEdit(template);
    setPort(template.port);
    setTemplateName(template.template_name);
    setRepoURL(template.repo_url);

    const type = tempTypes.find((item) => item.name === template.template_type);
    setTempType(type);
    const a = activeStatus?.find((item) => item.value === template?.active);
    setActive(a);

    setFileInfo({
      file: template.thumbnail,
      preview: `${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${template?._id}/thumb/${template.thumbnail}`,
    });
    toggleSidebar();
  };

  const updateTemplate = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("template_name", template_name);
      formData.append("repo_url", repo_url);
      formData.append("port", port);
      formData.append("active", active.value);
      formData.append("template_type", tempType.name);
      if (fileInfo?.file) {
        formData.append("thumb", fileInfo?.file);
      }
      await request({
        method: "put",
        url: `/industries/${templateToEdit?.templateToEdit?.industry_id?._id}/templates/${templateToEdit?._id}`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(`Template Updated.`);
      getTemplates();
      toggleSidebar();
      setTemplateName("");
      setTempType({});
      setIndustry({});
      setRepoURL("");
      setPort("");
      setFileInfo(null);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const { hasPermission } = usePermissions();
  const startItem = (page - 1) * itemsPerPage + 1;
  const endItem = Math.min(page * itemsPerPage, filteredTemplates.length);
  const totalItems = filteredTemplates.length;

  return (
    <div>
      <Toaster />
      <div className="flex items-center justify-between gap-5 px-6 py-2 bg-warmGray dark:bg-gray-800">
        <div className="text-white flex items-center gap-5">
          <h4>Templates</h4>
          <RefreshCw
            onClick={getTemplates}
            className={`w-5 cursor-pointer dark:text-white/80 ${
              loading && "animate-spin"
            }`}
          />
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={page}
          handlePageChange={handlePageChange}
        />

        <div className="flex items-center gap-2 flex-1">
          <div className="flex items-center flex-1 bg-white dark:bg-gray-600 rounded">
            <ListBox
              name="industry"
              options={industries?.map((industry) => ({
                _id: industry._id,
                name: industry.industry_name,
              }))}
              selectedOption={selectIndustry}
              setSelectedOption={setSelectedIndustry}
              optionsStyle="mt-8"
              placeholder="Filter By Category"
              inputStyle="border-none py-1"
              className="w-48"
            />
            <input
              type="text"
              className="border-l h-full py-1 px-5 outline-none bg-transparent flex-1 dark:border-white/20"
              placeholder="Search Template"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
          {hasPermission("Add Template") && (
            <button
              onClick={() => {
                setModalType("new");
                setTemplateName("");
                setTempType({});
                setIndustry({});
                setRepoURL("");
                setPort("");
                setFileInfo(null);
                toggleSidebar();
              }}
              className="btnPrimary rounded text-base px-3 py-1"
            >
              <PlusCircle className="h-4 w-4" /> Add Template
            </button>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between w-full px-6 mt-2">
        <div>
          <Breadcrumbs />
        </div>
        <p className="text-gray-700 dark:text-gray-400 whitespace-nowrap">
          Showing {startItem} to {endItem} of {totalItems} templates
        </p>
      </div>

      {loading ? (
        <div className="flex flex-col items-center w-full p-52 gap-5">
          <LoaderCircle className="h-12 w-12 animate-spin text-primary" />
          <p>Loading Templates...</p>
        </div>
      ) : filteredTemplates.length > 0 ? (
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 px-6 mt-2">
          {currentTemplates?.map((template, index) => (
            <div className="bg-white dark:bg-gray-950 rounded-md shadow-lg grid grid-cols-templateBox shadow-black/20">
              <div className="relative tempImg">
                <div
                  onClick={() => viewTemplate(template)}
                  className="overflow-hidden cursor-pointer relative transition-all h-full w-full top-0 left-0 rounded-l"
                >
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${template?._id}/thumb/${template.thumbnail}`}
                    alt="Thumbnail Not Found"
                    className="absolute top-0 left-0 w-full h-auto"
                  />
                </div>
                <div
                  onClick={() => viewTemplate(template)}
                  className="absolute top-0 bg-white left-0 ml-[280px] scale-125 z-50 imgToShow p-1 rounded-md"
                >
                  <div
                    onClick={() => viewTemplate(template)}
                    className="overflow-hidden cursor-pointer transition-all h-96 relative w-[200px]"
                  >
                    <div className="overflow-hidden cursor-pointer transition-all h-96 overflow-y-scroll relative w-[200px]">
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${template?._id}/thumb/${template.thumbnail}`}
                        alt="Thumbnail Not Found"
                        className="absolute top-0 left-0 w-full h-auto"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between p-3">
                <div className="text-sm">
                  <div className="flex items-center justify-between">
                    <p className="font-medium text-xl">
                      {template.template_name}{" "}
                      <span className="text-lg text-gray-400 ml-1 font-normal">
                        ({template?.industry_id?.industry_name})
                      </span>
                    </p>
                    <p className="text-gray-500 mr-2">
                      {template.template_type}
                    </p>
                  </div>
                  {hasPermission("template created at") && (
                    <div className="flex items-center text-gray-500 mt-1">
                      <span className="font-medium mr-1">Created:</span>
                      {dayjs(template.createdAt)?.format("D-MMM-YYYY")}
                      <span className="font-medium mr-1 ml-3">By:</span>
                      {template?.createdBy?.first_name +
                        " " +
                        template?.createdBy?.last_name}{" "}
                      <p className="bg-gray-100 ml-1 rounded-full px-2 text-xs">
                        {template?.createdBy?.username}
                      </p>
                    </div>
                  )}
                  {template.url ? (
                    <p className="mt-1">
                      Template URL:{" "}
                      <a
                        href={`https://${template.url}`}
                        target="_blank"
                        rel="noreferrer"
                        className="underline hover:text-primary transition-all cursor-pointer"
                      >
                        {template.url}
                      </a>
                    </p>
                  ) : null}
                  {hasPermission("template repo") && (
                    <div className="flex items-center gap-1 text-gray-500 mt-1">
                      <Github className="w-4 h-4" />
                      <a
                        href={template.repo_url}
                        target="_blank"
                        rel="noreferrer"
                        className="underline hover:text-primary transition-all cursor-pointer"
                      >
                        {template.repo_url}
                      </a>
                    </div>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex gap-2 mt-2">
                    {hasPermission("Edit Template") && (
                      <button
                        className="btnWhite px-3 py-1"
                        onClick={() => handleEditTemplate(template)}
                      >
                        <SquarePen className="w-4 h-4 cursor-pointer" />
                        Edit Info
                      </button>
                    )}
                    {hasPermission("Edit Template") && (
                      <button
                        onClick={() => viewTemplate(template)}
                        className="btnPrimary py-1"
                      >
                        Manage Content
                      </button>
                    )}
                  </div>
                  <Status type={template.active ? "active" : "inActive"} />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center text-gray-500 py-44">
          No templates found
        </div>
      )}

      <AddUpdateTemplate
        sidebar={sidebar}
        toggleSidebar={toggleSidebar}
        industries={industries}
        tempType={tempType}
        setTempType={setTempType}
        tempTypes={tempTypes}
        fileInfo={fileInfo}
        handleFileChange={handleFileChange}
        industry={industry}
        setIndustry={setIndustry}
        repo_url={repo_url}
        setRepoURL={setRepoURL}
        port={port}
        setPort={setPort}
        template_name={template_name}
        setTemplateName={setTemplateName}
        addTemplate={addTemplate}
        updateTemplate={updateTemplate}
        modalType={modalType}
        active={active}
        setActive={setActive}
        activeStatus={activeStatus}
      />
    </div>
  );
}
