import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { toast } from "react-hot-toast";
import { FolderKanban, X, Info, Trash2 } from "lucide-react";

export default function ListProjects() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [deletingProject, setDeletingProject] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://apipm.ecommcube.com/list_projects",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you store the token in localStorage
              "Content-Type": "application/json",
            },
          }
        );

        setProjects(response?.data?.projects || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setError(error.response?.data?.message || "Failed to fetch projects");
        toast.error("Failed to fetch projects");
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const fetchProjectDetails = async (projectName) => {
    setLoadingDetails(true);
    try {
      const response = await axios.get(
        `https://apipm.ecommcube.com/get_project_details?projectName=${projectName}`
      );
      setProjectDetails(response.data);
    } catch (error) {
      console.error("Error fetching project details:", error);
      toast.error("Failed to fetch project details");
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleDeleteProject = async (projectName) => {
    try {
      await axios.delete(
        `https://apipm.ecommcube.com/delete_project?project_name=${encodeURIComponent(
          projectName
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Remove the project from the local state
      setProjects(projects.filter((project) => project !== projectName));
      toast.success(`Project '${projectName}' deleted successfully`);
      setShowDeleteConfirm(false);
      setDeletingProject(null);
    } catch (error) {
      console.error("Error deleting project:", error);
      toast.error(error.response?.data?.message || "Failed to delete project");
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-8 text-center">
        <div className="text-red-500 mb-4">{error}</div>
        <button
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="relative">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-6xl mx-auto p-8"
      >
        <div className="mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Projects List
          </h1>
          <p className="text-gray-600 dark:text-gray-400">
            Total Projects: {projects.length}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {projects.map((project, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-all duration-300 p-4 group"
            >
              <div className="flex items-center space-x-4 mb-4">
                <div className="p-3 bg-gray-200 dark:bg-gray-900/20 rounded-lg group-hover:bg-gray-100 dark:group-hover:bg-gray-900/30 transition-colors">
                  <FolderKanban className="h-6 w-6 text-gray-500" />
                </div>
                <div className="flex-1">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white group-hover:text-blue-500 transition-colors">
                    {project}
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                    Project ID: {index + 1}
                  </p>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeletingProject(project);
                    setShowDeleteConfirm(true);
                  }}
                  className="p-2 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-full transition-colors"
                  title="Delete project"
                >
                  <Trash2 className="h-5 w-5 text-red-500" />
                </button>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => {
                    setSelectedProject(project);
                    fetchProjectDetails(project);
                  }}
                  className="flex items-center space-x-2 px-3 py-2 bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400 rounded-md hover:bg-blue-100 dark:hover:bg-blue-900/30 transition-colors flex-1"
                >
                  <Info className="h-4 w-4" />
                  <span>Details</span>
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        {projects.length === 0 && (
          <div className="text-center text-gray-500 dark:text-gray-400 mt-8 p-8 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <FolderKanban className="h-12 w-12 mx-auto mb-4 text-gray-400" />
            <p className="text-lg">No projects found</p>
          </div>
        )}
      </motion.div>

      {/* Project Details Modal */}
      {selectedProject && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-2xl w-full mx-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                {selectedProject}
              </h2>
              <button
                onClick={() => {
                  setSelectedProject(null);
                  setProjectDetails(null);
                }}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            {loadingDetails ? (
              <div className="flex justify-center py-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : projectDetails ? (
              <div className="space-y-4">
                {projectDetails.map((detail, index) => (
                  <div
                    key={index}
                    className="border-b dark:border-gray-700 pb-4"
                  >
                    <div className="grid grid-cols-2 gap-4">
                      <div className="text-gray-600 dark:text-gray-400">
                        Category:
                      </div>
                      <div className="font-medium text-gray-900 dark:text-white">
                        {detail.category}
                      </div>
                      <div className="text-gray-600 dark:text-gray-400">
                        Industry:
                      </div>
                      <div className="font-medium text-gray-900 dark:text-white">
                        {detail.industry}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-500 dark:text-gray-400 text-center py-8">
                No details available
              </p>
            )}
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full mx-4">
            <div className="flex items-center justify-center mb-4 text-red-500">
              <Trash2 className="h-12 w-12" />
            </div>
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white text-center mb-4">
              Delete Project
            </h2>
            <p className="text-gray-600 dark:text-gray-400 text-center mb-6">
              Are you sure you want to delete '{deletingProject}'? This action
              cannot be undone.
            </p>
            <div className="flex space-x-4">
              <button
                onClick={() => {
                  setShowDeleteConfirm(false);
                  setDeletingProject(null);
                }}
                className="flex-1 px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDeleteProject(deletingProject)}
                className="flex-1 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
