import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Link2,
  LoaderCircle,
  RefreshCw,
  SlidersHorizontal,
  Share2,
  Calendar,
} from "lucide-react";
import useApi from "../../../utils/useApi";
import { Breadcrumbs, SearchBox } from "../../../components";
import Pagination from "../../../components/common/Pagination";
import ReportFilters from "./ReportFilters";

export default function BacklinkReport() {
  const { request, isLoading } = useApi();
  const location = useLocation();
  const { domain_id } = location.state;

  const [backlinks, setBacklinks] = useState([]);
  const getBacklinksReport = async () => {
    try {
      const res = await request({
        method: "post",
        url: "domains/backlinks/report",
        data: {
          filters: "",
          domain_id,
        },
      });
      setBacklinks(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getBacklinksReport();
  }, [domain_id]);

  const [page, setpage] = useState(1);
  const itemsPerPage = 9;
  const totalPages = Math.ceil(backlinks?.length / itemsPerPage);
  const currentTemplates = backlinks?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
  };

  const startItem = (page - 1) * itemsPerPage + 1;
  const endItem = Math.min(page * itemsPerPage, backlinks?.length);
  const totalItems = backlinks?.length;

  const renderDetailItem = (label, value, className = "") => {
    // Skip rendering if value is empty or undefined
    if (!value) return null;

    return (
      <div
        className={`flex flex-col ${className} p-2 hover:bg-gray-50 dark:hover:bg-gray-700/50 rounded`}
      >
        <span className="text-xs text-gray-500 dark:text-gray-400 mb-1 capitalize">
          {label.replaceAll("_", " ")}
        </span>
        <span className="text-sm text-gray-800 dark:text-gray-200 break-words">
          {value}
        </span>
      </div>
    );
  };

  const [searchQuery, setSearchQuery] = useState();
  const handleSearch = () => {};

  const [showReportFilters, setShowReportFilters] = useState(false);

  return (
    <div>
      {/* Head */}
      <div className="bg-warmGray dark:bg-gray-800 px-6 py-2 flex items-center justify-between">
        <div className="flex items-center">
          <div className="flex items-center text-white">
            <Link2 className="h-6 w-6" />
            <h4 className="ml-2 mr-3 whitespace-nowrap">Backlink Report</h4>
          </div>
          <SearchBox
            placeholder="Search."
            className="border-white/20 bg-white text-black"
            inputPadding="py-1"
            inputClass="min-w-44"
            name="domain"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
        <div className="flex items-center justify-end gap-2">
          <button className="mr-3 ml-1" onClick={() => getBacklinksReport("")}>
            <RefreshCw
              className={`w-5 cursor-pointer text-white dark:text-white/80 ${
                isLoading && "animate-spin"
              }`}
            />
          </button>
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            handlePageChange={handlePageChange}
          />
          <button
            type="button"
            onClick={() => setShowReportFilters(true)}
            className="btnOutlined py-[6px]"
          >
            <SlidersHorizontal className="h-4 w-4 text-gray-100 dark:text-gray-300" />
            Filters
          </button>
        </div>
      </div>

      {/* Breadcrumbs */}
      <div className="flex items-center justify-between w-full px-6 mt-2">
        <div>
          <Breadcrumbs />
        </div>
        <p className="text-gray-700 dark:text-gray-400 whitespace-nowrap">
          Showing {startItem} to {endItem} of {totalItems} backlinks
        </p>
      </div>

      {showReportFilters && <ReportFilters />}

      {/* Table */}
      <div className="px-6 py-2">
        {isLoading ? (
          <div className="flex justify-center items-center text-center h-64 flex-col gap-3">
            <LoaderCircle className="animate-spin h-12 w-12 text-primary" />
            Loading Report..
          </div>
        ) : backlinks.length === 0 ? (
          <div className="text-center text-gray-600 dark:text-gray-300">
            No backlinks available.
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            {backlinks?.map((item, index) => (
              <div
                key={index}
                className="bg-white dark:bg-gray-800 shadow-sm hover:shadow-md transition-all duration-200 rounded-lg overflow-hidden text-sm"
              >
                {/* URL and Title */}
                <div className="p-3 border-b border-gray-100 dark:border-gray-700">
                  <div className="flex items-center gap-2">
                    <div className="p-1 bg-primary/10 rounded">
                      <Link2 className="h-3.5 w-3.5 text-primary" />
                    </div>
                    <a
                      href={item.backlink_info.url_from}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary hover:text-primary/80 text-sm font-medium truncate"
                    >
                      {item.backlink_info.url_from}
                    </a>
                  </div>
                </div>

                {/* Metrics Grid */}
                <div className="grid grid-cols-4 p-2 gap-1 border-b border-gray-100 dark:border-gray-700">
                  {[
                    {
                      label: "DR",
                      value: item.backlink_info.domain_rating_source,
                    },
                    { label: "DA", value: item.backlink_info.domain_authority },
                    { label: "PA", value: item.backlink_info.page_authority },
                    { label: "Traffic", value: item.backlink_info.traffic },
                  ].map((stat, i) => (
                    <div key={i} className="text-center p-1">
                      <div className="text-base font-semibold text-primary">
                        {stat.value || "-"}
                      </div>
                      <div className="text-xs text-gray-500 dark:text-gray-400">
                        {stat.label}
                      </div>
                    </div>
                  ))}
                </div>

                {/* Details Grid */}
                <div className="grid grid-cols-2 gap-x-2 gap-y-1 p-3 text-xs">
                  {[
                    {
                      label: "Title",
                      value: item.backlink_info.title,
                      span: 2,
                    },
                    { label: "Link Type", value: item.backlink_info.link_type },
                    {
                      label: "Status",
                      value: item.backlink_info.discovered_status,
                    },
                    {
                      label: "First Seen",
                      value: new Date(
                        item.backlink_info.first_seen_link
                      ).toLocaleDateString(),
                    },
                    {
                      label: "Last Seen",
                      value: new Date(
                        item.backlink_info.last_seen_link
                      ).toLocaleDateString(),
                    },
                    { label: "Anchor", value: item.backlink_info.anchor },
                    { label: "Link URL", value: item.backlink_info.url_to },
                    { label: "Language", value: item.backlink_info.language },
                    { label: "Country", value: item.backlink_info.country },
                    { label: "Platform", value: item.backlink_info.platform },
                    {
                      label: "Referring IPs",
                      value: item.backlink_info.referring_ips,
                    },
                    {
                      label: "Referring Subnets",
                      value: item.backlink_info.referring_subnets,
                    },
                    {
                      label: "Referring Pages",
                      value: item.backlink_info.referring_pages,
                    },
                    {
                      label: "External Links",
                      value: item.backlink_info.external_links,
                    },
                    {
                      label: "Internal Links",
                      value: item.backlink_info.internal_links,
                    },
                    {
                      label: "Semantic Location",
                      value: item.backlink_info.semantic_location,
                    },
                  ].map(
                    (detail, i) =>
                      detail.value && (
                        <div
                          key={i}
                          className={`${
                            detail.span === 2 ? "col-span-2" : ""
                          } p-1 hover:bg-gray-50 dark:hover:bg-gray-700/50 rounded`}
                        >
                          <div className="text-gray-500 dark:text-gray-400 mb-0.5">
                            {detail.label}
                          </div>
                          <div className="text-gray-800 dark:text-gray-200 break-words">
                            {detail.value}
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
