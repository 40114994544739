import React, { useState } from "react";
import { LoaderCircle, Sparkles } from "lucide-react";
import { toast } from "react-hot-toast";
import NavigationButtons from "./NavigationButtons";

export default function Step3Content({
  formData,
  setFormData,
  isBlog,
  currentStep,
  totalSteps,
  handleNext,
  handleBack,
  isStepValid,
  setChat,
}) {
  const [isGeneratingCategories, setIsGeneratingCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");

  if (!isBlog) return null;

  const handleAddCategory = () => {
    if (newCategory.trim()) {
      const updatedCategories = [...categories, newCategory.trim()];
      setCategories(updatedCategories);
      setFormData((prev) => ({ ...prev, categories: updatedCategories }));
      setNewCategory("");
    }
  };

  const handleRemoveCategory = (indexToRemove) => {
    const updatedCategories = categories.filter(
      (_, index) => index !== indexToRemove
    );
    setCategories(updatedCategories);
    setFormData((prev) => ({ ...prev, categories: updatedCategories }));
  };

  const handleGenerateCategories = async () => {
    if (!formData.industry) {
      toast.error("Please select an industry first");
      return;
    }

    setIsGeneratingCategories(true);
    try {
      const response = await fetch(
        "https://apipm.ecommcube.com/generate_categories",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer f61c1e040031ca554f74b1d58acff7b8`,
          },
          body: JSON.stringify({
            industry: formData.industry,
            numCategories: 5, // You can make this configurable if needed
          }),
        }
      );

      const data = await response.json();
      if (data.categories) {
        setCategories(data.categories);
        setFormData((prev) => ({ ...prev, categories: data.categories }));
      }
    } catch (error) {
      console.error("Failed to generate categories:", error);
      toast.error("Failed to generate categories");
    } finally {
      setIsGeneratingCategories(false);
    }
  };

  return (
    <div className="space-y-6 mx-auto w-[671px] p-6 bg-white dark:bg-gray-800 rounded-2xl shadow-sm border border-gray-200 dark:border-gray-700">
      {/* Categories Section */}
      <div className="mb-6">
        <div className="flex items-center justify-between mb-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Article Categories
          </label>
        </div>

        {/* Category Input */}
        <div className="flex gap-2 mb-2">
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleAddCategory()}
            className="flex-1 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                     dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
            placeholder={`Add blog cateogries for project ${formData.projectName}`}
          />
          <button
            onClick={handleAddCategory}
            className="px-4 py-2 rounded-lg bg-primary text-white hover:bg-primary/90 transition-colors"
          >
            Add
          </button>
          <button
            onClick={handleGenerateCategories}
            disabled={isGeneratingCategories}
            className="flex items-center gap-2 px-4 py-2 rounded-lg bg-primary/10 text-primary hover:bg-primary/20 transition-colors"
          >
            {isGeneratingCategories ? (
              <LoaderCircle className="w-4 h-4 animate-spin" />
            ) : (
              <Sparkles className="w-4 h-4" />
            )}
            Generate with AI
          </button>
        </div>

        {/* Categories List */}
        <div className="flex flex-wrap gap-2 mt-2">
          {categories.map((category, index) => (
            <div
              key={index}
              className="flex items-center gap-2 px-3 py-1 rounded-full bg-gray-100 dark:bg-gray-700"
            >
              <span className="text-sm">{category}</span>
              <button
                onClick={() => handleRemoveCategory(index)}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                ×
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Number of Articles */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Number of Articles
        </label>
        <input
          type="number"
          value={formData.numArticles}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, numArticles: e.target.value }))
          }
          className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                   dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
          placeholder="Enter number of articles"
        />
      </div>

      {/* Number of Authors */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Number of Authors
        </label>
        <input
          type="number"
          value={formData.numAuthors}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, numAuthors: e.target.value }))
          }
          className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                   dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
          placeholder="Enter number of authors"
        />
      </div>

      {/* Number of Tags */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Number of Tags
        </label>
        <input
          type="number"
          value={formData.numTags}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, numTags: e.target.value }))
          }
          className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                   dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
          placeholder="Enter number of tags"
        />
      </div>

      {/* Word Count */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Words per Article
        </label>
        <input
          type="number"
          value={formData.wordCount}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, wordCount: e.target.value }))
          }
          className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                   dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
          placeholder="Enter words per article"
        />
      </div>

      <NavigationButtons
        currentStep={currentStep}
        totalSteps={totalSteps}
        handleNext={handleNext}
        handleBack={handleBack}
        isStepValid={isStepValid}
        setChat={setChat}
      />
    </div>
  );
}
