import { ChevronDown, ChevronUp, GripHorizontal, X } from "lucide-react";
import React, { useState } from "react";
import NavigationButtons from "./NavigationButtons";

export default function Step2Sections({
  selectedSections,
  handleSectionToggle,
  availableSections,
  bannerResolutions,
  formData,
  setFormData,
  currentStep,
  totalSteps,
  handleNext,
  handleBack,
  isStepValid,
  setChat,
  setSelectedSections,
}) {
  /**
   * Helper constant for previews.
   * You could further refactor these into smaller components if desired.
   */
  const SECTION_PREVIEWS = {
    banner: (
      <div className="relative h-[500px] w-full overflow-hidden">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage:
              'url("https://images.unsplash.com/photo-1604014237800-1c9102c219da?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80")',
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-gray-900/80 to-gray-900/40" />
        </div>
        <div className="relative h-full text-center mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center">
          <h1 className="text-5xl font-bold text-white max-w-3xl">
            Build Beautiful Websites Without Code
          </h1>
          <p className="mt-6 text-xl text-gray-200 max-w-2xl">
            Create stunning, responsive websites with our drag-and-drop builder.
            No coding required.
          </p>
        </div>
      </div>
    ),
    featuredPosts: (
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-10 border-b border-gray-200 dark:border-gray-700 pb-4">
          Featured Posts
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Post 1 */}
          <div className="group">
            <div className="relative h-64 mb-4 rounded-xl overflow-hidden">
              <img
                src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2072&q=80"
                alt="Coding on laptop"
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900/60 to-transparent" />
              <div className="absolute bottom-4 left-4">
                <span className="px-3 py-1 bg-primary/90 text-white text-sm rounded-full">
                  Technology
                </span>
              </div>
            </div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white group-hover:text-primary transition-colors">
              The Future of Web Development: 2024 Trends
            </h3>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400 line-clamp-2">
              Explore the latest trends shaping the future of web development,
              from AI integration to advanced frameworks.
            </p>
          </div>

          {/* Post 2 */}
          <div className="group">
            <div className="relative h-64 mb-4 rounded-xl overflow-hidden">
              <img
                src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
                alt="Team collaboration"
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900/60 to-transparent" />
              <div className="absolute bottom-4 left-4">
                <span className="px-3 py-1 bg-primary/90 text-white text-sm rounded-full">
                  Business
                </span>
              </div>
            </div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white group-hover:text-primary transition-colors">
              Building Successful Development Teams
            </h3>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400 line-clamp-2">
              Learn the key strategies for building and managing high-performing
              development teams in today's competitive landscape.
            </p>
          </div>

          {/* Post 3 */}
          <div className="group">
            <div className="relative h-64 mb-4 rounded-xl overflow-hidden">
              <img
                src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2015&q=80"
                alt="Data analytics"
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900/60 to-transparent" />
              <div className="absolute bottom-4 left-4">
                <span className="px-3 py-1 bg-primary/90 text-white text-sm rounded-full">
                  Analytics
                </span>
              </div>
            </div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white group-hover:text-primary transition-colors">
              Data-Driven Development Decisions
            </h3>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400 line-clamp-2">
              Discover how to leverage data analytics to make better development
              and design decisions for your projects.
            </p>
          </div>
        </div>
      </div>
    ),
    testimonials: (
      <div className="max-w-7xl mx-auto">
        <div className="text-center max-w-2xl mx-auto mb-12">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            What Our Users Say
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            Discover why developers worldwide choose our platform for their
            learning journey
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            {
              name: "Sarah Anderson",
              role: "Senior Frontend Developer",
              company: "TechCorp",
              avatar:
                "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
              content:
                "The quality of content and the structured learning approach has significantly improved my development skills. The community support is exceptional!",
              rating: 5,
            },
            {
              name: "Michael Chen",
              role: "Full Stack Developer",
              company: "InnovateLabs",
              avatar:
                "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
              content:
                "I've tried many platforms, but this one stands out. The practical projects and real-world applications have helped me grow as a developer.",
              rating: 5,
            },
            {
              name: "Emily Rodriguez",
              role: "UI/UX Developer",
              company: "DesignStudio",
              avatar:
                "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1964&q=80",
              content:
                "The focus on modern development practices and cutting-edge technologies keeps me coming back. It's an invaluable resource for staying current.",
              rating: 5,
            },
          ].map((testimonial, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-sm border border-gray-200 dark:border-gray-700"
            >
              {/* Quote Icon */}
              <div className="mb-6">
                <svg
                  className="w-10 h-10 text-primary/20"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
                </svg>
              </div>

              {/* Content */}
              <div className="mb-6">
                <p className="text-gray-600 dark:text-gray-300">
                  {testimonial.content}
                </p>
              </div>

              {/* Rating */}
              <div className="flex mb-6">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <svg
                    key={i}
                    className="w-5 h-5 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
              </div>

              {/* Author */}
              <div className="flex items-center gap-4">
                <img
                  src={testimonial.avatar}
                  alt={testimonial.name}
                  className="w-12 h-12 rounded-full object-cover"
                />
                <div>
                  <h4 className="font-semibold text-gray-900 dark:text-white">
                    {testimonial.name}
                  </h4>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    {testimonial.role} at {testimonial.company}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Trust Indicators */}
        <div className="mt-16 text-center">
          <div className="flex flex-wrap justify-center items-center gap-8">
            <div className="text-center px-8">
              <div className="text-3xl font-bold text-primary mb-2">15k+</div>
              <div className="text-sm text-gray-500 dark:text-gray-400">
                Active Users
              </div>
            </div>
            <div className="text-center px-8">
              <div className="text-3xl font-bold text-primary mb-2">4.9/5</div>
              <div className="text-sm text-gray-500 dark:text-gray-400">
                Average Rating
              </div>
            </div>
            <div className="text-center px-8">
              <div className="text-3xl font-bold text-primary mb-2">98%</div>
              <div className="text-sm text-gray-500 dark:text-gray-400">
                Satisfaction Rate
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    features: (
      <div className="max-w-7xl mx-auto">
        <div className="text-center max-w-2xl mx-auto mb-16">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Powerful Features
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            Everything you need to build and scale your application
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            {
              title: "Real-time Collaboration",
              description:
                "Work together seamlessly with your team in real-time. Share, edit, and manage projects efficiently.",
              icon: "https://cdn-icons-png.flaticon.com/512/1534/1534959.png",
              image:
                "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
            },
            {
              title: "Advanced Analytics",
              description:
                "Gain valuable insights with comprehensive analytics and reporting tools.",
              icon: "https://cdn-icons-png.flaticon.com/512/1283/1283264.png",
              image:
                "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
            },
            {
              title: "Cloud Storage",
              description:
                "Secure cloud storage with automatic backups and easy file management.",
              icon: "https://cdn-icons-png.flaticon.com/512/3305/3305673.png",
              image:
                "https://images.unsplash.com/photo-1544197150-b99a580bb7a8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
            },
            {
              title: "API Integration",
              description:
                "Connect with your favorite tools through our robust API integration system.",
              icon: "https://cdn-icons-png.flaticon.com/512/1935/1935765.png",
              image:
                "https://images.unsplash.com/photo-1558494949-ef010cbdcc31?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2034&q=80",
            },
            {
              title: "Advanced Security",
              description:
                "Enterprise-grade security with end-to-end encryption and compliance features.",
              icon: "https://cdn-icons-png.flaticon.com/512/2092/2092663.png",
              image:
                "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
            },
            {
              title: "24/7 Support",
              description:
                "Round-the-clock support from our dedicated team of experts.",
              icon: "https://cdn-icons-png.flaticon.com/512/1067/1067566.png",
              image:
                "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2072&q=80",
            },
          ].map((feature, index) => (
            <div
              key={index}
              className="group bg-white dark:bg-gray-800 rounded-xl overflow-hidden border border-gray-200 dark:border-gray-700 hover:shadow-lg transition-all duration-300"
            >
              <div className="relative h-48 overflow-hidden">
                <img
                  src={feature.image}
                  alt={feature.title}
                  className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <img
                  src={feature.icon}
                  alt={`${feature.title} icon`}
                  className="absolute bottom-4 left-4 w-12 h-12 p-2 bg-white rounded-xl shadow-lg"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-400">
                  {feature.description}
                </p>
                <div className="mt-4">
                  <a
                    href="#"
                    className="inline-flex items-center text-primary hover:text-primary/80 transition-colors"
                  >
                    Learn more
                    <svg
                      className="w-4 h-4 ml-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ),
    about: (
      <div className="w-full h-32 bg-gray-50 dark:bg-gray-800 rounded-md p-3">
        <div className="flex gap-4 h-full">
          <div className="w-1/3">
            <div className="w-full h-full bg-gray-200 dark:bg-gray-700 rounded" />
          </div>
          <div className="w-2/3 flex flex-col gap-2">
            <div className="w-1/2 h-3 bg-gray-200 dark:bg-gray-700 rounded" />
            <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded" />
            <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded" />
            <div className="w-3/4 h-2 bg-gray-200 dark:bg-gray-700 rounded" />
          </div>
        </div>
      </div>
    ),
    contact: (
      <div className="w-full h-32 bg-gray-50 dark:bg-gray-800 rounded-md p-3">
        <div className="flex flex-col gap-2 h-full">
          <div className="w-full h-8 bg-gray-200 dark:bg-gray-700 rounded" />
          <div className="w-full h-8 bg-gray-200 dark:bg-gray-700 rounded" />
          <div className="w-full h-8 bg-gray-200 dark:bg-gray-700 rounded" />
          <div className="w-24 h-8 bg-primary/20 rounded self-end" />
        </div>
      </div>
    ),
    pricing: (
      <div className="max-w-7xl mx-auto">
        <div className="text-center max-w-2xl mx-auto mb-16">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Choose Your Blog Plan
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            Select the perfect plan for your blogging needs. Scale as you grow.
          </p>
        </div>

        {/* Billing Toggle */}
        <div className="flex justify-center items-center gap-4 mb-12">
          <span className="text-gray-600 dark:text-gray-400">Monthly</span>
          <button className="relative w-14 h-7 bg-primary/20 rounded-full p-1 transition-colors">
            <div className="absolute w-5 h-5 bg-primary rounded-full left-1"></div>
          </button>
          <span className="text-gray-900 dark:text-white font-medium">
            Yearly <span className="text-primary text-sm">(Save 20%)</span>
          </span>
        </div>

        <div className="grid lg:grid-cols-3 gap-8">
          {[
            {
              name: "Hobby Blogger",
              description: "Perfect for personal blogs and beginners",
              price: "9",
              features: [
                {
                  title: "Up to 10 blog posts/month",
                  included: true,
                },
                {
                  title: "Basic SEO tools",
                  included: true,
                },
                {
                  title: "Standard themes",
                  included: true,
                },
                {
                  title: "Community support",
                  included: true,
                },
                {
                  title: "Basic analytics",
                  included: true,
                },
                {
                  title: "Custom domain",
                  included: false,
                },
                {
                  title: "Monetization tools",
                  included: false,
                },
                {
                  title: "Priority support",
                  included: false,
                },
              ],
              highlighted: false,
              cta: "Start Blogging",
            },
            {
              name: "Pro Blogger",
              description: "For serious content creators",
              price: "29",
              features: [
                {
                  title: "Unlimited blog posts",
                  included: true,
                },
                {
                  title: "Advanced SEO suite",
                  included: true,
                },
                {
                  title: "Premium themes",
                  included: true,
                },
                {
                  title: "Priority support",
                  included: true,
                },
                {
                  title: "Advanced analytics",
                  included: true,
                },
                {
                  title: "Custom domain",
                  included: true,
                },
                {
                  title: "Monetization tools",
                  included: true,
                },
                {
                  title: "AI writing assistant",
                  included: false,
                },
              ],
              highlighted: true,
              cta: "Go Pro",
              badge: "Most Popular",
            },
            {
              name: "Business",
              description: "For media companies and teams",
              price: "99",
              features: [
                {
                  title: "Unlimited everything",
                  included: true,
                },
                {
                  title: "Enterprise SEO tools",
                  included: true,
                },
                {
                  title: "Custom themes",
                  included: true,
                },
                {
                  title: "Dedicated support",
                  included: true,
                },
                {
                  title: "Advanced analytics",
                  included: true,
                },
                {
                  title: "Multiple custom domains",
                  included: true,
                },
                {
                  title: "Full monetization suite",
                  included: true,
                },
                {
                  title: "AI writing assistant",
                  included: true,
                },
              ],
              highlighted: false,
              cta: "Contact Sales",
            },
          ].map((plan, index) => (
            <div
              key={index}
              className={`relative rounded-2xl p-8 ${
                plan.highlighted
                  ? "bg-primary text-white scale-105 shadow-xl"
                  : "bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700"
              }`}
            >
              {/* Popular Badge */}
              {plan.badge && (
                <div className="absolute -top-4 left-0 right-0 flex justify-center">
                  <span className="bg-primary/10 text-primary px-3 py-1 rounded-full text-sm font-medium">
                    {plan.badge}
                  </span>
                </div>
              )}

              {/* Plan Header */}
              <div className="text-center mb-8">
                <h3
                  className={`text-xl font-bold mb-2 ${
                    plan.highlighted
                      ? "text-white"
                      : "text-gray-900 dark:text-white"
                  }`}
                >
                  {plan.name}
                </h3>
                <p
                  className={`text-sm mb-6 ${
                    plan.highlighted
                      ? "text-white/80"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                >
                  {plan.description}
                </p>
                <div className="flex items-end justify-center gap-1">
                  <span className="text-sm">$</span>
                  <span
                    className={`text-4xl font-bold ${
                      plan.highlighted
                        ? "text-white"
                        : "text-gray-900 dark:text-white"
                    }`}
                  >
                    {plan.price}
                  </span>
                  <span
                    className={
                      plan.highlighted
                        ? "text-white/80"
                        : "text-gray-500 dark:text-gray-400"
                    }
                  >
                    /month
                  </span>
                </div>
              </div>

              {/* Features List */}
              <div className="space-y-4 mb-8">
                {plan.features.map((feature, i) => (
                  <div key={i} className="flex items-center gap-3">
                    <div
                      className={`flex-shrink-0 w-5 h-5 rounded-full flex items-center justify-center ${
                        feature.included
                          ? plan.highlighted
                            ? "bg-white text-primary"
                            : "bg-primary/10 text-primary"
                          : plan.highlighted
                          ? "bg-white/20"
                          : "bg-gray-100 dark:bg-gray-700"
                      }`}
                    >
                      {feature.included ? (
                        <svg
                          className="w-3 h-3"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="w-3 h-3"
                          fill="none"
                          stroke={plan.highlighted ? "currentColor" : "#999"}
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      )}
                    </div>
                    <span
                      className={`text-sm ${
                        feature.included
                          ? plan.highlighted
                            ? "text-white"
                            : "text-gray-900 dark:text-white"
                          : plan.highlighted
                          ? "text-white/60"
                          : "text-gray-500 dark:text-gray-400"
                      }`}
                    >
                      {feature.title}
                    </span>
                  </div>
                ))}
              </div>

              {/* CTA Button */}
              <button
                className={`w-full py-3 px-6 rounded-lg font-medium transition-colors ${
                  plan.highlighted
                    ? "bg-white text-primary hover:bg-white/90"
                    : "bg-primary/10 text-primary hover:bg-primary/20"
                }`}
              >
                {plan.cta}
              </button>
            </div>
          ))}
        </div>

        {/* Trust Indicators */}
        <div className="mt-16 flex flex-wrap justify-center items-center gap-8">
          <div className="flex items-center gap-2 text-gray-500 dark:text-gray-400">
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
              />
            </svg>
            <span>30-day money-back guarantee</span>
          </div>
          <div className="flex items-center gap-2 text-gray-500 dark:text-gray-400">
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            <span>Instant access</span>
          </div>
          <div className="flex items-center gap-2 text-gray-500 dark:text-gray-400">
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
            <span>24/7 customer support</span>
          </div>
        </div>
      </div>
    ),
    gallery: (
      <div className="w-full h-32 bg-gray-50 dark:bg-gray-800 rounded-md p-3">
        <div className="grid grid-cols-4 gap-2 h-full">
          {[...Array(8)].map((_, i) => (
            <div
              key={i}
              className="w-full h-full bg-gray-200 dark:bg-gray-700 rounded"
            />
          ))}
        </div>
      </div>
    ),
    faq: (
      <div className="max-w-7xl mx-auto">
        <div className="text-center max-w-2xl mx-auto mb-12">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Frequently Asked Questions
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            Find answers to common questions about our platform and services
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-5">
          <div className="space-y-4">
            {[
              {
                question: "How do I get started?",
                answer:
                  "Getting started is easy! Simply sign up for an account, choose your plan, and follow our step-by-step setup guide. Our intuitive interface will help you get up and running in minutes.",
              },
              {
                question: "What payment methods do you accept?",
                answer:
                  "We accept all major credit cards (Visa, MasterCard, American Express), PayPal, and bank transfers. For enterprise customers, we also offer custom payment terms.",
              },
              {
                question: "Can I upgrade or downgrade my plan?",
                answer:
                  "Yes, you can change your plan at any time. When upgrading, you'll be prorated for the remainder of your billing cycle. When downgrading, your new rate will take effect at the next billing cycle.",
              },
              {
                question: "Is there a free trial available?",
                answer:
                  "Yes! We offer a 14-day free trial on all our plans. No credit card is required to start your trial, and you can upgrade or cancel at any time.",
              },
            ].map((faq, index) => (
              <div
                key={index}
                className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-xl overflow-hidden hover:shadow-md transition-shadow group"
              >
                <button
                  className="w-full text-left py-4 px-4"
                  onClick={() => {
                    const el = document.getElementById(`faq-${index}`);
                    const height = el.scrollHeight;
                    if (el.style.height === "0px" || !el.style.height) {
                      el.style.height = `${height}px`;
                    } else {
                      el.style.height = "0px";
                    }
                  }}
                >
                  <div className="flex justify-between items-center gap-4">
                    <h6 className="font-medium text-gray-900 dark:text-white group-hover:text-primary transition-colors">
                      {faq.question}
                    </h6>
                    <svg
                      className="w-5 h-5 text-gray-500 dark:text-gray-400 group-hover:text-primary transition-transform duration-200 transform group-hover:rotate-180"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                  <div
                    id={`faq-${index}`}
                    className="text-gray-600 dark:text-gray-400 overflow-hidden transition-all duration-300 ease-in-out"
                    style={{ height: "0px" }}
                  >
                    <p className="pt-2">{faq.answer}</p>
                  </div>
                </button>
              </div>
            ))}
          </div>

          <div className="space-y-4">
            {[
              {
                question: "Do you offer customer support?",
                answer:
                  "Yes, we provide 24/7 customer support through live chat, email, and phone. Our dedicated support team is always ready to help you with any questions or issues you may have.",
              },
              {
                question: "What about data security?",
                answer:
                  "We take security seriously. Our platform uses industry-standard encryption, regular security audits, and follows best practices for data protection. Your data is backed up daily and stored securely.",
              },
              {
                question: "Can I cancel my subscription?",
                answer:
                  "Yes, you can cancel your subscription at any time from your account settings. There are no long-term contracts or cancellation fees. You'll maintain access until the end of your current billing period.",
              },
              {
                question: "Do you offer custom solutions?",
                answer:
                  "Absolutely! For enterprise customers, we offer custom solutions tailored to your specific needs. Contact our sales team to discuss your requirements and get a personalized quote.",
              },
            ].map((faq, index) => (
              <div
                key={index}
                className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-xl overflow-hidden hover:shadow-md transition-shadow group"
              >
                <button
                  className="w-full text-left py-4 px-4"
                  onClick={() => {
                    const el = document.getElementById(`faq-right-${index}`);
                    const height = el.scrollHeight;
                    if (el.style.height === "0px" || !el.style.height) {
                      el.style.height = `${height}px`;
                    } else {
                      el.style.height = "0px";
                    }
                  }}
                >
                  <div className="flex justify-between items-center gap-4">
                    <h6 className="font-medium text-gray-900 dark:text-white group-hover:text-primary transition-colors">
                      {faq.question}
                    </h6>
                    <svg
                      className="w-5 h-5 text-gray-500 dark:text-gray-400 group-hover:text-primary transition-transform duration-200 transform group-hover:rotate-180"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                  <div
                    id={`faq-right-${index}`}
                    className="text-gray-600 dark:text-gray-400 overflow-hidden transition-all duration-300 ease-in-out"
                    style={{ height: "0px" }}
                  >
                    <p className="pt-2">{faq.answer}</p>
                  </div>
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Contact Support Section */}
        <div className="mt-12 text-center">
          <p className="text-gray-600 dark:text-gray-400 mb-4">
            Still have questions? We're here to help!
          </p>
          <button className="inline-flex items-center gap-2 px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors">
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
              />
            </svg>
            Contact Support
          </button>
        </div>
      </div>
    ),
    newsletter: (
      <div className="max-w-7xl mx-auto bg-gradient-to-br from-primary/5 via-primary/10 to-primary/5 rounded-2xl overflow-hidden">
        <div className="grid md:grid-cols-2 gap-8 p-12">
          {/* Content */}
          <div className="flex flex-col justify-center space-y-6">
            <div className="space-y-4">
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white">
                Stay Updated with Our Newsletter
              </h2>
              <p className="text-gray-600 dark:text-gray-300">
                Get the latest insights, tutorials, and updates delivered
                straight to your inbox. Join our growing community of
                developers.
              </p>
            </div>

            <div className="flex flex-col sm:flex-row gap-2">
              <input
                type="email"
                placeholder="Enter your email"
                className="flex-grow px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
              />
              <button className="px-4 py-2 bg-primary text-white rounded-lg font-medium hover:bg-primary/90 transition-colors">
                Subscribe
              </button>
            </div>

            <div className="flex items-center gap-8 pt-4">
              <div className="flex -space-x-3">
                <img
                  src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1964&q=80"
                  className="w-8 h-8 rounded-full border-2 border-white dark:border-gray-800"
                  alt="Subscriber"
                />
                <img
                  src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80"
                  className="w-8 h-8 rounded-full border-2 border-white dark:border-gray-800"
                  alt="Subscriber"
                />
                <img
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80"
                  className="w-8 h-8 rounded-full border-2 border-white dark:border-gray-800"
                  alt="Subscriber"
                />
              </div>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Join{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  2,000+
                </span>{" "}
                other developers
              </p>
            </div>
          </div>

          {/* Image */}
          <div className="relative hidden md:block">
            <img
              src="https://images.unsplash.com/photo-1579389083078-4e7018379f7e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
              alt="Newsletter"
              className="w-full h-full object-cover rounded-xl"
            />
            <div className="absolute inset-0 bg-gradient-to-br from-primary/20 to-transparent rounded-xl" />

            {/* Feature badges */}
            <div className="absolute top-6 right-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 bg-primary/10 rounded-full flex items-center justify-center">
                  <svg
                    className="w-5 h-5 text-primary"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div>
                  <p className="font-medium text-gray-900 dark:text-white">
                    Weekly Updates
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Stay informed
                  </p>
                </div>
              </div>
            </div>

            <div className="absolute bottom-6 right-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 bg-primary/10 rounded-full flex items-center justify-center">
                  <svg
                    className="w-5 h-5 text-primary"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                </div>
                <div>
                  <p className="font-medium text-gray-900 dark:text-white">
                    Exclusive Content
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Premium resources
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    categoryGrid: (
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl border-b border-gray-200 dark:border-gray-700 pb-4 font-bold text-gray-900 dark:text-white mb-12">
          Popular Categories
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {/* Web Development */}
          <div className="group relative h-64 overflow-hidden rounded-2xl">
            <img
              src="https://images.unsplash.com/photo-1627398242454-45a1465c2479?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2074&q=80"
              alt="Web Development"
              className="absolute inset-0 h-full w-full object-cover group-hover:scale-110 transition-transform duration-300"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/50" />
            <div className="absolute inset-0 flex flex-col justify-end p-6">
              <h3 className="text-xl font-semibold text-white mb-1">
                Web Development
              </h3>
              <p className="text-gray-300 text-sm">148 courses</p>
            </div>
          </div>

          {/* UI/UX Design */}
          <div className="group relative h-64 overflow-hidden rounded-2xl">
            <img
              src="https://images.unsplash.com/photo-1561070791-2526d30994b5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2064&q=80"
              alt="UI/UX Design"
              className="absolute inset-0 h-full w-full object-cover group-hover:scale-110 transition-transform duration-300"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/50" />
            <div className="absolute inset-0 flex flex-col justify-end p-6">
              <h3 className="text-xl font-semibold text-white mb-1">
                UI/UX Design
              </h3>
              <p className="text-gray-300 text-sm">86 courses</p>
            </div>
          </div>

          {/* Mobile Development */}
          <div className="group relative h-64 overflow-hidden rounded-2xl">
            <img
              src="https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
              alt="Mobile Development"
              className="absolute inset-0 h-full w-full object-cover group-hover:scale-110 transition-transform duration-300"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/50" />
            <div className="absolute inset-0 flex flex-col justify-end p-6">
              <h3 className="text-xl font-semibold text-white mb-1">
                Mobile Development
              </h3>
              <p className="text-gray-300 text-sm">94 courses</p>
            </div>
          </div>

          {/* Data Science */}
          <div className="group relative h-64 overflow-hidden rounded-2xl">
            <img
              src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
              alt="Data Science"
              className="absolute inset-0 h-full w-full object-cover group-hover:scale-110 transition-transform duration-300"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/50" />
            <div className="absolute inset-0 flex flex-col justify-end p-6">
              <h3 className="text-xl font-semibold text-white mb-1">
                Data Science
              </h3>
              <p className="text-gray-300 text-sm">72 courses</p>
            </div>
          </div>
        </div>
      </div>
    ),
    popularPosts: (
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-12 border-b border-gray-200 dark:border-gray-700 pb-4">
          Popular Posts
        </h2>
        <div className="grid gap-8">
          {/* Post 1 */}
          <div className="group grid md:grid-cols-3 gap-4 items-center">
            <div className="relative h-64 md:h-full rounded-2xl overflow-hidden">
              <img
                src="https://images.unsplash.com/photo-1487014679447-9f8336841d58?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
                alt="State of Web Development"
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
              />
            </div>
            <div className="md:col-span-2">
              <div className="flex items-center gap-4 mb-4">
                <span className="px-3 py-1 bg-primary/10 text-primary text-sm rounded-full">
                  Technology
                </span>
                <span className="text-sm text-gray-500">5 days ago</span>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white group-hover:text-primary transition-colors">
                The State of Web Development in 2024: A Comprehensive Overview
              </h3>
              <p className="text-sm mt-2 text-gray-400 dark:text-gray-400 line-clamp-2">
                An in-depth analysis of current trends, technologies, and best
                practices shaping the web development landscape in 2024.
              </p>
            </div>
          </div>

          {/* Post 2 */}
          <div className="group grid md:grid-cols-3 gap-4 items-center">
            <div className="relative h-64 md:h-full rounded-2xl overflow-hidden">
              <img
                src="https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
                alt="Team Productivity"
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
              />
            </div>
            <div className="md:col-span-2">
              <div className="flex items-center gap-4 mb-4">
                <span className="px-3 py-1 bg-primary/10 text-primary text-sm rounded-full">
                  Productivity
                </span>
                <span className="text-sm text-gray-500">1 week ago</span>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white group-hover:text-primary transition-colors">
                10 Ways to Boost Your Development Team's Productivity
              </h3>
              <p className="text-sm mt-2 text-gray-400 dark:text-gray-400 line-clamp-2">
                Discover proven strategies and tools that can significantly
                improve your development team's efficiency and output quality.
              </p>
            </div>
          </div>

          {/* Post 3 */}
          <div className="group grid md:grid-cols-3 gap-4 items-center">
            <div className="relative h-64 md:h-full rounded-2xl overflow-hidden">
              <img
                src="https://images.unsplash.com/photo-1573164713988-8665fc963095?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2069&q=80"
                alt="AI Development"
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
              />
            </div>
            <div className="md:col-span-2">
              <div className="flex items-center gap-4 mb-4">
                <span className="px-3 py-1 bg-primary/10 text-primary text-sm rounded-full">
                  AI & ML
                </span>
                <span className="text-sm text-gray-500">2 weeks ago</span>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white group-hover:text-primary transition-colors">
                Integrating AI into Modern Web Applications
              </h3>
              <p className="text-sm mt-2 text-gray-400 dark:text-gray-400 line-clamp-2">
                Learn how to leverage artificial intelligence to create smarter,
                more efficient web applications that deliver better user
                experiences.
              </p>
            </div>
          </div>
        </div>
      </div>
    ),
    authorSpotlight: (
      <div className="max-w-7xl mx-auto bg-white dark:bg-gray-800 rounded-2xl overflow-hidden">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Author Profile */}
          <div className="flex flex-col items-center text-center space-y-4">
            <img
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
              alt="Author"
              className="w-32 h-32 rounded-full object-cover border-4 border-white shadow-lg"
            />
            <div>
              <h3 className="text-xl font-bold text-gray-900 dark:text-white">
                David Mitchell
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Senior Software Engineer
              </p>
            </div>
            <div className="flex gap-4">
              <a
                href="#"
                className="text-gray-400 hover:text-primary transition-colors"
              >
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg"
                  className="w-6 h-6"
                />
              </a>
              <a
                href="#"
                className="text-gray-400 hover:text-primary transition-colors"
              >
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linkedin/linkedin-plain.svg"
                  className="w-6 h-6"
                />
              </a>
              <a
                href="#"
                className="text-gray-400 hover:text-primary transition-colors"
              >
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/twitter/twitter-original.svg"
                  className="w-6 h-6"
                />
              </a>
            </div>
          </div>

          {/* Author Bio & Stats */}
          <div className="md:col-span-2 flex flex-col justify-between">
            <div className="space-y-4">
              <h4 className="text-2xl font-bold text-gray-900 dark:text-white">
                About Me
              </h4>
              <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
                With over a decade of experience in software development, I
                specialize in building scalable web applications and mentoring
                development teams. My passion lies in creating elegant solutions
                to complex problems and sharing knowledge with the developer
                community.
              </p>
              <div className="flex flex-wrap gap-3">
                <span className="px-3 py-1 bg-primary/10 text-primary rounded-full text-sm">
                  React
                </span>
                <span className="px-3 py-1 bg-primary/10 text-primary rounded-full text-sm">
                  Node.js
                </span>
                <span className="px-3 py-1 bg-primary/10 text-primary rounded-full text-sm">
                  TypeScript
                </span>
                <span className="px-3 py-1 bg-primary/10 text-primary rounded-full text-sm">
                  AWS
                </span>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-6 mt-8">
              <div className="text-center">
                <div className="text-3xl font-bold text-primary">150+</div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Articles Published
                </div>
              </div>
              <div className="text-center">
                <div className="text-3xl font-bold text-primary">50K+</div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Monthly Readers
                </div>
              </div>
              <div className="text-center">
                <div className="text-3xl font-bold text-primary">15+</div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Years Experience
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    recentPosts: (
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8 border-b border-gray-200 dark:border-gray-700 pb-4">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
            Recent Posts
          </h2>
          <button className="text-primary hover:text-primary/80 font-medium text-sm transition-colors">
            View All Posts →
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Featured Post */}
          <div className="group space-y-4">
            <div className="relative h-[300px] overflow-hidden rounded-2xl">
              <img
                src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2072&q=80"
                alt="Web Development"
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900/80 to-transparent" />
              <div className="absolute bottom-4 left-4">
                <span className="px-3 py-1 bg-primary/90 text-white text-sm rounded-full">
                  Featured
                </span>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-4 mb-2">
                <div className="flex items-center gap-2">
                  <img
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
                    alt="Author"
                    className="w-6 h-6 rounded-full"
                  />
                  <span className="text-sm text-gray-600 dark:text-gray-400">
                    David Mitchell
                  </span>
                </div>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  5 min read
                </span>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white group-hover:text-primary transition-colors">
                The Future of Web Development: 2024 Trends
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-400 line-clamp-2">
                Explore the latest trends shaping the future of web development,
                from AI integration to advanced frameworks and tools.
              </p>
            </div>
          </div>

          {/* Recent Posts List */}
          <div className="space-y-6">
            {[
              {
                title: "10 Must-Know JavaScript Optimization Tips",
                image:
                  "https://images.unsplash.com/photo-1555066931-4365d14bab8c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
                author: {
                  name: "Sarah Johnson",
                  avatar:
                    "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
                },
                category: "JavaScript",
                readTime: "4 min read",
              },
              {
                title: "Building Scalable React Applications",
                image:
                  "https://images.unsplash.com/photo-1551033406-611cf9a28f67?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
                author: {
                  name: "Michael Chen",
                  avatar:
                    "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
                },
                category: "React",
                readTime: "6 min read",
              },
              {
                title: "Getting Started with TypeScript",
                image:
                  "https://images.unsplash.com/photo-1516116216624-53e697fedbea?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2128&q=80",
                author: {
                  name: "Emma Wilson",
                  avatar:
                    "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1964&q=80",
                },
                category: "TypeScript",
                readTime: "3 min read",
              },
            ].map((post, index) => (
              <div key={index} className="group flex gap-4">
                <div className="w-24 h-24 flex-shrink-0 overflow-hidden rounded-xl">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="flex flex-col justify-between py-1">
                  <div>
                    <h4 className="font-semibold text-gray-900 dark:text-white group-hover:text-primary transition-colors line-clamp-2">
                      {post.title}
                    </h4>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2">
                      <img
                        src={post.author.avatar}
                        alt={post.author.name}
                        className="w-5 h-5 rounded-full"
                      />
                      <span className="text-sm text-gray-600 dark:text-gray-400">
                        {post.author.name}
                      </span>
                    </div>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {post.readTime}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
    searchSection: (
      <div className="max-w-7xl mx-auto">
        {/* Search Header */}
        <div className="text-center max-w-2xl mx-auto mb-8">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Find What You're Looking For
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            Search through our extensive collection of tutorials, articles, and
            resources
          </p>
        </div>

        {/* Search Bar */}
        <div className="relative max-w-3xl mx-auto mb-12">
          <div className="relative">
            <input
              type="text"
              placeholder="Search articles, tutorials, and more..."
              className="w-full pl-12 pr-4 py-4 rounded-xl border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 focus:ring-2 focus:ring-primary/20 shadow-sm"
            />
            <div className="absolute left-4 top-1/2 -translate-y-1/2">
              <svg
                className="w-5 h-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>

          {/* Popular Searches */}
          <div className="mt-4 flex items-center gap-2 flex-wrap">
            <span className="text-sm text-gray-500 dark:text-gray-400">
              Popular:
            </span>
            {["React", "TypeScript", "Node.js", "API Development"].map(
              (term, index) => (
                <button
                  key={index}
                  className="px-3 py-1 text-sm bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 rounded-full hover:bg-primary/10 hover:text-primary transition-colors"
                >
                  {term}
                </button>
              )
            )}
          </div>
        </div>

        {/* Recent Searches */}
        <div className="grid md:grid-cols-2 gap-6 max-w-3xl mx-auto">
          <div>
            <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-3 flex items-center gap-2">
              <svg
                className="w-4 h-4 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Recent Searches
            </h3>
            <div className="space-y-2">
              {[
                "Frontend Development Guide",
                "React Hooks Tutorial",
                "CSS Grid Layout",
              ].map((search, index) => (
                <div
                  key={index}
                  className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400 hover:text-primary cursor-pointer group"
                >
                  <svg
                    className="w-4 h-4 text-gray-400 group-hover:text-primary"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                  {search}
                </div>
              ))}
            </div>
          </div>

          {/* Popular Categories */}
          <div>
            <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-3 flex items-center gap-2">
              <svg
                className="w-4 h-4 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                />
              </svg>
              Popular Categories
            </h3>
            <div className="grid grid-cols-2 gap-2">
              {[
                { name: "Web Development", count: "234" },
                { name: "Mobile Apps", count: "156" },
                { name: "UI/UX Design", count: "98" },
                { name: "DevOps", count: "85" },
              ].map((category, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-2 rounded-lg border border-gray-200 dark:border-gray-700 hover:border-primary/50 cursor-pointer group"
                >
                  <span className="text-sm text-gray-900 dark:text-white group-hover:text-primary">
                    {category.name}
                  </span>
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    {category.count}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    ),
    relatedPosts: (
      <div className="max-w-7xl mx-auto">
        <div className="border-b border-gray-200 dark:border-gray-700 pb-4 mb-8">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
            Related Posts
          </h2>
          <p className="mt-2 text-gray-600 dark:text-gray-400">
            More articles you might like
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            {
              title: "Understanding React Server Components",
              excerpt:
                "Dive deep into React Server Components and learn how they can improve your application's performance.",
              image:
                "https://images.unsplash.com/photo-1633356122544-f134324a6cee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
              category: "React",
              author: {
                name: "David Chen",
                avatar:
                  "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
              },
              readTime: "5 min read",
            },
            {
              title: "Building Modern APIs with GraphQL",
              excerpt:
                "Learn how to create efficient and flexible APIs using GraphQL and best practices for schema design.",
              image:
                "https://images.unsplash.com/photo-1555066931-4365d14bab8c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
              category: "API",
              author: {
                name: "Sarah Johnson",
                avatar:
                  "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
              },
              readTime: "7 min read",
            },
            {
              title: "Advanced TypeScript Patterns",
              excerpt:
                "Explore advanced TypeScript patterns and techniques to write more maintainable code.",
              image:
                "https://images.unsplash.com/photo-1516116216624-53e697fedbea?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2128&q=80",
              category: "TypeScript",
              author: {
                name: "Michael Wilson",
                avatar:
                  "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
              },
              readTime: "6 min read",
            },
          ].map((post, index) => (
            <div key={index} className="group">
              {/* Image */}
              <div className="relative h-48 mb-4 rounded-xl overflow-hidden">
                <img
                  src={post.image}
                  alt={post.title}
                  className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900/60 to-transparent" />
                <div className="absolute bottom-4 left-4">
                  <span className="px-3 py-1 bg-primary/90 text-white text-sm rounded-full">
                    {post.category}
                  </span>
                </div>
              </div>

              {/* Content */}
              <div className="space-y-3">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white group-hover:text-primary transition-colors">
                  {post.title}
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-400 line-clamp-2">
                  {post.excerpt}
                </p>

                {/* Author and Meta */}
                <div className="flex items-center justify-between pt-2">
                  <div className="flex items-center gap-2">
                    <img
                      src={post.author.avatar}
                      alt={post.author.name}
                      className="w-8 h-8 rounded-full"
                    />
                    <span className="text-sm text-gray-900 dark:text-white">
                      {post.author.name}
                    </span>
                  </div>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {post.readTime}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ),
    socialFeed: (
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8 border-b border-gray-200 dark:border-gray-700 pb-4">
          <div>
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
              Social Feed
            </h2>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Latest updates from our community
            </p>
          </div>
          <div className="flex gap-2">
            {["All", "Photos", "Videos", "Events"].map((filter, index) => (
              <button
                key={index}
                className={`px-4 py-2 text-sm rounded-lg transition-colors ${
                  filter === "All"
                    ? "bg-primary text-white"
                    : "text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                }`}
              >
                {filter}
              </button>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[
            {
              type: "image",
              author: {
                name: "Emma Wilson",
                avatar:
                  "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
                role: "UI Designer",
              },
              content:
                "Just wrapped up this new dashboard design! 🎨 What do you think?",
              image:
                "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
              likes: 234,
              comments: 45,
              time: "2h ago",
            },
            {
              type: "event",
              author: {
                name: "David Chen",
                avatar:
                  "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
                role: "Tech Lead",
              },
              content:
                'Join us for our upcoming webinar on "Building Scalable Applications" 🚀',
              eventDate: "March 25, 2024",
              eventTime: "2:00 PM EST",
              attendees: 156,
              time: "4h ago",
            },
            {
              type: "image",
              author: {
                name: "Sarah Johnson",
                avatar:
                  "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1964&q=80",
                role: "Developer",
              },
              content:
                "Working on some exciting new features with the team! 💻",
              image:
                "https://images.unsplash.com/photo-1556761175-b413da4baf72?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2074&q=80",
              likes: 189,
              comments: 32,
              time: "6h ago",
            },
          ].map((post, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden"
            >
              {/* Author Header */}
              <div className="flex items-center gap-3 p-4">
                <img
                  src={post.author.avatar}
                  alt={post.author.name}
                  className="w-10 h-10 rounded-full object-cover"
                />
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white">
                    {post.author.name}
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {post.author.role}
                  </p>
                </div>
                <span className="ml-auto text-sm text-gray-500 dark:text-gray-400">
                  {post.time}
                </span>
              </div>

              {/* Content */}
              <div className="px-4 py-2">
                <p className="text-gray-600 dark:text-gray-300">
                  {post.content}
                </p>
              </div>

              {/* Media */}
              {post.type === "image" && (
                <div className="mt-3">
                  <img
                    src={post.image}
                    alt="Post content"
                    className="w-full h-64 object-cover"
                  />
                </div>
              )}

              {/* Event Details */}
              {post.type === "event" && (
                <div className="px-4 py-3 mt-2 bg-primary/5 border-t border-b border-primary/10">
                  <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-300">
                    <svg
                      className="w-5 h-5 text-primary"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    {post.eventDate} at {post.eventTime}
                  </div>
                  <div className="mt-2 flex items-center gap-2 text-sm text-gray-600 dark:text-gray-300">
                    <svg
                      className="w-5 h-5 text-primary"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                    {post.attendees} attendees
                  </div>
                </div>
              )}

              {/* Interaction Bar */}
              <div className="px-4 py-3 border-t border-gray-200 dark:border-gray-700 flex items-center justify-between">
                <div className="flex items-center gap-6">
                  <button className="flex items-center gap-2 text-gray-600 dark:text-gray-400 hover:text-primary transition-colors">
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                      />
                    </svg>
                    {post.likes}
                  </button>
                  <button className="flex items-center gap-2 text-gray-600 dark:text-gray-400 hover:text-primary transition-colors">
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      />
                    </svg>
                    {post.comments}
                  </button>
                </div>
                <button className="text-gray-600 dark:text-gray-400 hover:text-primary transition-colors">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    ),
    contactForm: (
      <div className="max-w-7xl mx-auto">
        <div className="grid md:grid-cols-2 gap-8 items-center">
          {/* Contact Info */}
          <div className="space-y-8">
            <div>
              <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                Get in Touch
              </h2>
              <p className="text-gray-600 dark:text-gray-400">
                Have questions? We'd love to hear from you. Send us a message
                and we'll respond as soon as possible.
              </p>
            </div>

            {/* Contact Methods */}
            <div className="space-y-6">
              <div className="flex items-center gap-4">
                <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center">
                  <svg
                    className="w-6 h-6 text-primary"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white">
                    Email
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    support@example.com
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center">
                  <svg
                    className="w-6 h-6 text-primary"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                </div>
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white">
                    Phone
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    +1 (555) 123-4567
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center">
                  <svg
                    className="w-6 h-6 text-primary"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </div>
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white">
                    Office
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    123 Innovation Street, Tech City, TC 12345
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Form */}
          <div className="bg-white dark:bg-gray-800 rounded-2xl p-8 shadow-sm border border-gray-200 dark:border-gray-700">
            <form className="space-y-6">
              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-primary/20"
                    placeholder="John"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-primary/20"
                    placeholder="Doe"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Email
                </label>
                <input
                  type="email"
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-primary/20"
                  placeholder="john@example.com"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Subject
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-primary/20"
                  placeholder="How can we help?"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Message
                </label>
                <textarea
                  rows="4"
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-primary/20"
                  placeholder="Your message..."
                ></textarea>
              </div>

              <button
                type="submit"
                className="w-full px-6 py-3 bg-primary text-white rounded-lg font-medium hover:bg-primary/90 transition-colors"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    ),
    subscriptionPlans: (
      <div className="max-w-7xl mx-auto">
        <div className="text-center max-w-2xl mx-auto mb-16">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Choose Your Plan
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            Select the perfect plan for your needs. Upgrade or downgrade at any
            time.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {[
            {
              name: "Starter",
              price: "29",
              description: "Perfect for individuals and small projects",
              features: [
                "Up to 5 projects",
                "2GB storage",
                "Basic analytics",
                "24/7 support",
                "API access",
              ],
              highlighted: false,
            },
            {
              name: "Professional",
              price: "99",
              description: "Ideal for growing teams and businesses",
              features: [
                "Unlimited projects",
                "20GB storage",
                "Advanced analytics",
                "Priority support",
                "API access",
                "Team collaboration",
                "Custom integrations",
              ],
              highlighted: true,
            },
            {
              name: "Enterprise",
              price: "299",
              description: "For large organizations with advanced needs",
              features: [
                "Unlimited everything",
                "100GB storage",
                "Custom analytics",
                "Dedicated support",
                "API access",
                "Advanced security",
                "Custom integrations",
                "SLA guarantee",
              ],
              highlighted: false,
            },
          ].map((plan, index) => (
            <div
              key={index}
              className={`relative rounded-2xl p-4 ${
                plan.highlighted
                  ? "bg-primary text-white shadow-xl scale-105"
                  : "bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700"
              }`}
            >
              {plan.highlighted && (
                <div className="absolute -top-6 left-1/2 -translate-x-1/2">
                  <span className="bg-primary/10 text-primary px-3 py-1 rounded-full text-sm font-medium">
                    Most Popular
                  </span>
                </div>
              )}

              {/* Plan Header */}
              <div className="text-center mb-8">
                <h3
                  className={`text-xl font-bold mb-2 ${
                    plan.highlighted
                      ? "text-white"
                      : "text-gray-900 dark:text-white"
                  }`}
                >
                  {plan.name}
                </h3>
                <p
                  className={`text-sm mb-6 ${
                    plan.highlighted
                      ? "text-white/80"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                >
                  {plan.description}
                </p>
                <div className="flex items-end justify-center gap-1">
                  <span
                    className={`text-4xl font-bold ${
                      plan.highlighted
                        ? "text-white"
                        : "text-gray-900 dark:text-white"
                    }`}
                  >
                    ${plan.price}
                  </span>
                  <span
                    className={
                      plan.highlighted
                        ? "text-white/80"
                        : "text-gray-500 dark:text-gray-400"
                    }
                  >
                    /month
                  </span>
                </div>
              </div>

              {/* Features List */}
              <div className="space-y-4 mb-8">
                {plan.features.map((feature, i) => (
                  <div key={i} className="flex items-center gap-3">
                    <svg
                      className={`w-5 h-5 ${
                        plan.highlighted ? "text-white" : "text-primary"
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span
                      className={
                        plan.highlighted
                          ? "text-white/90"
                          : "text-gray-600 dark:text-gray-300"
                      }
                    >
                      {feature}
                    </span>
                  </div>
                ))}
              </div>

              {/* CTA Button */}
              <button
                className={`w-full py-3 px-6 rounded-lg font-medium transition-colors ${
                  plan.highlighted
                    ? "bg-white text-primary hover:bg-white/90"
                    : "bg-primary/10 text-primary hover:bg-primary/20"
                }`}
              >
                Get Started
              </button>
            </div>
          ))}
        </div>

        {/* Trust Indicators */}
        <div className="mt-16 flex flex-wrap justify-center items-center gap-8 text-gray-500 dark:text-gray-400">
          <div className="flex items-center gap-2">
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
              />
            </svg>
            <span>30-day money-back guarantee</span>
          </div>
          <div className="flex items-center gap-2">
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            <span>Instant access</span>
          </div>
          <div className="flex items-center gap-2">
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
            <span>24/7 customer support</span>
          </div>
        </div>
      </div>
    ),
    readingList: (
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
              Reading List
            </h2>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Your saved articles and resources
            </p>
          </div>
          <div className="flex gap-2">
            <button className="px-4 py-2 text-sm rounded-lg bg-primary/10 text-primary hover:bg-primary/20 transition-colors">
              All Topics
            </button>
            <button className="p-2 text-gray-600 dark:text-gray-400 hover:text-primary transition-colors">
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="space-y-4">
          {[
            {
              title: "Advanced TypeScript Patterns for Robust Applications",
              excerpt:
                "Learn how to leverage TypeScript's type system to build more maintainable applications.",
              image:
                "https://images.unsplash.com/photo-1516116216624-53e697fedbea?ixlib=rb-4.0.3",
              category: "TypeScript",
              readTime: "8 min read",
              saved: "2 days ago",
              progress: 25,
            },
            {
              title: "Building Scalable APIs with GraphQL",
              excerpt:
                "A comprehensive guide to designing and implementing GraphQL APIs for modern applications.",
              image:
                "https://images.unsplash.com/photo-1555066931-4365d14bab8c?ixlib=rb-4.0.3",
              category: "API Development",
              readTime: "12 min read",
              saved: "1 week ago",
              progress: 60,
            },
            {
              title: "React Performance Optimization Techniques",
              excerpt:
                "Practical strategies to improve your React application's performance and user experience.",
              image:
                "https://images.unsplash.com/photo-1633356122544-f134324a6cee?ixlib=rb-4.0.3",
              category: "React",
              readTime: "10 min read",
              saved: "2 weeks ago",
              progress: 80,
            },
          ].map((article, index) => (
            <div
              key={index}
              className="group bg-white dark:bg-gray-800 rounded-xl border border-gray-200 dark:border-gray-700 p-4 hover:shadow-md transition-shadow"
            >
              <div className="flex gap-6">
                {/* Image */}
                <div className="w-48 h-32 flex-shrink-0">
                  <img
                    src={article.image}
                    alt={article.title}
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>

                {/* Content */}
                <div className="flex-grow flex flex-col">
                  <div className="flex items-center gap-3 mb-2">
                    <span className="px-3 py-1 bg-primary/10 text-primary text-sm rounded-full">
                      {article.category}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {article.readTime}
                    </span>
                  </div>

                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2 group-hover:text-primary transition-colors">
                    {article.title}
                  </h3>

                  <p className="text-gray-600 dark:text-gray-400 text-sm line-clamp-2 mb-4">
                    {article.excerpt}
                  </p>

                  <div className="mt-auto flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        Saved {article.saved}
                      </span>
                      <div className="flex items-center gap-2">
                        <div className="w-20 h-1.5 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                          <div
                            className="h-full bg-primary rounded-full"
                            style={{ width: `${article.progress}%` }}
                          />
                        </div>
                        <span className="text-sm text-gray-500 dark:text-gray-400">
                          {article.progress}%
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-2">
                      <button className="p-2 text-gray-600 dark:text-gray-400 hover:text-primary transition-colors">
                        <svg
                          className="w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                          />
                        </svg>
                      </button>
                      <button className="p-2 text-gray-600 dark:text-gray-400 hover:text-primary transition-colors">
                        <svg
                          className="w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Load More */}
        <div className="mt-8 text-center">
          <button className="px-6 py-3 text-sm text-primary hover:text-primary/80 font-medium transition-colors">
            Load More Articles
          </button>
        </div>
      </div>
    ),
    commentSection: (
      <div className="max-w-7xl mx-auto">
        {/* Comment Header */}
        <div className="flex items-center justify-between mb-8">
          <div>
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
              Discussion
            </h2>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Join the conversation with our community
            </p>
          </div>
          <div className="flex gap-2">
            <button className="px-4 py-2 text-sm rounded-lg bg-primary/10 text-primary hover:bg-primary/20 transition-colors">
              Most Recent
            </button>
            <button className="p-2 text-gray-600 dark:text-gray-400 hover:text-primary transition-colors">
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Comment Input */}
        <div className="mb-8 bg-white dark:bg-gray-800 rounded-xl p-4 border border-gray-200 dark:border-gray-700">
          <div className="flex gap-4">
            <img
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3"
              alt="Your avatar"
              className="w-10 h-10 rounded-full object-cover"
            />
            <div className="flex-grow">
              <textarea
                rows="3"
                className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-primary/20 resize-none"
                placeholder="Add to the discussion..."
              ></textarea>
              <div className="mt-3 flex justify-end">
                <button className="px-4 py-2 bg-primary text-white rounded-lg text-sm font-medium hover:bg-primary/90 transition-colors">
                  Comment
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Comments List */}
        <div className="space-y-6">
          {[
            {
              author: {
                name: "David Chen",
                avatar:
                  "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3",
                role: "Developer",
              },
              content:
                "This is a great article! I especially liked the section about performance optimization. Would love to see more content like this.",
              time: "2 hours ago",
              likes: 24,
              replies: [
                {
                  author: {
                    name: "Sarah Johnson",
                    avatar:
                      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3",
                    role: "Designer",
                  },
                  content:
                    "Agreed! The performance tips were really helpful. I've already started implementing some of them in my current project.",
                  time: "1 hour ago",
                  likes: 12,
                },
              ],
            },
            {
              author: {
                name: "Michael Wilson",
                avatar:
                  "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-4.0.3",
                role: "Tech Lead",
              },
              content:
                "Great insights! One thing I'd add is the importance of proper error handling when dealing with these patterns. It can save a lot of debugging time later.",
              time: "3 hours ago",
              likes: 18,
              replies: [],
            },
          ].map((comment, index) => (
            <div key={index} className="space-y-6">
              {/* Main Comment */}
              <div className="bg-white dark:bg-gray-800 rounded-xl p-4 border border-gray-200 dark:border-gray-700">
                <div className="flex gap-4">
                  <img
                    src={comment.author.avatar}
                    alt={comment.author.name}
                    className="w-10 h-10 rounded-full object-cover"
                  />
                  <div className="flex-grow">
                    <div className="flex items-center gap-2 mb-1">
                      <h5 className="font-medium text-gray-900 dark:text-white">
                        {comment.author.name}
                      </h5>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {comment.author.role}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        • {comment.time}
                      </span>
                    </div>
                    <p className="text-gray-600 dark:text-gray-300 mb-3">
                      {comment.content}
                    </p>
                    <div className="flex items-center gap-4">
                      <button className="flex items-center gap-1 text-gray-500 dark:text-gray-400 hover:text-primary transition-colors">
                        <svg
                          className="w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                          />
                        </svg>
                        <span>{comment.likes}</span>
                      </button>
                      <button className="text-gray-500 dark:text-gray-400 hover:text-primary transition-colors">
                        Reply
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Replies */}
              {comment.replies.map((reply, replyIndex) => (
                <div key={replyIndex} className="ml-12">
                  <div className="bg-white dark:bg-gray-800 rounded-xl p-4 border border-gray-200 dark:border-gray-700">
                    <div className="flex gap-4">
                      <img
                        src={reply.author.avatar}
                        alt={reply.author.name}
                        className="w-10 h-10 rounded-full object-cover"
                      />
                      <div className="flex-grow">
                        <div className="flex items-center gap-2 mb-1">
                          <h4 className="font-medium text-gray-900 dark:text-white">
                            {reply.author.name}
                          </h4>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {reply.author.role}
                          </span>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            • {reply.time}
                          </span>
                        </div>
                        <p className="text-gray-600 dark:text-gray-300 mb-3">
                          {reply.content}
                        </p>
                        <div className="flex items-center gap-4">
                          <button className="flex items-center gap-1 text-gray-500 dark:text-gray-400 hover:text-primary transition-colors">
                            <svg
                              className="w-5 h-5"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                              />
                            </svg>
                            <span>{reply.likes}</span>
                          </button>
                          <button className="text-gray-500 dark:text-gray-400 hover:text-primary transition-colors">
                            Reply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* Load More */}
        <div className="mt-8 text-center">
          <button className="px-6 py-3 text-sm text-primary hover:text-primary/80 font-medium transition-colors">
            Load More Comments
          </button>
        </div>
      </div>
    ),
    navbar: (
      <nav className="bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              {/* Logo */}
              <div className="flex-shrink-0 flex items-center">
                <div className="h-8 w-8 bg-primary rounded-lg"></div>
                <span className="ml-2 text-xl font-bold text-gray-900 dark:text-white">
                  {formData.projectName}
                </span>
              </div>
              {/* Main Navigation */}
              <div className="hidden sm:ml-6 sm:flex sm:space-x-2">
                <a className="border-primary text-gray-900 dark:text-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                  Home
                </a>
                <a className="border-transparent text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                  Features
                </a>
                <a className="border-transparent text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                  Pricing
                </a>
                <a className="border-transparent text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                  About
                </a>
              </div>
            </div>
            {/* Right side buttons */}
            <div className="hidden sm:ml-6 sm:flex sm:items-center space-x-3">
              <button className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg transition-colors">
                Sign in
              </button>
              <button className="px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary/90 rounded-lg transition-colors">
                Get Started
              </button>
            </div>
            {/* Mobile menu button */}
            <div className="flex items-center sm:hidden">
              <button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700">
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </nav>
    ),
    footer: (
      <footer className="bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {/* Company Info */}
            <div className="col-span-2 md:col-span-1">
              <div className="flex items-center">
                <div className="h-8 w-8 bg-primary rounded-lg"></div>
                <span className="ml-2 text-xl font-bold text-gray-900 dark:text-white">
                  {formData.projectName}
                </span>
              </div>
              <p className="mt-4 text-sm text-gray-600 dark:text-gray-400">
                Making the world a better place through innovative solutions.
              </p>
              <div className="mt-4 flex space-x-4">
                {/* Social Media Icons */}
                {["twitter", "facebook", "instagram", "linkedin"].map(
                  (social) => (
                    <a
                      key={social}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <span className="sr-only">{social}</span>
                      <div className="h-6 w-6 bg-gray-400/20 rounded-full"></div>
                    </a>
                  )
                )}
              </div>
            </div>

            {/* Quick Links */}
            {["Product", "Company", "Resources"].map((category) => (
              <div key={category}>
                <h3 className="text-sm font-semibold text-gray-900 dark:text-white tracking-wider uppercase">
                  {category}
                </h3>
                <ul className="mt-4 space-y-4">
                  {[1, 2, 3, 4].map((item) => (
                    <li key={item}>
                      <a className="text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white">
                        Link {item}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Bottom section */}
          <div className="mt-8 pt-8 border-t border-gray-200 dark:border-gray-700">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <p className="text-sm text-gray-400">
                © 2024 Your Company, Inc. All rights reserved.
              </p>
              <div className="mt-4 md:mt-0 flex space-x-6">
                <a className="text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white">
                  Privacy Policy
                </a>
                <a className="text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white">
                  Terms of Service
                </a>
                <a className="text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white">
                  Cookie Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    ),
    howItWorks: (
      <div className="max-w-7xl mx-auto">
        <div className="text-center max-w-2xl mx-auto mb-16">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            How It Works
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            Get started with our platform in just a few simple steps
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-6 relative">
          {/* Connecting Line */}
          <div className="hidden md:block absolute top-32 left-0 w-full h-0.5 bg-gray-100 dark:bg-gray-800">
            <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-4/5 h-full bg-gradient-to-r from-primary/20 via-primary to-primary/20"></div>
          </div>

          {[
            {
              step: 1,
              title: "Create Your Project",
              description:
                "Start by setting up your project with our intuitive project creation wizard.",
              image:
                "https://images.unsplash.com/photo-1507238691740-187a5b1d37b8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2055&q=80",
              icon: (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
              ),
            },
            {
              step: 2,
              title: "Customize & Configure",
              description:
                "Personalize your workspace and configure settings to match your needs.",
              image:
                "https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2015&q=80",
              icon: (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                  />
                </svg>
              ),
            },
            {
              step: 3,
              title: "Launch & Scale",
              description:
                "Deploy your project and scale it as your needs grow.",
              image:
                "https://images.unsplash.com/photo-1441986300917-64674bd600d8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
              icon: (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              ),
            },
          ].map((item, index) => (
            <div key={index} className="relative">
              {/* Step Card */}
              <div className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-sm border border-gray-200 dark:border-gray-700 transition-all duration-300 hover:shadow-lg group">
                {/* Image Section */}
                <div className="relative h-48 overflow-hidden">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent" />

                  {/* Step Number */}
                  <div className="absolute top-4 right-4 w-8 h-8 rounded-full bg-white dark:bg-gray-800 flex items-center justify-center text-primary font-bold shadow-lg">
                    {item.step}
                  </div>
                </div>

                {/* Content Section */}
                <div className="p-6">
                  <div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center text-primary mb-4">
                    {item.icon}
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                    {item.title}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    {item.description}
                  </p>
                </div>
              </div>

              {/* Connector Line for Desktop */}
              {index < 2 && (
                <div className="hidden md:block absolute top-32 right-0 w-1/2 h-0.5">
                  <div className="w-full h-full bg-gradient-to-r from-primary to-primary/20"></div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Additional Features */}
        <div className="mt-16 grid md:grid-cols-2 lg:grid-cols-4 gap-5">
          {[
            {
              title: "24/7 Support",
              description: "Get help whenever you need it",
              icon: (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              ),
            },
            {
              title: "Regular Updates",
              description: "Always stay up to date",
              icon: (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              ),
            },
            {
              title: "Secure Platform",
              description: "Enterprise-grade security",
              icon: (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
              ),
            },
            {
              title: "Analytics",
              description: "Track your progress",
              icon: (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                  />
                </svg>
              ),
            },
          ].map((feature, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 p-6 rounded-xl border border-gray-200 dark:border-gray-700 hover:shadow-md transition-all duration-300"
            >
              <div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center text-primary mb-4">
                {feature.icon}
              </div>
              <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                {feature.title}
              </h4>
              <p className="text-gray-600 dark:text-gray-400">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    ),
  };

  // Add this function near the top of the Step2Sections component
  const moveSection = (sectionId, direction) => {
    // Get array of selected section IDs in current order
    const selectedSectionIds = Object.entries(selectedSections)
      .filter(([, isSelected]) => isSelected)
      .map(([id]) => id);

    const currentIndex = selectedSectionIds.indexOf(sectionId);

    const newIndex =
      direction === "up"
        ? Math.max(0, currentIndex - 1)
        : Math.min(selectedSectionIds.length - 1, currentIndex + 1);

    if (currentIndex === newIndex) return;

    const reorderedSections = {};

    selectedSectionIds.splice(currentIndex, 1);
    selectedSectionIds.splice(newIndex, 0, sectionId);

    // Rebuild sections object in new order
    selectedSectionIds.forEach((id) => {
      reorderedSections[id] = true;
    });

    // Add back unselected sections
    Object.entries(selectedSections).forEach(([id, isSelected]) => {
      if (!isSelected) {
        reorderedSections[id] = false;
      }
    });

    setSelectedSections(reorderedSections);
  };

  return (
    <div className="grid grid-cols-12 gap-8 mx-w-[1600px] mx-auto">
      {/* Available Sections */}
      <div className="col-span-12 lg:col-span-5 xl:col-span-4 sticky top-0">
        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-sm border border-gray-200 dark:border-gray-700 sticky top-16">
          <div className="p-5 border-b border-gray-200 dark:border-gray-700">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Click to add sections to your page
            </h3>
          </div>
          <div className="p-5 grid grid-cols-2 gap-4">
            {availableSections.map((section) => {
              const isSelected = !!selectedSections[section.id];
              return (
                <div
                  key={section.id}
                  onClick={() => handleSectionToggle(section.id)}
                  className={`
                        group cursor-pointer rounded-lg border-2 transition-all duration-200
                        ${
                          isSelected
                            ? "border-primary bg-primary/5 shadow-md"
                            : "border-gray-200 dark:border-gray-700 hover:border-primary/30 hover:shadow-md"
                        }
                      `}
                >
                  <div className="p-3">
                    <div className="flex items-center justify-between">
                      <p className="font-medium text-gray-900 dark:text-white text-sm">
                        {section.name}
                      </p>
                      <div
                        className={`
                            w-5 h-5 rounded-md border-2 transition-all duration-200
                            ${
                              isSelected
                                ? "border-primary bg-primary"
                                : "border-gray-300 dark:border-gray-600 group-hover:border-primary/30"
                            }
                          `}
                      >
                        {isSelected && (
                          <svg
                            className="w-full h-full text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                    {/* <p className="text-sm text-gray-600 dark:text-gray-400">
                        {section.description}
                      </p> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Live Preview */}
      <div className="col-span-12 lg:col-span-7 xl:col-span-8">
        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-sm border border-gray-200 dark:border-gray-700">
          <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Design Your Landing Page
                </h3>
                <p className="text-gray-600 dark:text-gray-400 text-sm">
                  Choose and arrange sections to create your perfect landing
                  page
                </p>
                {/* <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  {Object.values(selectedSections).filter(Boolean).length}{" "}
                  sections selected
                </p> */}
              </div>
              {selectedSections.banner && (
                <div className="w-64">
                  <select
                    value={formData.bannerResolution}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        bannerResolution: e.target.value,
                      }))
                    }
                    className="w-full px-3 py-2 text-sm rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
                  >
                    <option value="">Select banner resolution</option>
                    {bannerResolutions.map((resolution, index) => (
                      <option
                        key={index}
                        value={resolution.value}
                        disabled={resolution.disabled}
                      >
                        {resolution.label}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>

          {/* Sections Container */}
          <div className="relative bg-gray-50 dark:bg-gray-900 rounded-b-2xl min-h-[600px] p-8">
            <div className="bg-white dark:bg-gray-800 shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden">
              {Object.entries(selectedSections)
                .filter(([, isSelected]) => isSelected)
                .map(([sectionId], index) => (
                  <div
                    key={sectionId}
                    className="sectionContainer relative before:absolute before:inset-0 before:border-2 before:border-transparent before:transition-colors hover:before:border-primary/80 before:pointer-events-none before:z-10"
                  >
                    <div className="hidden sectionContainerBorder absolute mx-auto items-center left-0 right-0 justify-center bottom-0 z-20">
                      <div className="bg-primary rounded-t-lg p-1 px-1 flex items-center gap-1 text-white">
                        <span
                          onClick={() => moveSection(sectionId, "down")}
                          className="p-1 rounded-md cursor-pointer hover:bg-white/30 transition-colors"
                        >
                          <ChevronDown className="w-4 h-4" />
                        </span>
                        <span
                          onClick={() => moveSection(sectionId, "up")}
                          className="p-1 rounded-md cursor-pointer hover:bg-white/30 transition-colors"
                        >
                          <ChevronUp className="w-4 h-4" />
                        </span>
                        <span className="p-1 rounded-md cursor-pointer hover:bg-white/30 transition-colors">
                          <GripHorizontal className="w-4 h-4" />
                        </span>
                        <span className="p-1 rounded-md cursor-pointer hover:bg-white/30 transition-colors">
                          <X
                            onClick={() => handleSectionToggle(sectionId)}
                            className="w-4 h-4"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="relative transform origin-top px-10 pt-12 pb-16">
                      <div className="max-w-7xl mx-auto">
                        {SECTION_PREVIEWS[sectionId]}
                      </div>
                    </div>
                    {/* Add separator between sections except for the last one */}
                    {index !==
                      Object.values(selectedSections).filter(Boolean).length -
                        1 && <div className="" />}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-sm border border-gray-200 dark:border-gray-700 p-2.5 mt-2">
          <NavigationButtons
            currentStep={currentStep}
            totalSteps={totalSteps}
            handleNext={handleNext}
            handleBack={handleBack}
            isStepValid={isStepValid}
            setChat={setChat}
          />
        </div>
      </div>
    </div>
  );
}
