import { BadgeCheck, LoaderCircle, Upload } from "lucide-react";
import Status from "../../../components/common/Status";

function ProjectDetails({
  project,
  handleConnect,
  hasPermission,
  deployProject,
  isDeploying,
}) {
  return (
    <>
      <p className="capitalize">
        <span className="font-semibold text-sm text-gray-500 dark:text-gray-400">
          Project Name:
        </span>{" "}
        <span className="text-md font-semibold">{project.project_name}</span>
      </p>
      {project?.domain_id?.domain ? (
        <p>
          <b className="text-gray-500 dark:text-gray-400">Domain:</b>{" "}
          <a
            href={`https://${project?.domain_id?.domain}`}
            target="_blank"
            rel="noreferrer"
            className="underline hover:text-primary transition-all cursor-pointer"
          >
            {project?.domain_id?.domain}
          </a>
        </p>
      ) : hasPermission("connect domain") ? (
        <button
          className="bg-primary text-white my-1 rounded-full py-1 px-3 w-fit"
          onClick={() => handleConnect(project)}
        >
          Connect Domain
        </button>
      ) : (
        ""
      )}
      <p>
        <b className="text-gray-500 dark:text-gray-400">Staging:</b>{" "}
        <a
          href={`https://${project?.project_name?.replaceAll(
            " ",
            "-"
          )}.sitebuilderz.com`}
          target="_blank"
          rel="noreferrer"
          className="underline hover:text-primary transition-all cursor-pointer lowercase"
        >
          {project?.slug}.sitebuilderz.com
        </a>
      </p>
      {project?.domain_id?._id && (
        <LiveDomainStatus
          project={project}
          hasPermission={hasPermission}
          deployProject={deployProject}
          isDeploying={isDeploying}
        />
      )}
      <div className="flex items-center mt-1 gap-2">
        {/* <b>Category:</b>{" "} */}
        <span className="text-black dark:text-white rounded-full px-2 py-0.5 text-xs font-medium bg-primary/10 dark:bg-gray-800">
          {project.industry_id.industry_name}
        </span>
        <span className="text-black dark:text-white rounded-full px-2 py-0.5 text-xs font-medium bg-primary/10 dark:bg-gray-800">
          {project.industry_template_id.template_name}
        </span>

        <p className="text-gray-500 dark:text-gray-400 text-sm">
          <b>Niche:</b> {project?.domain_id?.niche_id?.name}
        </p>
      </div>
    </>
  );
}

function LiveDomainStatus({
  project,
  hasPermission,
  deployProject,
  isDeploying,
}) {
  return (
    <div>
      {project.is_deployed ? (
        <span className="flex items-center gap-2">
          <b className="text-gray-500 dark:text-gray-400">
            Live Domain Status:
          </b>
          <span className="w-fit rounded-full flex items-center gap-2 font-medium py-1 px-4 text-sm bg-green-100 text-green-600">
            Live <BadgeCheck className="w-4 h-4" />
          </span>
        </span>
      ) : project?.domain_id?.cloudflair_details?.status === "pending" ? (
        <p className="text-gray-500 dark:text-gray-400 flex items-center gap-1">
          <b>Live Domain Status:</b>{" "}
          <Status
            className="py-1 px-3 w-fit text-sm"
            type={project?.domain_id?.cloudflair_details?.status}
          />
        </p>
      ) : (
        hasPermission("deploy project") && (
          <button
            onClick={deployProject}
            className={`btnPrimary rounded-full px-3 py-1 hover:bg-warmGray hover:text-white ${
              isDeploying && "bg-warmGray"
            }`}
          >
            {isDeploying ? (
              <LoaderCircle className="w-4 animate-spin text-primary" />
            ) : (
              <Upload className="w-4" />
            )}
            {isDeploying ? "Deploying" : "Deploy Project"}
          </button>
        )
      )}
    </div>
  );
}

export default ProjectDetails;
