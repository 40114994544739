import React, { useEffect, useRef, useState } from "react";
import {
  X,
  Info,
  Plus,
  Edit,
  Save,
  Trash,
  FileWarning,
  LoaderCircle,
} from "lucide-react";

import dayjs from "dayjs";
import Dropdown from "./Dropdown";
import toast, { Toaster } from "react-hot-toast";
import useApi from "../../../../../utils/useApi";
import { useArticleContext } from "../ArticleContext";
import usePermissions from "../../../../../utils/userPermission";
import { CheckBox, InputField } from "../../../../../components";
import CircularProgressBar from "../../../../../components/common/CircularProgress";

export default function ArticleCard({
  blog,
  index,
  select,
  isSelected,
  onSingleSelect,
  deleteConfirmation,
  setDeleteConfirmation,
}) {
  const {
    grid,
    blogsList,
    deleteBlog,
    project_id,
    categories,
    deleteFromList,
    deletingArticle,
    handleUpdateBlog,
    setBlogsList,
  } = useArticleContext();

  const { request } = useApi();

  const handleApiUpdate = async (updatedBlogsList) => {
    const formData = new FormData();
    formData.append("key", "blog_list");
    formData.append("value_type", "JSON");
    formData.append("value", JSON.stringify(updatedBlogsList));

    await request({
      method: "post",
      url: `projects/${project_id}/data`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("updatedBlogsList", updatedBlogsList);

    setBlogsList(updatedBlogsList);
  };

  const tagsModalRef = useRef(null);

  const [input, setInput] = useState("");
  const [action, setAction] = useState(false);
  const [tagsModal, setTagsModal] = useState(false);
  const [blogTags, setBlogTags] = useState(blog?.tags || []);

  const addTag = (e) => {
    if ((e.key === "Enter" || e.type === "click") && input.trim() !== "") {
      setBlogTags([...blogTags, input.trim()]);
      setInput("");
    }
  };

  const removeTag = (index) => {
    setBlogTags(blogTags.filter((_, i) => i !== index));
  };

  const handleSaveTags = async () => {
    setAction(true);
    const savePromise = (async () => {
      const updatedBlog = { ...blog, tags: blogTags };
      const updatedBlogsList = blogsList?.map((blogItem) =>
        blogItem._id === updatedBlog._id ? updatedBlog : blogItem
      );

      const allTags = updatedBlogsList.reduce((acc, article) => {
        const tags = Array.isArray(article?.tags) ? article.tags : [];
        return [...new Set([...acc, ...tags])];
      }, []);

      const tagArticleMapping = allTags.map((tag) => ({
        tag,
        article_ids: updatedBlogsList
          .filter((article) => article.tags?.includes(tag))
          .map((article) => article.key),
      }));

      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: {
          key: "tag_list",
          value_type: "JSON",
          value: JSON.stringify(tagArticleMapping),
        },
        headers: { "Content-Type": "multipart/form-data" },
      });

      await handleApiUpdate(updatedBlogsList);
    })();

    toast.promise(savePromise, {
      loading: "Saving tags...",
      success: "Tags updated successfully",
      error: "Failed to update tags",
    });

    try {
      await savePromise;
      setTagsModal(false);
    } catch (error) {
      console.error("Tag update error:", error);
    } finally {
      setAction(false);
      setDeleteConfirmation(false);
    }
  };

  const { hasPermission } = usePermissions();

  const handleClickOutside = (event) => {
    if (tagsModalRef.current && !tagsModalRef.current.contains(event.target)) {
      setTagsModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [article_category, setArticleCategory] = useState(
    blog?.article_category
  );

  // Handle category update
  const handleUpdateCat = async (categoryTitle) => {
    setAction(true);
    const updatePromise = (async () => {
      const updatedBlog = { ...blog, article_category: categoryTitle };
      const updatedBlogsList = blogsList?.map((blogItem) =>
        blogItem._id === updatedBlog._id ? updatedBlog : blogItem
      );

      await handleApiUpdate(updatedBlogsList);
    })();

    toast.promise(updatePromise, {
      loading: "Updating category...",
      success: "Category updated successfully",
      error: "Failed to update category",
    });

    try {
      await updatePromise;
    } finally {
      setAction(false);
    }
  };

  return (
    <div className="flex gap-2 items-start w-full">
      <Toaster />

      {select && (
        <CheckBox
          checked={isSelected(blog?._id)}
          className="h-6 w-6"
          boxSize="h-7 w-7"
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: blog,
            })
          }
        />
      )}
      <div
        className={`bg-white flex-1 dark:bg-gray-800 shadow-lg rounded  ${
          grid ? "flex flex-col" : "grid grid-cols-articleCard"
        }`}
      >
        <div
          className={`overflow-hidden cursor-pointer relative flex items-end rounded ${
            grid ? "h-40" : "h-full"
          }`}
          onClick={
            hasPermission("Edit Project Article")
              ? () => handleUpdateBlog(blog)
              : null
          }
        >
          <img
            src={`${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${blog?.image}`}
            alt="no article coverphoto"
            className="object-fill absolute m-auto min-w-full min-h-full hover:scale-110 transition-all"
          />
          <CircularProgressBar
            percentage={blog?.percentage}
            className="text-sm"
            showLabel
          />
        </div>
        <div className="py-2 px-3 flex flex-col justify-between flex-1">
          <div>
            <div className="flex items-center flex-wrap justify-between gap-2">
              <p className="capitalize font-semibold leading-tight mt-1">
                {blog?.title}
              </p>
            </div>
            <p className="text-xs text-gray-400 dark:text-white/80 mt-1">
              {grid
                ? blog?.articleContent?.slice(0, 110)
                : blog?.articleContent}
              ...
            </p>
          </div>

          {/* Category Select Dropdown */}
          <div className="flex items-center justify-between mt-3">
            <div className="flex flex-wrap items-center gap-1">
              <p className="font-medium text-sm text-gray-500 dark:text-white/80">
                Category:
              </p>
              <select
                disabled={action}
                className="py-1 px-2 disabled:cursor-wait border-gray-200 dark:bg-transparent dark:border-white/20 rounded-md border text-sm w-44"
                value={article_category || ""}
                onChange={(e) => {
                  const selectedCategory = e.target.value;
                  setArticleCategory(selectedCategory);
                  handleUpdateCat(selectedCategory);
                }}
              >
                <option value="" disabled>
                  Select a category
                </option>
                {categories?.map((category) => (
                  <option key={category.title} value={category.title}>
                    {category.title}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex items-center flex-wrap justify-end gap-3">
              <p className="dark:text-white/80 text-xs text-gray-500">
                {blog?.author} -{" "}
                {dayjs(blog?.published_at)?.format("MMM D, YYYY")}
              </p>
              <div className="flex items-center gap-2">
                {hasPermission("Delete Project Article") && (
                  <Trash
                    className="deleteIcon"
                    onClick={() => deleteBlog(blog)}
                  />
                )}
                {hasPermission("Edit Project Article") && (
                  <Edit
                    className="editIcon"
                    onClick={() => handleUpdateBlog(blog)}
                  />
                )}
              </div>
              <Dropdown blog={blog} index={index} deleteBlog={deleteBlog} />
            </div>
          </div>

          {!grid && (
            <div className="flex items-center border-t pt-2 mt-2 dark:border-white/20">
              <p className="font-medium text-sm mr-2">Tags:</p>
              <div className="flex items-center gap-2">
                {blogTags.map((tag, index) => (
                  <span
                    key={index}
                    className="flex items-center text-xs px-2 bg-orange-100 text-orange-600 rounded gap-2"
                  >
                    {tag}
                  </span>
                ))}
              </div>

              <button
                type="button"
                onClick={() => setTagsModal(true)}
                className="flex items-center text-xs font-medium mx-1 gap-1 hover:text-primary hover:border-primary border-b border-black transition-all"
              >
                <Plus className="w-4 h-4" /> Add/Edit Tags
              </button>
            </div>
          )}
        </div>
      </div>

      {deleteConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800/10 dark:bg-black/20 top-0 left-0 z-50">
          <div className="bg-white p-6 rounded shadow-xl w-full max-w-md">
            <div className="flex items-center gap-2">
              <FileWarning className="w-5 h-5 text-red-500" />{" "}
              <h2 className="text-lg font-bold">Are you sure?</h2>
            </div>
            <p>Do you really want to delete this article.</p>
            <div className="flex justify-end mt-4 gap-2">
              <button
                onClick={async (e) => {
                  await deleteFromList(e);
                  setDeleteConfirmation(false);
                }}
                className="btnPrimary bg-red-500 text-sm"
                disabled={deletingArticle}
              >
                {deletingArticle ? (
                  <LoaderCircle className="w-4 h-4 animate-spin" />
                ) : (
                  <Trash className="w-4 h-4" />
                )}
                {deletingArticle ? <p>Deleting</p> : <p>Delete Article</p>}
              </button>
              <button
                className="bg-gray-300 px-4 py-2 rounded-md text-sm"
                onClick={() => setDeleteConfirmation(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {tagsModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-400 dark:bg-black/50 bg-opacity-75 transition-opacity">
          <div
            ref={tagsModalRef}
            className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-2xl dark:border-white/20 max-w-2xl w-full"
          >
            <h3 className="font-bold">Article Tags</h3>
            <p className="text-secondary text-sm py-2 mb-3 whitespace-nowrap flex items-center gap-1">
              <Info className="w-4 h-4" /> Click on add or press enter to add
              tag.
            </p>
            <div className="flex items-end gap-3">
              <InputField
                label="new tag"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={addTag}
                autoFocus
              />
              <button
                type="button"
                className="btnPrimary text-base"
                disabled={!input}
                onClick={addTag}
              >
                Add
              </button>
            </div>

            <div className="flex items-center flex-wrap gap-2 mt-5">
              {blogTags.map((tag, index) => (
                <span
                  key={index}
                  className="flex items-center px-3 py-1 bg-gray-100 dark:bg-gray-700 rounded gap-2"
                >
                  {tag}
                  <X
                    className="w-4 h-4 text-red-400 hover:text-red-500 cursor-pointer"
                    onClick={() => removeTag(index)}
                  />
                </span>
              ))}
            </div>

            <div className="flex items-center justify-end mt-7 gap-2">
              <button
                type="button"
                title="close"
                onClick={() => setTagsModal(false)}
                className="btnPrimary bg-red-400 hover:bg-red-500"
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={action || input}
                onClick={handleSaveTags}
                className={`btnPrimary bg-secondary px-3 ${
                  action && "cursor-wait"
                }`}
              >
                {action ? (
                  <LoaderCircle className="w-4 animate-spin" />
                ) : (
                  <Save className="w-4 h-4" />
                )}
                {action ? <p>Updating Tags</p> : <p>Save & Update</p>}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
