import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";
import { Copy, CheckCircle } from "lucide-react";

export default function CreateProject() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({
    // Step 1: Basic Project Info
    projectName: "Test Project",
    industry: "Technology",
    category: "Software",

    // Step 2: Content Settings
    titleCount: "5",
    authorCount: "3",
    tagCount: "10",
    wordCount: "1000",
    sectionCount: "4",
    executeNow: "0",
    imgWidth: "1280",
    imgHeight: "720",

    // Step 3: Company & Domain Info
    domain: "test.com",
    createdBy: "admin",
    companyDetails: {
      companyName: "Test Company",
      website: "https://testcompany.com",
      location: "New York, USA",
      phoneNumber: "+1 234 567 8900",
    },
  });
  const [projectResponse, setProjectResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes(".")) {
      // Handle nested companyDetails
      const [parent, child] = name.split(".");
      setFormData((prevState) => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleNext = (e) => {
    if (e) {
      e.preventDefault();
    }
    setCurrentStep((prev) => Math.min(prev + 1, 3));
  };

  const handlePrev = (e) => {
    if (e) {
      e.preventDefault();
    }
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const payload = {
      projectName: formData.projectName,
      industry: formData.industry,
      category: formData.category,
      titleCount: parseInt(formData.titleCount),
      authorCount: parseInt(formData.authorCount),
      tagCount: parseInt(formData.tagCount),
      wordCount: parseInt(formData.wordCount),
      createdBy: formData.createdBy,
      domain: formData.domain,
      sectionCount: parseInt(formData.sectionCount),
      executeNow: parseInt(formData.executeNow),
      imgWidth: parseInt(formData.imgWidth),
      imgHeight: parseInt(formData.imgHeight),
      companyDetails: formData.companyDetails,
      additionalData: {},
    };

    try {
      const response = await axios.post(
        "https://apipm.ecommcube.com/create_project",
        payload
      );
      if (response.data) {
        setProjectResponse(response.data.data);
        toast.success("Project created successfully!");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to create project");
      console.error("Project creation error:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard!");
    } catch (err) {
      toast.error("Failed to copy text");
      console.error("Failed to copy:", err);
    }
  };

  const renderStep1 = () => (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Project Name
          </label>
          <input
            type="text"
            name="projectName"
            value={formData.projectName}
            onChange={handleChange}
            className="w-full px-4 py-2.5 bg-white dark:bg-gray-900/50 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-lg text-gray-900 dark:text-gray-200 text-sm placeholder:text-gray-500"
            placeholder="Enter project name"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Industry
          </label>
          <input
            type="text"
            name="industry"
            value={formData.industry}
            onChange={handleChange}
            className="w-full px-4 py-2.5 bg-white dark:bg-gray-900/50 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-lg text-gray-900 dark:text-gray-200 text-sm placeholder:text-gray-500"
            placeholder="Enter industry"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Category
          </label>
          <input
            type="text"
            name="category"
            value={formData.category}
            onChange={handleChange}
            className="w-full px-4 py-2.5 bg-white dark:bg-gray-900/50 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-lg text-gray-900 dark:text-gray-200 text-sm placeholder:text-gray-500"
            placeholder="Enter category"
          />
        </div>
      </div>
    </div>
  );

  const renderStep2 = () => (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Title Count
          </label>
          <input
            type="number"
            name="titleCount"
            value={formData.titleCount}
            onChange={handleChange}
            className="w-full px-4 py-2.5 bg-white dark:bg-gray-900/50 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-lg text-gray-900 dark:text-gray-200 text-sm placeholder:text-gray-500"
            placeholder="Enter title count"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Author Count
          </label>
          <input
            type="number"
            name="authorCount"
            value={formData.authorCount}
            onChange={handleChange}
            className="w-full px-4 py-2.5 bg-white dark:bg-gray-900/50 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-lg text-gray-900 dark:text-gray-200 text-sm placeholder:text-gray-500"
            placeholder="Enter author count"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Tag Count
          </label>
          <input
            type="number"
            name="tagCount"
            value={formData.tagCount}
            onChange={handleChange}
            className="w-full px-4 py-2.5 bg-white dark:bg-gray-900/50 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-lg text-gray-900 dark:text-gray-200 text-sm placeholder:text-gray-500"
            placeholder="Enter tag count"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Word Count
          </label>
          <input
            type="number"
            name="wordCount"
            value={formData.wordCount}
            onChange={handleChange}
            className="w-full px-4 py-2.5 bg-white dark:bg-gray-900/50 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-lg text-gray-900 dark:text-gray-200 text-sm placeholder:text-gray-500"
            placeholder="Enter word count"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Section Count
          </label>
          <input
            type="number"
            name="sectionCount"
            value={formData.sectionCount}
            onChange={handleChange}
            className="w-full px-4 py-2.5 bg-white dark:bg-gray-900/50 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-lg text-gray-900 dark:text-gray-200 text-sm placeholder:text-gray-500"
            placeholder="Enter section count"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Execute Now
          </label>
          <select
            name="executeNow"
            value={formData.executeNow}
            onChange={handleChange}
            className="w-full px-4 py-2.5 bg-white dark:bg-gray-900/50 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-lg text-gray-900 dark:text-gray-200 text-sm placeholder:text-gray-500"
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
      </div>
    </div>
  );

  const renderStep3 = () => (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Domain
          </label>
          <input
            type="text"
            name="domain"
            value={formData.domain}
            onChange={handleChange}
            className="w-full px-4 py-2.5 bg-white dark:bg-gray-900/50 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-lg text-gray-900 dark:text-gray-200 text-sm placeholder:text-gray-500"
            placeholder="Enter domain"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Company Name
          </label>
          <input
            type="text"
            name="companyDetails.companyName"
            value={formData.companyDetails.companyName}
            onChange={handleChange}
            className="w-full px-4 py-2.5 bg-white dark:bg-gray-900/50 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-lg text-gray-900 dark:text-gray-200 text-sm placeholder:text-gray-500"
            placeholder="Enter company name"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Website
          </label>
          <input
            type="text"
            name="companyDetails.website"
            value={formData.companyDetails.website}
            onChange={handleChange}
            className="w-full px-4 py-2.5 bg-white dark:bg-gray-900/50 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-lg text-gray-900 dark:text-gray-200 text-sm placeholder:text-gray-500"
            placeholder="Enter website"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Location
          </label>
          <input
            type="text"
            name="companyDetails.location"
            value={formData.companyDetails.location}
            onChange={handleChange}
            className="w-full px-4 py-2.5 bg-white dark:bg-gray-900/50 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-lg text-gray-900 dark:text-gray-200 text-sm placeholder:text-gray-500"
            placeholder="Enter location"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Phone Number
          </label>
          <input
            type="text"
            name="companyDetails.phoneNumber"
            value={formData.companyDetails.phoneNumber}
            onChange={handleChange}
            className="w-full px-4 py-2.5 bg-white dark:bg-gray-900/50 border border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-lg text-gray-900 dark:text-gray-200 text-sm placeholder:text-gray-500"
            placeholder="Enter phone number"
          />
        </div>
      </div>
    </div>
  );

  const renderResponse = () => {
    if (!projectResponse) return null;

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 space-y-6"
      >
        <div className="bg-gray-100/50 dark:bg-gray-800/50 backdrop-blur rounded-2xl border border-gray-200 dark:border-gray-700 p-6">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-6 flex items-center gap-2">
            <CheckCircle className="w-5 h-5 text-green-400" />
            Project Details
          </h3>

          {/* Basic Info Section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <div className="space-y-1">
              <label className="text-sm text-gray-600 dark:text-gray-400">
                Project Name
              </label>
              <p className="text-gray-900 dark:text-white font-medium">
                {projectResponse.projectName}
              </p>
            </div>
            <div className="space-y-1">
              <label className="text-sm text-gray-600 dark:text-gray-400">
                Industry
              </label>
              <p className="text-gray-900 dark:text-white font-medium">
                {projectResponse.industry}
              </p>
            </div>
            <div className="space-y-1">
              <label className="text-sm text-gray-600 dark:text-gray-400">
                Category
              </label>
              <p className="text-gray-900 dark:text-white font-medium">
                {projectResponse.category}
              </p>
            </div>
          </div>

          {/* Generated Titles Section */}
          <div className="mb-8">
            <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              Generated Titles
            </h4>
            <div className="space-y-3">
              {projectResponse.titles.map((title, index) => (
                <div
                  key={index}
                  className="p-3 bg-gray-200/30 dark:bg-gray-700/30 rounded-lg border border-gray-300 dark:border-gray-700 flex justify-between items-center group"
                >
                  <p className="text-gray-800 dark:text-gray-200">{title}</p>
                  <button
                    onClick={() => copyToClipboard(title)}
                    className="opacity-0 group-hover:opacity-100 transition-opacity p-2 hover:bg-gray-300/50 dark:hover:bg-gray-600/50 rounded-lg"
                    title="Copy to clipboard"
                  >
                    <Copy className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Project Metadata */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <div className="space-y-4">
              <div className="space-y-1">
                <label className="text-sm text-gray-600 dark:text-gray-400">
                  Project ID
                </label>
                <p className="text-gray-900 dark:text-white font-medium font-mono text-sm break-all">
                  {projectResponse.projectMetadata.project_id}
                </p>
              </div>
              <div className="space-y-1">
                <label className="text-sm text-gray-600 dark:text-gray-400">
                  Created At
                </label>
                <p className="text-gray-900 dark:text-white font-medium">
                  {projectResponse.projectCreatedAt}
                </p>
              </div>
            </div>
            <div className="space-y-4">
              <div className="space-y-1">
                <label className="text-sm text-gray-600 dark:text-gray-400">
                  Created By
                </label>
                <p className="text-gray-900 dark:text-white font-medium">
                  {projectResponse.projectCreatedBy}
                </p>
              </div>
              <div className="space-y-1">
                <label className="text-sm text-gray-600 dark:text-gray-400">
                  Domain
                </label>
                <p className="text-gray-900 dark:text-white font-medium">
                  {projectResponse.domain}
                </p>
              </div>
            </div>
          </div>

          {/* Content Statistics */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {[
              { label: "Word Count", value: projectResponse.wordCount },
              { label: "Section Count", value: projectResponse.sectionCount },
              { label: "Title Count", value: projectResponse.titleCount },
              { label: "Tag Count", value: projectResponse.tagCount },
            ].map((stat, index) => (
              <div
                key={index}
                className="bg-gray-200/30 dark:bg-gray-700/30 rounded-lg p-4 border border-gray-300 dark:border-gray-700"
              >
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {stat.label}
                </p>
                <p className="text-xl font-semibold text-gray-900 dark:text-white">
                  {stat.value}
                </p>
              </div>
            ))}
          </div>

          <div className="mt-6 flex justify-end">
            <button
              onClick={() =>
                navigate(
                  `/projects/${projectResponse.projectMetadata.project_id}`
                )
              }
              className="px-4 py-2 text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 rounded-lg transition-colors"
            >
              View Project Details →
            </button>
          </div>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="relative">
      <div className="absolute inset-0 bg-gradient-to-b from-blue-500/5 via-transparent to-transparent"></div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-6xl mx-auto p-8"
      >
        {/* Header Section */}
        <div className="text-center mb-16">
          <div className="inline-block">
            <span className="inline-flex items-center justify-center px-4 py-1 text-sm font-medium text-blue-400 bg-blue-500/10 rounded-full mb-4">
              AI-Powered Content Generation
            </span>
          </div>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            AI Content Generator
          </h1>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Create high-quality content in minutes using advanced artificial
            intelligence
          </p>
        </div>

        {/* Show either the form or the response */}
        {projectResponse ? (
          renderResponse()
        ) : (
          <>
            {/* Progress Steps */}
            <div className="mb-12">
              <div className="max-w-3xl mx-auto flex justify-between relative">
                <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-gray-200 dark:bg-gray-800" />
                {[1, 2, 3].map((step) => (
                  <motion.div
                    key={step}
                    initial={{ scale: 0.8 }}
                    animate={{ scale: currentStep >= step ? 1 : 0.8 }}
                    className="relative flex flex-col items-center"
                  >
                    <div
                      onClick={() => setCurrentStep(step)}
                      className={`w-10 h-10 rounded-full cursor-pointer flex items-center justify-center relative z-10
                        ${
                          step <= currentStep
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200 dark:bg-gray-800 text-gray-500 dark:text-gray-400"
                        }`}
                    >
                      {step}
                    </div>
                    <span
                      className={`mt-3 text-sm font-medium ${
                        step <= currentStep ? "text-gray-200" : "text-gray-500"
                      }`}
                    >
                      {step === 1
                        ? "Project Info"
                        : step === 2
                        ? "Content Setup"
                        : "Company Details"}
                    </span>
                  </motion.div>
                ))}
              </div>
            </div>

            {/* Form Section */}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (currentStep < 3) {
                  handleNext();
                } else {
                  handleSubmit(e);
                }
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-gray-100/50 dark:bg-gray-800/50 backdrop-blur rounded-2xl border border-gray-200 dark:border-gray-700"
              >
                <div className="p-8">
                  {currentStep === 1 && renderStep1()}
                  {currentStep === 2 && renderStep2()}
                  {currentStep === 3 && renderStep3()}

                  <div className="mt-8 flex justify-between">
                    {currentStep > 1 && (
                      <button
                        type="button"
                        onClick={handlePrev}
                        className="px-6 py-2.5 text-sm font-medium text-white bg-gray-500 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 rounded-lg transition-colors"
                      >
                        Previous
                      </button>
                    )}
                    {currentStep < 3 ? (
                      <button
                        type="button"
                        onClick={handleNext}
                        className="ml-auto px-6 py-2.5 text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 rounded-lg transition-colors"
                      >
                        Continue
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled={isProcessing}
                        className="ml-auto px-6 py-2.5 text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                      >
                        {isProcessing ? (
                          <>
                            <svg
                              className="animate-spin h-4 w-4"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              />
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              />
                            </svg>
                            <span>Processing...</span>
                          </>
                        ) : (
                          "Generate Content"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </motion.div>
            </form>
          </>
        )}
      </motion.div>
    </div>
  );
}
