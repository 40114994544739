import { X } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import AIContentManager from "../AIContentManager";
import AskAnything from "../../NewProject/AIProject/AskAnything";
import GenerateImage from "../../NewProject/AIProject/GenerateImage";

export default function AIChat({
  showAIDropdown,
  setShowAIDropdown,
  currentProject,
  currentPath,
}) {
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [activeTab, setActiveTab] = useState("chat");

  // Escape key to close sidebar
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape" && showAIDropdown) {
        setShowAIDropdown(false);
      }
    };

    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, [showAIDropdown, setShowAIDropdown]);

  useEffect(() => {
    if (showAIDropdown) {
      inputRef.current?.focus();
    }
  }, [showAIDropdown]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        showAIDropdown
      ) {
        setShowAIDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showAIDropdown, setShowAIDropdown]);

  const getContextInfo = () => {
    if (!currentProject) return { project: "No Project", section: "Dashboard" };

    const pathSegments = currentPath?.split("/").filter(Boolean);
    const currentSection = pathSegments?.[pathSegments.length - 1];

    // Convert path to readable format
    const section = currentSection
      ? currentSection
          .replace(/-/g, " ")
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      : "Dashboard";

    return {
      project: currentProject.project_name || "No Project",
      section,
    };
  };

  const { project, section } = getContextInfo();

  return (
    <div
      ref={containerRef}
      className={`fixed top-0 right-0 z-50 h-screen w-fit shadow-l shadow-black/20 dark:shadow-gray-600/40 flex flex-col bg-white dark:bg-gray-950 backdrop-blur-xl border-l border-gray-200/20 dark:border-gray-800/50 transform transition-all duration-300 ease-out ${
        showAIDropdown ? "translate-x-0" : "translate-x-full"
      }`}
      onClick={() => inputRef.current?.focus()}
    >
      {/* Header */}
      <div className="px-6 py-1 dark:bg-gray-950 backdrop-blur-md">
        <div className="flex items-center justify-between border-b border-gray-200/50 dark:border-gray-700/50">
          <div className="flex">
            <button
              onClick={() => setActiveTab("manager")}
              className={`px-5 py-3 text-sm font-medium transition-all duration-200 relative
                  ${
                    activeTab === "manager"
                      ? "text-primary"
                      : "text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200"
                  }`}
            >
              <div className="flex items-center gap-2">
                <span>⚡</span>
                AI Manager
              </div>
              {activeTab === "manager" && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-primary" />
              )}
            </button>
            <button
              onClick={() => setActiveTab("chat")}
              className={`px-5 py-3 text-sm font-medium transition-all duration-200 relative
                  ${
                    activeTab === "chat"
                      ? "text-primary"
                      : "text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200"
                  }`}
            >
              <div className="flex items-center gap-2">
                <span>💬</span>
                Chat
              </div>
              {activeTab === "chat" && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-primary" />
              )}
            </button>
            <button
              onClick={() => setActiveTab("image")}
              className={`px-5 py-3 text-sm font-medium transition-all duration-200 relative
                  ${
                    activeTab === "image"
                      ? "text-primary"
                      : "text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200"
                  }`}
            >
              <div className="flex items-center gap-2">
                <span>🎨</span>
                Image
              </div>
              {activeTab === "image" && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-primary" />
              )}
            </button>
          </div>

          <button
            onClick={() => setShowAIDropdown(false)}
            className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 
                transition-all duration-200 hover:rotate-90"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        {/* Context info - only show in chat tab */}
        {activeTab === "chat" && (
          <div className="flex items-center gap-2 flex-wrap mt-1 py-3">
            <div className="flex items-center text-xs text-gray-600 dark:text-gray-300 bg-gray-100/80 dark:bg-neutral-800/80 rounded-full px-4 py-1.5 w-fit duration-200">
              <span className="flex items-center gap-2">
                <span className="w-3.5 h-3.5">🗂️</span>
                {project}
              </span>
            </div>
            <div className="flex items-center text-xs text-gray-600 dark:text-gray-300 bg-gray-100/80 dark:bg-neutral-800/80 rounded-full px-4 py-1.5 w-fit duration-200">
              <span className="flex items-center gap-2">
                <span className="w-3.5 h-3.5">📁</span>
                {section}
              </span>
            </div>
          </div>
        )}
      </div>

      {activeTab === "chat" ? (
        <AskAnything className="w-[500px] bg-gray-100" />
      ) : activeTab === "manager" ? (
        <AIContentManager sidebar />
      ) : (
        <GenerateImage chatbar className="w-[600px]" />
      )}
    </div>
  );
}
