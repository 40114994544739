import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { Send, User, Bot, Sparkles, Copy, Check } from "lucide-react";
import { toast } from "react-hot-toast";
import ReactMarkdown from "react-markdown";
import { TypeAnimation } from 'react-type-animation';

export default function AskAnything({ className }) {
  const [messages, setMessages] = useState([]);
  const [prompt, setPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [copiedId, setCopiedId] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [currentTypingMessage, setCurrentTypingMessage] = useState("");

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!prompt.trim()) return;

    const userMessage = {
      type: "user",
      content: prompt,
      timestamp: new Date().toISOString(),
    };

    setMessages((prev) => [...prev, userMessage]);
    setPrompt("");
    setIsLoading(true);

    try {
      const response = await fetch("https://apipm.ecommcube.com/askanything", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ prompt: userMessage.content }),
      });

      if (!response.ok) {
        throw new Error(`Error ${response.status}`);
      }

      const answer = await response.text();
      
      // Set the current typing message and start typing animation
      setCurrentTypingMessage(answer);
      setIsTyping(true);

      // Wait for typing animation to complete (adjust timing based on content length)
      const typingDuration = Math.min(answer.length * 20, 3000); // Cap at 3 seconds
      await new Promise(resolve => setTimeout(resolve, typingDuration));

      const botMessage = {
        type: "bot",
        content: answer,
        timestamp: new Date().toISOString(),
      };

      setMessages((prev) => [...prev, botMessage]);
      setIsTyping(false);
      setCurrentTypingMessage("");

    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to get response");

      const errorMessage = {
        type: "bot",
        content: "Sorry, I couldn't process your request. Please try again.",
        timestamp: new Date().toISOString(),
        isError: true,
      };

      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = async (text, messageId) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedId(messageId);
      toast.success("Copied to clipboard!");
      setTimeout(() => setCopiedId(null), 2000);
    } catch (err) {
      toast.error("Failed to copy text");
    }
  };

  const getWordCount = (text) => {
    return text.trim().split(/\s+/).length;
  };

  return (
    <div
      className={`max-w-5xl mx-auto px-4 relative ${
        className ? className : "py-6"
      }`}
    >
      <div className="flex-1 space-y-6 pt-4 pb-28 h-[calc(100vh-6.5rem)] transparent-scrollbar overflow-y-scroll">
        {messages?.length > 0 ? (
          messages.map((message, index) => (
            <motion.div
              key={message.timestamp}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`flex ${
                message.type === "user" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`flex items-start space-x-3 max-w-[85%] ${
                  message.type === "user"
                    ? "flex-row-reverse space-x-reverse"
                    : ""
                }`}
              >
                <div
                  className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center ${
                    message.type === "user"
                      ? "bg-primary"
                      : "bg-gray-200 dark:bg-gray-700"
                  }`}
                >
                  {message.type === "user" ? (
                    <User className="h-5 w-5 text-white" />
                  ) : (
                    <Bot className="h-5 w-5 text-gray-600 dark:text-gray-300" />
                  )}
                </div>
                <div
                  className={`flex flex-col ${
                    message.type === "user" ? "items-end" : "items-start"
                  }`}
                >
                  <div
                    className={`p-4 rounded-2xl ${
                      message.type === "user"
                        ? "bg-primary text-white"
                        : message.isError
                        ? "bg-red-50 dark:bg-red-900/20 text-red-500"
                        : "bg-white dark:bg-gray-800 text-gray-900 dark:text-white shadow-sm"
                    }`}
                  >
                    {message.type === "user" ? (
                      <p className="whitespace-pre-wrap">{message.content}</p>
                    ) : (
                      <div className="prose dark:prose-invert prose-sm max-w-none">
                        <ReactMarkdown>{message.content}</ReactMarkdown>
                      </div>
                    )}
                  </div>
                  <div className="flex items-center justify-between space-x-4 mt-1 px-2 w-full">
                    <span className="text-xs text-gray-500 dark:text-gray-400">
                      {new Date(message.timestamp).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                    {message.type !== "user" && (
                      <div className="flex items-center space-x-3 justify-end flex-1">
                        <button
                          onClick={() =>
                            handleCopy(message.content, message.timestamp)
                          }
                          className="text-gray-500 text-sm flex items-center gap-1 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-colors"
                          title="Copy response"
                        >
                          {copiedId === message.timestamp ? (
                            <Check className="h-4 w-4 text-green-500" />
                          ) : (
                            <Copy className="h-4 w-4" />
                          )}
                          Copy
                        </button>
                        <span className="text-sm text-gray-500 dark:text-gray-400">
                          {getWordCount(message.content)} words
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          ))
        ) : (
          <div className="flex flex-col items-center gap-3 justify-center h-[calc(100vh-6.5rem)]">
            <Sparkles className="h-10 w-10 text-primary dark:text-gray-300" />
            <p className="text-gray-500 dark:text-gray-400">
              Ask anything about your project
            </p>
          </div>
        )}
        {isTyping && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex justify-start"
          >
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center bg-gray-200 dark:bg-gray-700">
                <Bot className="h-5 w-5 text-gray-600 dark:text-gray-300" />
              </div>
              <div className="bg-white dark:bg-gray-800 p-4 rounded-2xl shadow-sm">
                <div className="prose dark:prose-invert prose-sm max-w-none">
                  <TypeAnimation
                    sequence={[currentTypingMessage]}
                    wrapper="div"
                    cursor={true}
                    repeat={0}
                    speed={70}
                    style={{ whiteSpace: 'pre-wrap' }}
                  />
                </div>
              </div>
            </div>
          </motion.div>
        )}
        {isLoading && !isTyping && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex justify-start"
          >
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center bg-gray-200 dark:bg-gray-700">
                <Bot className="h-5 w-5 text-gray-600 dark:text-gray-300" />
              </div>
              <div className="bg-white dark:bg-gray-800 p-4 rounded-2xl shadow-sm">
                <div className="flex items-center space-x-2">
                  <div className="w-2 h-2 bg-primary rounded-full animate-bounce" />
                  <div className="w-2 h-2 bg-primary rounded-full animate-bounce delay-100" />
                  <div className="w-2 h-2 bg-primary rounded-full animate-bounce delay-200" />
                </div>
              </div>
            </div>
          </motion.div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="border-t border-gray-100 dark:border-gray-700 p-4 bg-white/90 rounded-t-xl dark:bg-gray-800 absolute bottom-0 left-0 z-20 w-full">
        <form onSubmit={handleSubmit} className="flex space-x-4">
          <input
            type="text"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Type your message..."
            className="flex-1 px-4 py-3 rounded-xl border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-900 text-gray-900 dark:text-white 
                focus:ring-2 focus:ring-primary focus:border-transparent focus:outline-none
                hover:border-blue-300 dark:hover:border-blue-400
                transition-all duration-200"
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={isLoading || !prompt.trim()}
            className="px-6 py-3 bg-primary text-white rounded-xl hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            <Send className="h-5 w-5" />
          </button>
        </form>
      </div>
    </div>
  );
}
