import React, { useState, useCallback, useEffect } from "react";
import useApi from "../../../../utils/useApi";
import NavigationButtons from "./NavigationButtons";

export default function Step4Domain({
  formData,
  setFormData,
  currentStep,
  totalSteps,
  handleNext,
  handleBack,
  isStepValid,
  setChat,
  handleExecute,
}) {
  const page = 1;
  const pageSize = 250;

  const { request } = useApi();
  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const getDomains = useCallback(async (filters) => {
    setLoading(true);
    try {
      const res = await request({
        method: "post",
        url: "domains/report",
        data: {
          page: page.toString(),
          size: pageSize,
          filters: filters ? filters : "",
          sort_field: "",
          sort_by: "",
        },
      });
      setTotalItems(res.totalItems);
      setDomains(res.data);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getDomains();
  }, []);

  return (
    <div className="space-y-6 mx-auto w-[671px] p-6 bg-white dark:bg-gray-800 rounded-2xl shadow-sm border border-gray-200 dark:border-gray-700">
      <div className="space-y-5">
        <div>
          <label className="block text-sm font-medium mb-1">
            Select Existing Domain
          </label>
          <select
            value={formData.siteDomain}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, siteDomain: e.target.value }))
            }
            className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                     dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
          >
            <option value="">Select a domain</option>
            {domains.map((domain) => (
              <option key={domain.id} value={domain.domain}>
                {domain.domain}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-5">
          <label className="block text-sm font-medium mb-1">
            Or Enter Custom Domain
          </label>
          <input
            type="text"
            value={formData.siteDomain}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, siteDomain: e.target.value }))
            }
            className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                     dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
            placeholder="Enter your domain name"
          />
        </div>
      </div>
      <NavigationButtons
        currentStep={currentStep}
        totalSteps={totalSteps}
        handleNext={handleNext}
        handleBack={handleBack}
        isStepValid={isStepValid}
        setChat={setChat}
        handleExecute={handleExecute}
      />
    </div>
  );
}
