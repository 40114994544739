import React, { useState } from "react";
import {
  Image,
  Loader,
  AlertCircle,
  Download,
  Copy,
  Check,
} from "lucide-react";
import { toast } from "react-hot-toast";

const IMAGE_STYLES = ["Photo", "Cinematic", "Anime", "Doodle", "(No style)"];

export default function GenerateImage({ chatbar, className }) {
  const [topicName, setTopicName] = useState("");
  const [selectedStyle, setSelectedStyle] = useState("Photo");
  const [useTextLine, setUseTextLine] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [imageHistory, setImageHistory] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleGenerateImage = async () => {
    if (!topicName.trim()) {
      toast.error("Please enter a topic name");
      return;
    }

    setIsGenerating(true);
    setError(null);

    try {
      const response = await fetch(
        "https://apipm.ecommcube.com/gen_img_bytes",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            topic_name: topicName,
            use_text_line: useTextLine,
            style: selectedStyle,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to generate image");
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);

      // Add new image to history with metadata
      const newImage = {
        url: imageUrl,
        timestamp: new Date().toISOString(),
        topic: topicName,
        style: selectedStyle,
      };

      setImageHistory((prev) => [newImage, ...prev]);
      setGeneratedImage(imageUrl);
      setSelectedImage(newImage);
      toast.success("Image generated successfully!");
    } catch (error) {
      console.error("Error generating image:", error);
      setError("Failed to generate image. Please try again.");
      toast.error("Failed to generate image");
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDownload = () => {
    if (generatedImage) {
      const link = document.createElement("a");
      link.href = generatedImage;
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      link.download = `generated_image_${timestamp}.jpg`;
      link.click();
    }
  };

  const handleCopyImage = async () => {
    try {
      const response = await fetch(generatedImage);
      const blob = await response.blob();
      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob,
        }),
      ]);
      setIsCopied(true);
      toast.success("Image copied to clipboard!");
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy image:", err);
      toast.error("Failed to copy image to clipboard");
    }
  };

  return (
    <div
      className={`${
        chatbar
          ? "h-[calc(100vh-3.5rem)] overflow-y-scroll flex flex-col"
          : "min-h-screen bg-gray-50 dark:bg-gray-900"
      } relative ${className}`}
    >
      <div className={`${chatbar ? "" : "max-w-7xl mx-auto px-4 py-8"}`}>
        <div
          className={`${
            chatbar ? "flex flex-col" : "flex flex-col lg:flex-row"
          } gap-8`}
        >
          {/* Controls Section */}
          <div className={`${chatbar ? "order-1" : "lg:w-80"}`}>
            {!chatbar && (
              <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-8 flex items-center">
                <Image className="h-8 w-8 mr-4 text-primary" />
                Generate Image
              </h1>
            )}
            <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm space-y-6">
              {/* Topic Input */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Topic Name
                </label>
                <input
                  type="text"
                  value={topicName}
                  onChange={(e) => setTopicName(e.target.value)}
                  placeholder="Explain what you are imagining"
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white 
                  focus:ring-2 focus:ring-blue-500 focus:border-transparent focus:outline-none
                  hover:border-blue-300 dark:hover:border-blue-400
                  transition-colors duration-200"
                />
              </div>

              {/* Style Selection */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Image Style
                </label>
                <div
                  className={`${chatbar ? "flex flex-row gap-2" : "space-y-2"}`}
                >
                  {IMAGE_STYLES.map((style) => (
                    <button
                      key={style}
                      onClick={() => setSelectedStyle(style)}
                      className={`${
                        chatbar ? "flex-1 min-w-[100px]" : "w-full"
                      } px-3 py-2 rounded-lg text-sm font-medium transition-colors ${
                        selectedStyle === style
                          ? "bg-primary text-white"
                          : "bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600"
                      }`}
                    >
                      {style}
                    </button>
                  ))}
                </div>
              </div>

              {/* Text Line Option */}
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="useTextLine"
                  checked={useTextLine}
                  onChange={(e) => setUseTextLine(e.target.checked)}
                  className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                />
                <label
                  htmlFor="useTextLine"
                  className="ml-2 block text-sm text-gray-700 dark:text-gray-300"
                >
                  Use text line in generation
                </label>
              </div>

              {/* Generate Button */}
              <button
                onClick={handleGenerateImage}
                disabled={isGenerating}
                className="w-full px-4 py-3 bg-primary text-white rounded-lg hover:bg-oranage-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed font-medium"
              >
                {isGenerating ? "Generating..." : "Generate Image"}
              </button>

              {/* Error Message */}
              {error && (
                <div className="flex items-center space-x-2 text-red-500 mt-4">
                  <AlertCircle className="h-5 w-5" />
                  <span>{error}</span>
                </div>
              )}
            </div>
          </div>

          {/* Image Preview Section */}
          <div className={`${chatbar ? "order-2" : "flex-1"}`}>
            <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
              {/* Main Preview */}
              <div className="mb-6">
                {isGenerating ? (
                  <div
                    className={`flex items-center justify-center ${
                      chatbar ? "h-[300px]" : "h-[600px]"
                    }`}
                  >
                    <div className="flex flex-col items-center space-y-4">
                      <Loader className="h-8 w-8 animate-spin text-primary" />
                      <p className="text-gray-500 dark:text-gray-400">
                        Imagining your image...
                      </p>
                    </div>
                  </div>
                ) : generatedImage ? (
                  <div
                    className={`relative ${
                      chatbar ? "h-[450px]" : "h-[600px]"
                    }`}
                  >
                    <div className="absolute bottom-4 leftt-4 flex space-x-2 z-10">
                      <button
                        onClick={handleCopyImage}
                        className="p-2 bg-white dark:bg-gray-700 rounded-lg shadow-lg hover:bg-gray-50 dark:hover:bg-gray-600 transition-colors"
                        title="Copy to clipboard"
                      >
                        {isCopied ? (
                          <Check className="h-5 w-5 text-green-500" />
                        ) : (
                          <Copy className="h-5 w-5 text-gray-600 dark:text-gray-300" />
                        )}
                      </button>
                      <button
                        onClick={handleDownload}
                        className="p-2 bg-white dark:bg-gray-700 rounded-lg shadow-lg hover:bg-gray-50 dark:hover:bg-gray-600 transition-colors"
                        title="Download image"
                      >
                        <Download className="h-5 w-5 text-gray-600 dark:text-gray-300" />
                      </button>
                    </div>
                    <div className="h-full flex items-center justify-center">
                      <img
                        src={generatedImage}
                        alt="Generated"
                        className="max-h-full max-w-full object-contain rounded-lg"
                      />
                    </div>
                    {selectedImage && (
                      <div className="absolute top-0 left-0 bg-white dark:bg-gray-700 rounded-lg px-3 py-2 shadow-lg">
                        <p className="text-sm text-gray-600 dark:text-gray-300">
                          {selectedImage.topic} • {selectedImage.style}
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={`flex items-center justify-center ${
                      chatbar ? "h-[300px]" : "h-[600px]"
                    } border-2 border-dashed border-gray-300 dark:border-gray-700 rounded-lg`}
                  >
                    <div className="text-center">
                      <Image className="h-16 w-16 mx-auto text-gray-400 mb-4" />
                      <p className="text-gray-500 dark:text-gray-400">
                        Generated image will appear here
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Image History - Show on left in chatbar mode */}
          {imageHistory.length > 0 && (
            <div className={`${chatbar ? "order-3 p-5" : "mt-6"}`}>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                Generated Images History
              </h3>
              <div
                className={`${
                  chatbar
                    ? "flex items-center gap-4 flex-wrap"
                    : "grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4"
                }`}
              >
                {imageHistory.map((image, index) => (
                  <div
                    key={image.timestamp}
                    className={`relative ${
                      chatbar ? "h-24" : "w-24 h-24"
                    } rounded-lg overflow-hidden cursor-pointer transition-all duration-200 ${
                      selectedImage === image
                        ? "ring-2 ring-blue-500"
                        : "hover:opacity-80"
                    }`}
                    onClick={() => {
                      setGeneratedImage(image.url);
                      setSelectedImage(image);
                    }}
                  >
                    <img
                      src={image.url}
                      alt={`Generated ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-2">
                      <p className="text-xs text-white truncate">
                        {image.topic}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
