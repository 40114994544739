import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-hot-toast";
import {
  Activity,
  AlertCircle,
  Clock,
  Archive,
  Play,
  Calendar,
  Timer,
  User,
  RefreshCw,
  X,
  Image,
  FileText,
} from "lucide-react";

export default function TaskTracking() {
  const [taskData, setTaskData] = useState(null);
  const [projects, setProjects] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [categories] = useState(["AI", "ML", "Web", "Mobile"]); // Add your categories here
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showJson, setShowJson] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [taskDetails, setTaskDetails] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [taskImage, setTaskImage] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [detailsError, setDetailsError] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [updateTaskId, setUpdateTaskId] = useState(null);
  const [updateConfig, setUpdateConfig] = useState({
    logicId: 1,
    priority: 0,
    iterative: 0,
    executeNow: 0,
  });
  const [isFilesModalOpen, setIsFilesModalOpen] = useState(false);
  const [taskFiles, setTaskFiles] = useState(null);
  const [filesLoading, setFilesLoading] = useState(false);
  const [filesError, setFilesError] = useState(null);

  // Fetch projects
  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://apipm.ecommcube.com/list_projects",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setProjects(response.data?.projects || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setError("Failed to fetch projects");
        toast.error("Failed to fetch projects");
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  // Fetch industries when project is selected
  useEffect(() => {
    if (!selectedProject) {
      setIndustries([]);
      return;
    }

    const fetchIndustries = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://apipm.ecommcube.com/list_industries?projectName=${selectedProject}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setIndustries(response.data?.industries || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching industries:", error);
        setError("Failed to fetch industries");
        toast.error("Failed to fetch industries");
        setLoading(false);
      }
    };

    fetchIndustries();
  }, [selectedProject]);

  // Fetch task tracking data
  useEffect(() => {
    if (!selectedProject || !selectedIndustry || !selectedCategory) return;

    const fetchTaskTracking = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://apipm.ecommcube.com/task_tracking?projectName=${selectedProject}&industry=${selectedIndustry}&category=${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setTaskData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching task tracking:", error);
        setError("Failed to fetch task tracking data");
        toast.error("Failed to fetch task tracking data");
        setLoading(false);
      }
    };

    fetchTaskTracking();
  }, [selectedProject, selectedIndustry, selectedCategory]);

  const handleProjectChange = (e) => {
    setSelectedProject(e.target.value);
    setSelectedIndustry("");
    setSelectedCategory("");
    setTaskData(null);
  };

  const handleIndustryChange = (e) => {
    setSelectedIndustry(e.target.value);
    setSelectedCategory("");
    setTaskData(null);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setTaskData(null);
  };

  const fetchTaskDetails = async (taskId) => {
    setIsDetailsModalOpen(true);
    setDetailsLoading(true);
    setDetailsError(null);

    try {
      const response = await axios.get(
        `https://apipm.ecommcube.com/get_task_details?taskId=${taskId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setTaskDetails(response.data);
    } catch (error) {
      console.error("Error fetching task details:", error);
      setDetailsError("Failed to fetch task details");
      toast.error("Failed to fetch task details");
    } finally {
      setDetailsLoading(false);
    }
  };

  const fetchTaskImage = async (taskId) => {
    setIsImageModalOpen(true);
    setImageLoading(true);
    setImageError(null);

    try {
      const response = await axios.get(
        `https://apipm.ecommcube.com/get_image?taskId=${taskId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      );
      setTaskImage(URL.createObjectURL(response.data));
    } catch (error) {
      console.error("Error fetching task image:", error);
      setImageError("Failed to load image");
      if (error.response?.status === 404) {
        setImageError("No image available for this task");
      }
    } finally {
      setImageLoading(false);
    }
  };

  const handleUpdateTask = async () => {
    try {
      const response = await axios.post(
        "https://apipm.ecommcube.com/update_task",
        {
          projectName: selectedProject,
          taskIds: [updateTaskId],
          ...updateConfig,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Task updated successfully");
      setIsUpdateModalOpen(false);
      // Refresh task data
      const updatedTaskData = { ...taskData };
      // You might want to refresh the entire task list here
      setTaskData(updatedTaskData);
    } catch (error) {
      console.error("Error updating task:", error);
      toast.error("Failed to update task");
    }
  };

  const fetchTaskFiles = async (taskId) => {
    setIsFilesModalOpen(true);
    setFilesLoading(true);
    setFilesError(null);

    try {
      const response = await axios.get(
        `https://apipm.ecommcube.com/get_task_files?taskId=${taskId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setTaskFiles(response.data);
    } catch (error) {
      console.error("Error fetching task files:", error);
      setFilesError("Failed to fetch task files");
      toast.error("Failed to fetch task files");
    } finally {
      setFilesLoading(false);
    }
  };

  const TaskDetailModal = () => {
    if (!isDetailsModalOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                Task Details
              </h2>
              <button
                onClick={() => setIsDetailsModalOpen(false)}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            {detailsLoading ? (
              <div className="flex items-center justify-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
              </div>
            ) : detailsError ? (
              <div className="text-center text-red-500 p-4">{detailsError}</div>
            ) : (
              taskDetails && (
                <div className="space-y-6">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="text-sm text-gray-500 dark:text-gray-400">
                        Project Name
                      </label>
                      <p className="font-medium text-gray-900 dark:text-white">
                        {taskDetails.projectName}
                      </p>
                    </div>
                    <div>
                      <label className="text-sm text-gray-500 dark:text-gray-400">
                        Task ID
                      </label>
                      <p className="font-medium text-gray-900 dark:text-white break-all">
                        {taskDetails.taskId}
                      </p>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                      Task Status
                    </h3>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="text-sm text-gray-500 dark:text-gray-400">
                          Progress
                        </label>
                        <p className="font-medium text-gray-900 dark:text-white capitalize">
                          {taskDetails.taskTrackingInfo.progress}
                        </p>
                      </div>
                      <div>
                        <label className="text-sm text-gray-500 dark:text-gray-400">
                          Status
                        </label>
                        <p className="font-medium text-gray-900 dark:text-white capitalize">
                          {taskDetails.taskTrackingInfo.status}
                        </p>
                      </div>
                      <div>
                        <label className="text-sm text-gray-500 dark:text-gray-400">
                          Priority
                        </label>
                        <p className="font-medium text-gray-900 dark:text-white">
                          {taskDetails.taskTrackingInfo.priority}
                        </p>
                      </div>
                    </div>
                  </div>

                  {taskDetails.taskTrackingInfo.remarks && (
                    <div>
                      <label className="text-sm text-gray-500 dark:text-gray-400">
                        Remarks
                      </label>
                      <p className="font-medium text-gray-900 dark:text-white">
                        {taskDetails.taskTrackingInfo.remarks}
                      </p>
                    </div>
                  )}

                  <div className="space-y-4">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                      Timestamps
                    </h3>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="text-sm text-gray-500 dark:text-gray-400">
                          Created At
                        </label>
                        <p className="font-medium text-gray-900 dark:text-white">
                          {taskDetails.taskTrackingInfo.timestamps.createdAt}
                        </p>
                      </div>
                      {taskDetails.taskTrackingInfo.timestamps
                        .lastUpdatedAt && (
                        <div>
                          <label className="text-sm text-gray-500 dark:text-gray-400">
                            Last Updated
                          </label>
                          <p className="font-medium text-gray-900 dark:text-white">
                            {
                              taskDetails.taskTrackingInfo.timestamps
                                .lastUpdatedAt
                            }
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  };

  const TaskImageModal = () => {
    if (!isImageModalOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-3xl">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                Task Image
              </h2>
              <button
                onClick={() => {
                  setIsImageModalOpen(false);
                  setTaskImage(null);
                }}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="relative aspect-video rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-900">
              {imageLoading ? (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                </div>
              ) : imageError ? (
                <div className="absolute inset-0 flex items-center justify-center text-gray-500 dark:text-gray-400">
                  <p>{imageError}</p>
                </div>
              ) : (
                taskImage && (
                  <img
                    src={taskImage}
                    alt="Task visualization"
                    className="w-full h-full object-contain"
                  />
                )
              )}
            </div>

            {taskImage && (
              <div className="mt-4 flex justify-end">
                <a
                  href={taskImage}
                  download={`task-${taskDetails?.taskId || "image"}.jpg`}
                  className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300 text-sm"
                >
                  Download Image
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const UpdateTaskModal = () => {
    if (!isUpdateModalOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-md">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                Update Task
              </h2>
              <button
                onClick={() => setIsUpdateModalOpen(false)}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Logic ID
                </label>
                <select
                  value={updateConfig.logicId}
                  onChange={(e) =>
                    setUpdateConfig({
                      ...updateConfig,
                      logicId: Number(e.target.value),
                    })
                  }
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
                >
                  <option value={1}>Mark to Draft</option>
                  <option value={2}>Generate Article and Image</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Priority
                </label>
                <select
                  value={updateConfig.priority}
                  onChange={(e) =>
                    setUpdateConfig({
                      ...updateConfig,
                      priority: Number(e.target.value),
                    })
                  }
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
                >
                  <option value={0}>Normal Priority</option>
                  <option value={1}>Low Priority</option>
                  <option value={2}>Medium Priority</option>
                  <option value={3}>High Priority</option>
                  <option value={4}>Important Priority</option>
                  <option value={5}>Urgent Priority</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Iterative Updates
                </label>
                <select
                  value={updateConfig.iterative}
                  onChange={(e) =>
                    setUpdateConfig({
                      ...updateConfig,
                      iterative: Number(e.target.value),
                    })
                  }
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
                >
                  <option value={0}>One Time</option>
                  <option value={1}>Daily</option>
                  <option value={7}>Weekly</option>
                  <option value={14}>Bi-Weekly</option>
                  <option value={30}>Monthly</option>
                  <option value={90}>Quarterly</option>
                </select>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="executeNow"
                  checked={updateConfig.executeNow === 1}
                  onChange={(e) =>
                    setUpdateConfig({
                      ...updateConfig,
                      executeNow: e.target.checked ? 1 : 0,
                    })
                  }
                  className="h-4 w-4 text-blue-600"
                />
                <label
                  htmlFor="executeNow"
                  className="ml-2 text-sm text-gray-700 dark:text-gray-300"
                >
                  Execute Immediately
                </label>
              </div>

              <button
                onClick={handleUpdateTask}
                className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                Update Task
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TaskFilesModal = () => {
    if (!isFilesModalOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                Task Files
              </h2>
              <button
                onClick={() => {
                  setIsFilesModalOpen(false);
                  setTaskFiles(null);
                }}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            {filesLoading ? (
              <div className="flex items-center justify-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
              </div>
            ) : filesError ? (
              <div className="text-center text-red-500 p-4">{filesError}</div>
            ) : taskFiles ? (
              <div className="space-y-6">
                {/* Markdown Content */}
                {taskFiles.md_file_content && (
                  <div className="space-y-2">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                      Markdown Content
                    </h3>
                    <div className="bg-gray-50 dark:bg-gray-900 rounded-lg p-4">
                      <pre className="whitespace-pre-wrap text-sm text-gray-700 dark:text-gray-300">
                        {taskFiles.md_file_content}
                      </pre>
                    </div>
                  </div>
                )}

                {/* Image */}
                {taskFiles.jpg_file_url && (
                  <div className="space-y-2">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                      Task Image
                    </h3>
                    <div className="relative aspect-video rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-900">
                      <img
                        src={`https://apipm.ecommcube.com${taskFiles.jpg_file_url}`}
                        alt="Task visualization"
                        className="w-full h-full object-contain"
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center text-gray-500 dark:text-gray-400 p-4">
                No files available for this task
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="relative">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-6xl mx-auto p-8"
      >
        <div className="mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Task Tracking
          </h1>

          {/* Filters Section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            {/* Project Selection */}
            <div>
              <label
                htmlFor="project"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                Select Project
              </label>
              <select
                id="project"
                value={selectedProject}
                onChange={handleProjectChange}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="">Select a project</option>
                {Array.isArray(projects) &&
                  projects.map((project, index) => (
                    <option key={index} value={project}>
                      {project}
                    </option>
                  ))}
              </select>
            </div>

            {/* Industry Selection */}
            <div>
              <label
                htmlFor="industry"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                Select Industry
              </label>
              <select
                id="industry"
                value={selectedIndustry}
                onChange={handleIndustryChange}
                disabled={!selectedProject}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent disabled:opacity-50"
              >
                <option value="">Select an industry</option>
                {Array.isArray(industries) &&
                  industries.map((industry, index) => (
                    <option key={index} value={industry}>
                      {industry}
                    </option>
                  ))}
              </select>
            </div>

            {/* Category Selection */}
            <div>
              <label
                htmlFor="category"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                Select Category
              </label>
              <select
                id="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                disabled={!selectedIndustry}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent disabled:opacity-50"
              >
                <option value="">Select a category</option>
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Selected Filters Display */}
          {selectedProject && selectedIndustry && selectedCategory && (
            <div className="text-gray-600 dark:text-gray-400 mb-4">
              <p>Selected Project: {selectedProject}</p>
              <p>Selected Industry: {selectedIndustry}</p>
              <p>Selected Category: {selectedCategory}</p>
            </div>
          )}
        </div>

        {/* Content Section */}
        {loading ? (
          <div className="flex items-center justify-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="p-8 text-center">
            <div className="text-red-500 mb-4">{error}</div>
            <button
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Retry
            </button>
          </div>
        ) : taskData ? (
          <div className="space-y-8">
            {/* Task Statistics */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                  Task Status
                </h3>
                {taskData.tasks.map((task) => {
                  const statusCounts = task.taskTracking.reduce((acc, t) => {
                    acc[t.status] = (acc[t.status] || 0) + 1;
                    return acc;
                  }, {});
                  return (
                    <div className="space-y-2" key={task.projectId}>
                      {Object.entries(statusCounts).map(([status, count]) => (
                        <div
                          key={status}
                          className="flex justify-between items-center"
                        >
                          <span className="capitalize text-gray-600 dark:text-gray-400">
                            {status}
                          </span>
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {count}
                          </span>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>

              <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                  Progress Overview
                </h3>
                {taskData.tasks.map((task) => {
                  const progressCounts = task.taskTracking.reduce((acc, t) => {
                    acc[t.progress] = (acc[t.progress] || 0) + 1;
                    return acc;
                  }, {});
                  return (
                    <div className="space-y-2" key={task.projectId}>
                      {Object.entries(progressCounts).map(
                        ([progress, count]) => (
                          <div
                            key={progress}
                            className="flex justify-between items-center"
                          >
                            <span className="capitalize text-gray-600 dark:text-gray-400">
                              {progress}
                            </span>
                            <span className="font-semibold text-gray-900 dark:text-white">
                              {count}
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Task List */}
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden">
              <div className="p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                  Task Details
                </h3>
                <div className="space-y-4">
                  {taskData.tasks.map((task) =>
                    task.taskTracking.map((tracking, index) => (
                      <motion.div
                        key={task.taskIds[index]}
                        className="border dark:border-gray-700 rounded-lg p-4 hover:shadow-md transition-shadow"
                      >
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                          <div>
                            <div className="flex items-center space-x-2 mb-2">
                              {tracking.progress === "completed" ? (
                                <Archive className="h-5 w-5 text-green-500" />
                              ) : tracking.progress === "initialised" ? (
                                <Clock className="h-5 w-5 text-blue-500" />
                              ) : (
                                <Play className="h-5 w-5 text-yellow-500" />
                              )}
                              <span className="font-medium text-gray-900 dark:text-white capitalize">
                                {tracking.progress} - {tracking.status}
                              </span>
                            </div>
                            <div className="text-sm text-gray-500 dark:text-gray-400">
                              Priority: {tracking.priority}
                            </div>
                          </div>

                          <div>
                            <div className="flex items-center space-x-2 text-sm text-gray-500 dark:text-gray-400">
                              <Calendar className="h-4 w-4" />
                              <span>
                                Created: {tracking.timestamps.createdAt}
                              </span>
                            </div>
                            {tracking.timestamps.lastUpdatedAt && (
                              <div className="flex items-center space-x-2 text-sm text-gray-500 dark:text-gray-400 mt-1">
                                <RefreshCw className="h-4 w-4" />
                                <span>
                                  Updated: {tracking.timestamps.lastUpdatedAt}
                                </span>
                              </div>
                            )}
                          </div>

                          {tracking.remarks && (
                            <div className="col-span-2">
                              <div className="flex items-start space-x-2 text-sm text-gray-500 dark:text-gray-400">
                                <AlertCircle className="h-4 w-4 mt-0.5 flex-shrink-0" />
                                <span>{tracking.remarks}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="mt-4 flex items-center justify-end space-x-4">
                          <button
                            onClick={() =>
                              fetchTaskDetails(task.taskIds[index])
                            }
                            className="inline-flex items-center px-3 py-2 text-sm font-medium rounded-md text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                          >
                            <Activity className="h-4 w-4 mr-2" />
                            View Details
                          </button>
                          <button
                            onClick={() => fetchTaskImage(task.taskIds[index])}
                            className="inline-flex items-center px-3 py-2 text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                          >
                            <Image className="h-4 w-4 mr-2" />
                            View Image
                          </button>
                          <button
                            onClick={() => {
                              setUpdateTaskId(task.taskIds[index]);
                              setIsUpdateModalOpen(true);
                            }}
                            className="inline-flex items-center px-3 py-2 text-sm font-medium rounded-md text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600"
                          >
                            <RefreshCw className="h-4 w-4 mr-2" />
                            Update Task
                          </button>
                          <button
                            onClick={() => fetchTaskFiles(task.taskIds[index])}
                            className="inline-flex items-center px-3 py-2 text-sm font-medium rounded-md text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600"
                          >
                            <FileText className="h-4 w-4 mr-2" />
                            View Files
                          </button>
                        </div>
                      </motion.div>
                    ))
                  )}
                </div>
              </div>
            </div>

            {/* Raw JSON Data (Collapsible) */}
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6">
              <button
                onClick={() => setShowJson(!showJson)}
                className="flex items-center space-x-2 text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
              >
                <code className="text-sm">
                  {showJson ? "- Hide" : "+ Show"} Raw JSON
                </code>
              </button>
              {showJson && (
                <pre className="mt-4 p-4 bg-gray-50 dark:bg-gray-900 rounded-lg overflow-auto text-sm text-gray-700 dark:text-gray-300">
                  {JSON.stringify(taskData, null, 2)}
                </pre>
              )}
            </div>
          </div>
        ) : (
          <div className="text-center text-gray-500 dark:text-gray-400 mt-8 p-8 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <Activity className="h-12 w-12 mx-auto mb-4 text-gray-400" />
            <p className="text-lg">
              {!selectedProject
                ? "Please select a project to view task tracking"
                : !selectedIndustry
                ? "Please select an industry"
                : !selectedCategory
                ? "Please select a category"
                : "No task tracking data available"}
            </p>
          </div>
        )}
      </motion.div>
      <TaskDetailModal />
      <TaskImageModal />
      <UpdateTaskModal />
      <TaskFilesModal />
    </div>
  );
}
