import React, { useState } from "react";
import { Breadcrumbs } from "../../../components";
import { TemplateProvider } from "./TemplateContext";
import TemplateSidebar from "./TemplateSidebar";
import SearchTemplate from "./SearchTemplate";
import usePermissions from "../../../utils/userPermission";

export default function ManageTemplate({ children }) {
  const [open, setOpen] = useState(true);
  const { hasPermission } = usePermissions();

  return (
    hasPermission("edit template") && (
      <TemplateProvider>
        <div className="border-b sticky top-12 px-2 z-20 h-10 dark:border-white/20 flex items-center justify-between flex-wrap w-full text-sm backdrop-blur-md bg-warmGray dark:bg-gray-950/30">
          <div className="flex items-center gap-5 flex-1 text-white">
            <SearchTemplate />
            <Breadcrumbs className="text-white/80" />
          </div>
        </div>
        <div
          className={`grid w-full ${
            open ? "grid-cols-manageTemplate" : "grid-cols-1"
          }`}
        >
          <TemplateSidebar open={open} setOpen={setOpen} />
          <div className="grid">
            <div className="overflow-hidden">{children}</div>
          </div>
        </div>
      </TemplateProvider>
    )
  );
}
