import { ChevronsLeft } from "lucide-react";

const NavigationButtons = ({
  currentStep,
  totalSteps,
  handleNext,
  handleBack,
  isStepValid,
  setChat,
  handleExecute,
}) => {
  return (
    <div className="flex justify-between">
      <button
        onClick={() => setChat(false)}
        className="text-primary font-medium ml-3 flex items-center gap-2 hover:-translate-x-1 transition-all duration-300"
      >
        <ChevronsLeft className="w-4 h-4" /> Switch to Manual Setup
      </button>
      <div className="flex items-center justify-end gap-2">
        {" "}
        <button
          onClick={handleBack}
          className={`px-6 py-2 rounded-lg font-medium
${
  currentStep === 1
    ? "text-gray-400 cursor-not-allowed"
    : "text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
}`}
          disabled={currentStep === 1}
        >
          Back
        </button>
        <button
          onClick={currentStep === totalSteps ? handleExecute : handleNext}
          disabled={!isStepValid(currentStep)}
          className={`px-6 py-2 rounded-lg font-medium
${
  isStepValid(currentStep)
    ? "bg-primary text-white hover:bg-primary/90"
    : "bg-gray-200 text-gray-400 cursor-not-allowed"
}`}
        >
          {currentStep === totalSteps ? "Create Website" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default NavigationButtons;
