import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-hot-toast";
import { FileText, X, List, Copy, Check } from "lucide-react";

export default function ListCategories() {
  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [showTitlesModal, setShowTitlesModal] = useState(false);
  const [categoryTitles, setCategoryTitles] = useState(null);
  const [loadingTitles, setLoadingTitles] = useState(false);
  const [selectedCategoryTitle, setSelectedCategoryTitle] = useState("");
  const [copiedId, setCopiedId] = useState(null);

  // Fetch projects first
  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://apipm.ecommcube.com/list_projects",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setProjects(response.data?.projects || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setError("Failed to fetch projects");
        toast.error("Failed to fetch projects");
        setLoading(false);
        setProjects([]);
      }
    };

    fetchProjects();
  }, []);

  // Fetch industries when project is selected
  useEffect(() => {
    if (!selectedProject) {
      setIndustries([]);
      return;
    }

    const fetchIndustries = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://apipm.ecommcube.com/list_industries?projectName=${selectedProject}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
        setIndustries(response.data?.industries || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching industries:", error);
        setError("Failed to fetch industries");
        toast.error("Failed to fetch industries");
        setLoading(false);
        setIndustries([]);
      }
    };

    fetchIndustries();
  }, [selectedProject]);

  // Fetch articles when both project and industry are selected
  useEffect(() => {
    if (!selectedProject || !selectedIndustry) return;

    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://apipm.ecommcube.com/list_categories?projectName=${selectedProject}&industry=${selectedIndustry}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setCategories(response.data?.categories || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching articles:", error);
        setError("Failed to fetch articles");
        toast.error("Failed to fetch articles");
        setLoading(false);
        setCategories([]);
      }
    };

    fetchCategories();
  }, [selectedProject, selectedIndustry]);

  const handleProjectChange = (e) => {
    setSelectedProject(e.target.value);
    setSelectedIndustry("");
    setCategories([]);
  };

  const handleIndustryChange = (e) => {
    setSelectedIndustry(e.target.value);
    setCategories([]);
  };

  const handleCopyTitle = async (title) => {
    try {
      await navigator.clipboard.writeText(title);
      setCopiedId(title);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
      toast.error("Failed to copy text");
    }
  };

  const fetchCategoryTitles = async (category) => {
    setShowTitlesModal(true);
    setLoadingTitles(true);
    setSelectedCategoryTitle(category.title || category);

    try {
      const response = await axios.get(
        `https://apipm.ecommcube.com/get_titles_by_industry_and_category?projectName=${selectedProject}&industry=${selectedIndustry}&category=${
          category.title || category
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setCategoryTitles(response.data);
    } catch (error) {
      console.error("Error fetching category titles:", error);
      toast.error("Failed to fetch category titles");
    } finally {
      setLoadingTitles(false);
    }
  };

  return (
    <div className="relative">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-6xl mx-auto p-8"
      >
        <div className="mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Categories List
          </h1>

          {/* Filters Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <div>
              <label
                htmlFor="project"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                Select Project
              </label>
              <select
                id="project"
                value={selectedProject}
                onChange={handleProjectChange}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="">Select a project</option>
                {Array.isArray(projects) &&
                  projects.map((project, index) => (
                    <option key={index} value={project}>
                      {project}
                    </option>
                  ))}
              </select>
            </div>

            {/* Industry Selection */}
            <div>
              <label
                htmlFor="industry"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                Select Industry
              </label>
              <select
                id="industry"
                value={selectedIndustry}
                onChange={handleIndustryChange}
                disabled={!selectedProject}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent disabled:opacity-50"
              >
                <option value="">Select an industry</option>
                {Array.isArray(industries) &&
                  industries.map((industry, index) => (
                    <option key={index} value={industry}>
                      {industry}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          {/* Status Information */}
          {selectedProject && selectedIndustry && (
            <div className="text-gray-600 dark:text-gray-400 mb-4">
              <p>Selected Project: {selectedProject}</p>
              <p>Selected Industry: {selectedIndustry}</p>
              <p>Total Categories: {categories?.length || 0}</p>
            </div>
          )}
        </div>

        {/* Content Section */}
        {loading ? (
          <div className="flex items-center justify-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="p-8 text-center">
            <div className="text-red-500 mb-4">{error}</div>
            <button
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Retry
            </button>
          </div>
        ) : selectedProject && selectedIndustry ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {Array.isArray(categories) &&
                categories.map((category, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-all duration-300 p-4"
                  >
                    <div className="flex items-center space-x-4 mb-3">
                      <div className="p-3 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
                        <FileText className="h-4 w-4 text-blue-500" />
                      </div>
                      <div className="flex-1">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                          {category.title || category}
                        </h3>
                      </div>
                    </div>
                    <button
                      onClick={() => fetchCategoryTitles(category)}
                      className="w-full flex items-center justify-center space-x-2 px-3 py-2 bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400 rounded-md hover:bg-blue-100 dark:hover:bg-blue-900/30 transition-colors"
                    >
                      <List className="h-4 w-4" />
                      <span>View Titles</span>
                    </button>
                  </motion.div>
                ))}
            </div>

            {(!categories || categories.length === 0) && (
              <div className="text-center text-gray-500 dark:text-gray-400 mt-8 p-8 bg-gray-50 dark:bg-gray-800 rounded-lg">
                <FileText className="h-12 w-12 mx-auto mb-4 text-gray-400" />
                <p className="text-lg">
                  No articles found for the selected criteria
                </p>
              </div>
            )}
          </>
        ) : (
          <div className="text-center text-gray-500 dark:text-gray-400 mt-8 p-8 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <FileText className="h-12 w-12 mx-auto mb-4 text-gray-400" />
            <p className="text-lg">
              Please select both a project and industry to view articles
            </p>
          </div>
        )}
      </motion.div>

      {/* Titles Modal */}
      {showTitlesModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-4xl w-full mx-4 max-h-[80vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                  {selectedCategoryTitle}
                </h2>
                <p className="text-gray-600 dark:text-gray-400">
                  Project: {selectedProject} | Industry: {selectedIndustry}
                </p>
              </div>
              <button
                onClick={() => {
                  setShowTitlesModal(false);
                  setCategoryTitles(null);
                }}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            {loadingTitles ? (
              <div className="flex justify-center py-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : categoryTitles ? (
              <div className="space-y-6">
                {categoryTitles.articles_by_progress_and_status
                  .filter((group) => group.articles.length > 0)
                  .map((group, index) => (
                    <div
                      key={index}
                      className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4"
                    >
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-3 capitalize">
                        {group.progress} - {group.status}
                      </h3>
                      <div className="space-y-2">
                        {group.articles.map((article) => (
                          <div
                            key={article.taskId}
                            className="bg-white dark:bg-gray-800 p-3 rounded-md shadow-sm flex justify-between items-start gap-4"
                          >
                            <div className="flex-1">
                              <p className="text-gray-800 dark:text-gray-200">
                                {article.title}
                              </p>
                              <div className="flex gap-2 mt-1">
                                <span className="text-xs text-gray-500 dark:text-gray-400">
                                  Priority: {article.priority}
                                </span>
                                <span className="text-xs text-gray-500 dark:text-gray-400">
                                  ID: {article.taskId}
                                </span>
                              </div>
                            </div>
                            <button
                              onClick={() => handleCopyTitle(article.title)}
                              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                              title="Copy title"
                            >
                              {copiedId === article.title ? (
                                <Check className="h-4 w-4 text-green-500" />
                              ) : (
                                <Copy className="h-4 w-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200" />
                              )}
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <p className="text-gray-500 dark:text-gray-400 text-center py-8">
                No titles available
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
